import React, { useState } from "react";
import PromptTabDetail from "../Common/PromptTabDetail";
import ManageProjectScope from "./ManageProjectScope";

const SystemPrompts = () => {

  const [displayAddScopeSection, setDisplayAddScopeSection] = useState(false);

  const updateAddScopeSectionVisibility = (value) => {
    setDisplayAddScopeSection(value);
  }

  return (
    <>
      {
        !displayAddScopeSection ?
          <PromptTabDetail tab={"SystemPrompts"} updateAddScopeSectionVisibility={updateAddScopeSectionVisibility} />
          :
          <ManageProjectScope updateAddScopeSectionVisibility={updateAddScopeSectionVisibility} />
      }
    </>
  )
};
export default SystemPrompts;

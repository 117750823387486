import {
  MotifErrorMessage,
  MotifFileUploader,
  MotifFooter,
  MotifFormField,
  MotifInput,
  MotifSearch,
  MotifPaginationSelect,
  MotifPaginationSelectItem,
  MotifTooltip
} from "@ey-xd/motif-react";
import MotifInlineMessage from "@ey-xd/motif-react/InlineMessage";
import React, { useEffect, useRef, useState, useContext } from "react";
import MotifModal, {
  MotifModalBody,
  MotifModalHeader,
} from "@ey-xd/motif-react/Modal";

import MotifButton from "@ey-xd/motif-react/Button";
import _ from "lodash";
import { DashboardContext } from "../../../context/dashboardContext";
import MotifTable, { MotifTableTitleBar } from "@ey-xd/motif-react/Table";
import "./AddDocument.css";
import page from "../../../assets/image/page.svg";
import link from "../../../assets/image/link.svg";
import DocumentService from "../../../services/documentService";
import GraphService from "../../../services/graphService";
import Utils from "../../../utils/Utils";
import searchIcon from "../../../assets/image/search.svg";
import LoaderComponent from "../../common/LoaderComponent";


const UploadModal = ({
  openShow,
  setOpenModal,
  filescountInfo,
  localFileUploadSuccess,
  sharePointFileUploadSuccess,
  onSelectLocalFiles
}) => {
  const graphService = new GraphService();
  const utils = new Utils();
  const documentservice = new DocumentService();

  const urlRegex =
    /^(https?:\/\/)?([^/\s]+\/)([^/\s]+\/)([^/\s]+\/)([^/\s]+)(.*)/;

    const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileTemp, setSelectedFileTemp] = useState([]);
  const [SearchLocal, setSearchLocal] = useState("");
  const [pagesize, SetPageSize] = useState(5);

  const [openFilesGrid, setOpneFilesGrid] = useState(false);
  const [errorfile, setErrorFile] = useState(false);
  const [filecountLimit, setFileCountLimit] = useState(0);
  const [errorFilescount, setErrorFilesCount] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);
  const MaxFileFlimit = useState(filescountInfo.MaxFilesuploadLimit);
  const [MaxFileFlimitSize] = useState(filescountInfo.MaxFFileSizeLimit);
  const [fileUploadLimit, setFileUploadLimit] = useState(0);

  const [totalFilesSize, setTotalFilesSize] = useState(0);
  const [errorFilseSizeCount, seterrorFileSizeCount] = useState(false);
  const [extrasize, setExtraSize] = useState(0);
  const [errorCharacters, setErrorCharacters] = useState(false);

  const [sharePointSiteUrl, setSharePointSiteUrl] = useState("");
  const [isInValidSiteUrl, setIsInValidSiteUrl] = useState(false);
  const [isInValidSharePointSite, setIsInValidSharePointSite] = useState(false);
  const [isInValidSitePath, setIsInValidSitePath] = useState(false);
  const [showSharePointModal, setShowSharePointModal] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [sitePath, setSitePath] = useState("");
  const [siteDomainName, setSiteDomainName] = useState("");
  const [showDirectory, setShowDirectory] = useState(false);
  const [siteId, setSiteId] = useState("");
  const [sharePointFiles, setSharePointFiles] = useState([]);
  const [selectedSharePointFiles, setSelectedSharePointFiles] = useState([]);
  const [sharePointFilesMemory, setSharePointFilesInMemory] = useState([]);
  const sharePointFilesRef = useRef({});
  sharePointFilesRef.current = sharePointFiles;
  const [sharePointConfirmButtonEnabled, setSharePointConfirmButtonEnabled] =
    useState(true);
  const [sharePointSearch, setSharePointSearch] = useState("");
  const [isSharePointValidLink, setIsSharePointValidLink] = useState(false);
  let selctedFilenames = [];
  const [checkListSelect, setCheckListSelect] = useState([]);
  const [errrduplicate, setErrorDuplicate] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const { setIsDocumentUploadProcessInitiated } = useContext(DashboardContext);
  const [spFileValidation, setspFileValidation] = useState(false);
  const [perFileLimitExceed, setPerFileLimitExceed] = useState(false);
  const [spdupNameValidation, setspdupNameValidation] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const[issbuttonDisabled,setIsbuttonDisabled]=useState(true);
  const [sharePointInvalidFiles, setSharePointInvalidFiles] = useState([]);
  const [isSharePointGridPageChanged, setIsSharePointGridPageChanged] = useState(false);
  const spGridRef = useRef(null);

  let chkFileValid = false;

  /**
   * Description
   * -----------
   * This useEffect is used to open/close the modal.
   */
  useEffect(() => {
    setOpenModal(openShow);
  }, [openShow, setOpenModal]);

  /**
   * Description
   * -----------
   * This useEffect is used to get the refreshed access token.
   */
  useEffect(() => {
    setFileUploadLimit(
      utils.getBytesFromGB(filescountInfo.MaxFFileSizeLimit) /
        filescountInfo.MaxFilesuploadLimit
    );
    getAccessToken();
  }, []);

  /**
   * Description
   * -----------
   * This method is used to get the refreshed access token.
   */
  const getAccessToken = async () => {
    const graphService = new GraphService();
    const graphApiToken = await graphService.getToken();
    setAccessToken(graphApiToken);
  };

  /**
   * Enable / Disable Confirm upload button based on all validations
   */
  useEffect(() => {
    setSharePointConfirmButtonEnabled(spdupNameValidation || spFileValidation || perFileLimitExceed || errorFilseSizeCount || (totalFiles > MaxFileFlimit) || (selectedSharePointFiles.length <= 0));
  }, [spFileValidation, spdupNameValidation, perFileLimitExceed, errorFilseSizeCount, totalFiles, selectedSharePointFiles]);

  /**
   * Highlight the files failing validations with red color
   */
  useEffect(() => {
    let count = 0;
    const timer = setInterval(() => {
      const gridApi = spGridRef.current;
      count++;
      sharePointInvalidFiles?.forEach(fileName => {
        if(selectedSharePointFiles.findIndex(f => f.name === fileName) > -1) {
          const elements = gridApi?.querySelectorAll('[data-id="' + fileName + '"]');
          elements?.forEach(element => {
            element.style = "color:red";
          });
        } else {
          const elements = gridApi?.querySelectorAll('[data-id="' + fileName + '"]');
          elements?.forEach(element => {
            element.style = "";
          });
        }
      });
      if(count > 2){
        clearInterval(timer);
      }
    }, 200);
  }, [selectedSharePointFiles, sharePointInvalidFiles, isSharePointGridPageChanged])

  /**
   * Description
   * -----------
   * This useEffect is used to fetch the sharepoint site id.
   */
  useEffect(() => {
    if (siteDomainName && siteName) {
      fetchSharePointDetails();
    }
  }, [siteDomainName, siteName]);

  /**
   * Description
   * -----------
   * This useEffect is used to fetch the sharepoint files.
   */
  useEffect(() => {
    if (siteId && sitePath) {
      fetchSharepointDirectory();
    }
  }, [siteId, sitePath]);

  /**
   * Description
   * -----------
   * This methods sets the values of the sharepoints parameters.
   * Parameters:
   * value: set sharepoint site url
   */
  const changeSiteUrlValue = (value) => {
     let sURL=value?.replace("/:x:\/r/", "/");
     sURL = sURL?.replace("/:f:\/r/", "/");
     sURL = sURL?.slice(0,sURL.indexOf('?'));

    setSharePointSiteUrl(sURL);
    setIsInValidSiteUrl(false);
    setIsInValidSharePointSite(false);
    setIsInValidSitePath(false);
    setIsbuttonDisabled(sURL.trim()==='');
  };

  /**
   * Description
   * -----------
   * defined columns to show the selected local documents
   */
  const [colDefs] = useState([
    {
      field: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: "name",
      width: 400,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params) => (
        <div>
          <img src={page} alt={params.data.name} /> {params.data.name}
        </div>
      ),
    },
    {
      field: "size",
      displayName: "Size",
      cellRenderer: (params) => (
        <div>{utils.getBytesInMB(params.data.size)} MB</div>
      ),
    },
  ]);

  /**
   * Description
   * -----------
   * defined columns to show the sharepoint documents
   */
  const [colDefsSharePointGrid] = useState([
    {
      field: "",
      headerName: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: "name",
      displayName: "Document Name",
      width: 500 ,
      wrapText: true,
      autoHeight: true,
      
      cellRenderer: (params) => (
        <div data-id={params.data.name}>
          <img src={page} alt={params.data.name} /> {params.data.name}
        </div>
      ),
    },
    {
      field: "size",
      displayName: "Size",
      width: 200,
      cellRenderer: (params) => (
        <div data-id={params.data.name}>{utils.getBytesInMB(params.data.size)} MB</div>
      ),
    },
  ]);

  /**
   * Description
   * -----------
   *  checkbox checked if any file having the validation before displaying in the grid.
   * Parameters:
   * event: ag-grid ready event
   */
  function onGridReady(event) {
    event.api.forEachNode(function (node) {
      if (
        node.data.error !== "" ||
        (node.data.errorduplicate !== undefined &&
          node.data.errorduplicate !== "") ||
        (node.data.errorSplChar !== undefined && node.data.errorSplChar !== "") ||
        (node.data.errorPerFileLimitExceed !== undefined && node.data.errorPerFileLimitExceed !== "")
      ) {
        node.setSelected(true);
      } else {
        node.setSelected(false);
      }
    });
  }

  /**
   * Description
   * -----------
   * This method calls when any files are selected
   * Parameters:
   * event: change event of checkbox
   */
  function onRowSelection(event) {
    let selectedFiles = event.api.getSelectedRows();
    setCheckListSelect(selectedFiles);
  }
  // removing the selected files

  /**
   * Description
   * -----------
   * This method removes the selected files in local file upload..
   */
  function removeSelectedItems() {
    if (checkListSelect.length > 0) {
      const restitem = [...selectedFile];
      let validtioncheck = false;
      let removedItems = [];
      for (const f of checkListSelect) {
        let index = restitem.findIndex((x) => x.name === f.name);
        if (index >= 0) {
          restitem.splice(index, 1);
          validtioncheck = true;
          removedItems.push(f);
        }
      }
      setSelectedFile(restitem);
      setSelectedFileTemp(restitem);
      if (validtioncheck) {
        checkValidationrequried(restitem, removedItems);
      }
      selctedFilenames = [];
    }
  }

  /**
   * Description
   * -----------
   * This method verifies validation for the rest of the files after removing the files
   * Parameters:
   * remainFiles: remaining file.
   * removedItems: items which are removed from the list
   */
  function checkValidationrequried(remainFiles, removedItems) {
    let error = false;
    let errordup = false;
    let fileremainSize = 0;
    let errorChar = false;
    let errorPerFileUploadExceed = false;
    remainFiles.forEach((item) => {
      fileremainSize += parseInt(item.size);
      if (item.error !== "") error = true;
      if (item.errorduplicate !== undefined && item.errorduplicate !== "")
        errordup = true;
      if (item.errorSplChar !== undefined && item.errorSplChar !== "")
        errorChar = true;
      if (item.errorPerFileLimitExceed !== undefined && item.errorPerFileLimitExceed !== "")
        errorPerFileUploadExceed = true;
    });
    setErrorFile(error);
    setErrorDuplicate(errordup);
    setErrorCharacters(errorChar);
    setPerFileLimitExceed(errorPerFileUploadExceed);

    showFilecount(filescountInfo?.filesuploadcount, remainFiles);

    fileremainSize += filescountInfo?.totalFileSize;

    let resultfilesize = utils.getBytesInMB(fileremainSize);

    setTotalFilesSize(resultfilesize);

    checkExtraFiles(filescountInfo?.avialbalefileuplaod, remainFiles);

    ExtraFileSize(filescountInfo?.availableFilesizeLimit, fileremainSize);
  }

  /**
   * Description
   * -----------
   * This method shows the total files count
   * Parameters:
   * fileUploadCount: Total uploaded document file count.
   * remainFiles: remaining file count.
   */
  function showFilecount(fileUploadCount, remainFiles) {
    let totaluploadscount = fileUploadCount + remainFiles.length;
    setTotalFiles(totaluploadscount);
  }

  /**
   * Description
   * -----------
   * This method setting up the extra files selected with the size of them and showing that extra size.
   * Parameters:
   * availableFileSize: Total uploaded document file size
   * fileRemainedSize: remaining size of the selected files
   */
  //
  function ExtraFileSize(availableFileSize, fileRemainedSize) {
    if (availableFileSize < fileRemainedSize) {
      seterrorFileSizeCount(true);
      let resfileSizecount = fileRemainedSize - availableFileSize;

      let ressize = utils.getBytesInMB(resfileSizecount);

      setExtraSize(ressize);
    } else {
      seterrorFileSizeCount(false);
      setExtraSize("");
    }
  }

  const handleLocalFileUpload = (event) => {
    const allowedTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.ms-excel",
      "application/pdf",
      "text/plain",
      "application/msword",
      "application/mspaint",
    ];

    const chosenFiles = Array.prototype.slice.call(event);
    onSelectLocalFiles(chosenFiles);
  };


  /**
   * Description
   * -----------
   * This method setting up the selected files ondrop file event and verifying the file validation
   * Parameters:
   * event: select file event of file uploader
   */
  const handleFileUpload = (event) => {
    const allowedTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.ms-excel",
      "application/pdf",
      "text/plain",
      "application/msword",
      "application/mspaint",
    ];
    const uploaded = [...selectedFile];
    const chosenFiles = Array.prototype.slice.call(event);
    let error = false;
    let totalfilesSize = 0;
    selctedFilenames = [];
    chosenFiles.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        totalfilesSize = totalfilesSize + parseInt(file?.size);
        filescountInfo.uploadedFilesdata?.forEach((item) => {
          if (item.documentName === file.name) {
            setErrorDuplicate(true);

            file.errorduplicate = "Duplicate file uploading";
            selctedFilenames.push({
              name: file?.name,
              size: file?.size,
            });
            setCheckListSelect(selctedFilenames);
          }
        });
        if (!allowedTypes.includes(file?.type)) {
          setErrorFile(true);
          error = true;
          file.error = "Incorrect File Format ";
          selctedFilenames.push({
            name: file?.name,
            size: file?.size,
          });
          setCheckListSelect(selctedFilenames);
        } else file.error = "";

        let fileExtension = file?.name.lastIndexOf(
          utils.getFileExtension(file?.name)
        );
        let filestringName = file?.name
          .toString()
          .substring(0, fileExtension - 1);
        if (utils.isContainSpecialCharacters(filestringName)) {
          setErrorCharacters(true);
          file.errorSplChar = "Special Characters included in file Name";
          if (!error) {
            selctedFilenames.push({
              name: file?.name,
              size: file?.size,
            });
            setCheckListSelect(selctedFilenames);
          }
        }
        /**
         * Commented to remove per file size limit on 09/07/2024 which was restricting large file
         * due to the logic of MaxFFileSizeLimit / MaxFilesuploadLimit for individual file
         */
        /* if(file?.size > fileUploadLimit){
          setPerFileLimitExceed(true);
          file.errorPerFileLimitExceed = `Per file Limit exceeded (${utils.getBytesInMB(fileUploadLimit)} MB)`;
          if (!error) {
            selctedFilenames.push({
              name: file?.name,
              size: file?.size,
            });
            setCheckListSelect(selctedFilenames);
          }
        } */
        uploaded.push(file);
      }
    });

    setSelectedFile(uploaded);
    setSelectedFileTemp(uploaded);
    let resFiles = filescountInfo;

    showFilecount(resFiles?.filesuploadcount, uploaded);

    totalfilesSize += resFiles?.totalFileSize;

    let resultfilesize = utils.getBytesInMB(totalfilesSize);

    setTotalFilesSize(resultfilesize);

    checkExtraFiles(resFiles?.avialbalefileuplaod, uploaded);

    ExtraFileSize(resFiles?.availableFilesizeLimit, totalfilesSize);
    setOpneFilesGrid(true);
  };

  /**
   * Description
   * -----------
   * This method checks the extra files selected and display
   * Parameters:
   * availableFiles: total remaining files count.
   * selctedFiles: selected local files list.
   */
  function checkExtraFiles(availableFiles, selctedFiles) {
    if (availableFiles < selctedFiles?.length) {
      setErrorFilesCount(true);
      let resfilecount = selctedFiles?.length - availableFiles;
      setFileCountLimit(resfilecount);
    } else {
      setErrorFilesCount(false);
      setFileCountLimit("");
    }
  }

  /**
   * Description
   * -----------
   * This method submits or uploading the selected files.
   */
  async function onSumbitupload() {
    if (selectedFile.length > 0) {
      setShowLoader(true);
      const res =  await documentservice.uploadLocalDocuments(selectedFile);
      
      if (res.status === 200) {
        setIsDocumentUploadProcessInitiated(true);
        localFileUploadSuccess(true);
        setOpenModal(!openShow);
      } else {
        localFileUploadSuccess(false);
        setOpenModal(!openShow);
      }
      setShowLoader(false);

    }
  }

  /**
   * Description
   * -----------
   * This method filters the local upload grid based on input
   * Parameters:
   * event: search input change event.
   */
  const filterLocalFiles = (event) => {
    setSearchLocal(event.target.value);
    let value = event.target.value.toString().toLowerCase().trim();
    const count = colDefs.length;
    const keys = ["name"];
    let rows = [];
  
    // Define an array to hold the filtered rows
    const filteredRows = selectedFileTemp.filter((item) => {
      // Loop through each key up to the specified count
      for (let i = 0; i < count; i++) {
        // Check if the item has the key and if the value at that key, converted to a string and made lowercase,
        // contains the search value (case-insensitive)
        if (
          item[keys[i]] &&
          item[keys[i]].toString().toLowerCase().includes(value)
        ) {
          // If the condition is met for any key, include this item in the filtered results
          return true;
        }
      }
      // If none of the keys match, exclude this item from the filtered results
      return false;
    });
  
    // If there is a search value, set the filtered rows; otherwise, reset to the original
    if (value !== "") {
      rows.push(filteredRows);
      setSelectedFile(rows[0]);
    } else {
      setSelectedFile(selectedFileTemp);
    }
  };
  

  /**
   * Description
   * -----------
   * This method extracts the siteName, domainName and sitePath from the sharepoint url.
   */
  const showSharePointView = () => {
    if (sharePointSiteUrl === "") {
      setIsInValidSiteUrl(true);
    } else {
      let matchedUrlItems = urlRegex.exec(sharePointSiteUrl);
      if (matchedUrlItems && matchedUrlItems.length > 0) {
        try {
          setSiteDomainName(matchedUrlItems[2]);
          setSiteName(matchedUrlItems[3] + matchedUrlItems[4]);
          setSitePath(matchedUrlItems[matchedUrlItems.length - 1]);
        } catch {
          setIsInValidSiteUrl(true);
        }
      } else {
        setIsInValidSiteUrl(true);
      }
    }
  };

  /**
   * Description
   * -----------
   * This method fetches the sharepoint id from the sharepint site name.
   */
  const fetchSharePointDetails = async () => {
    const siteDetails = await graphService.getSharePointSiteDetails(
      siteDomainName,
      siteName
    );
    if (siteDetails) {
      if (siteDetails?.isError) {
        setIsInValidSharePointSite(true);
      } else {
        setSiteId(siteDetails.id);
      }
    }
  };

  /**
   * Description
   * -----------
   * This method fetches the sharepoint files from the given sharepoint path.
   */
  const fetchSharepointDirectory = async () => {
    const siteItemDetails = await graphService.getSharePointDocumentFiles(
      siteId,
      sitePath
    );
    if (siteItemDetails) {
      if (siteItemDetails?.isError) {
        setIsInValidSitePath(true);
      } else {
        setIsSharePointValidLink(true);
        setTimeout(() => {
          setIsSharePointValidLink(false);
          if (siteItemDetails.value?.length > 0) {
            let result = _.chain(siteItemDetails?.value)
              .filter((item) => item.file)
              .map(function (element) {
                return {
                  id: element.id,
                  name: element.name,
                  size: element.size,
                  lastModifiedDateTime: element.lastModifiedDateTime,
                };
              })
              .value();
            setSharePointFiles(result);
            setSharePointFilesInMemory(result);
            setShowSharePointModal(true);
            setShowDirectory(true);
            setTotalFiles(filescountInfo?.filesuploadcount);
            setTotalFilesSize(
              utils.getBytesInMB(filescountInfo?.totalFileSize)
            );
          }
        }, 2000);
      }
    }
  };

  /**
   * Description
   * -----------
   * This method filters the sharepoint files.
   * Parameters:
   * event: search input change event.
   */
  const filterSharePointFiles = (event) => {
    setSharePointSearch(event.target.value);
    let value = event.target.value.toString().toLowerCase().trim();
    if (value !== "" && sharePointFiles.length > 0) {
      const count = colDefs.length;
      const keys = ["name"];
      let rows = [];
      rows.push(
        sharePointFilesMemory.filter((item) => {
          for (let i = 0; i < count; i++) {
            if (
              (item[keys[i]] &&
                item[keys[i]].toString().toLowerCase().indexOf(value) !== -1) ||
              !value
            ) {
              return true;
            }
          }
        })
      );
      setSharePointFiles(rows[0]);
    } else {
      setSharePointFiles(sharePointFilesMemory);
    }
  };

  /**
   * Description
   * -----------
   * This method initiates the sharepoint upload process.
   */
  const uploadSharePointDocument = () => {
    let selectedDcoument = selectedSharePointFiles;
    if (selectedDcoument && selectedDcoument.length > 0) {
      let selectedSharepointDocuments = [];
      selectedDcoument.forEach((item) => {
        selectedSharepointDocuments.push({
          id: item.id,
          name: item.name,
          size: item.size.toString(),
          lastModifiedDateTime: item.lastModifiedDateTime,
        });
      });

      let requestObject = {
        P_SharePointSite_ID: siteId,
        P_UserAccessToken: accessToken,
        P_Blob_Container_Name: sessionStorage.getItem("DashboardProjectId"),
        P_Sharepoint_FileList: selectedSharepointDocuments,
        P_UploadUserEmail: sessionStorage.getItem("Email"),
        P_UploadUserName: sessionStorage.getItem("CurrentUserName"),
      };
      documentservice.UploadDocumentToSharePoint(requestObject);
      setOpenModal(!openShow);
      sharePointFileUploadSuccess(true);
    }
  };

  /**
   * Perform validation check on SharePoint file(s) selection
   */
  useEffect(()=>{
    sharepointFileValidation(selectedSharePointFiles);
  }, [selectedSharePointFiles])

  /**
   * Description
   * -----------
   * This method validates the sharepoint files
   * Parameters:
   * files: selected sharepoint files
   */
  function sharepointFileValidation(files) {
    let isspecialcharvalid = false;
    let isdupName = false;
    let isPerFileLimitExceed = false;
    let invalidFiles = [];
    files.forEach((file) => {
      let fileExtension = file?.name.lastIndexOf(
        utils.getFileExtension(file?.name)
      );
      let filestringName = file?.name
        .toString()
        .substring(0, fileExtension - 1);
      if (utils.isContainSpecialCharacters(filestringName)) {
        isspecialcharvalid = true;
        chkFileValid = true;
        if(invalidFiles.findIndex(f=>f.name === file.name) === -1) {
          invalidFiles.push(file.name)
        }
      }
      filescountInfo.uploadedFilesdata?.forEach((item) => {
        if (item.documentName === file.name) {
          isdupName = true;
          chkFileValid = true;
          if(invalidFiles.findIndex(f=>f.name === file.name) === -1) {
            invalidFiles.push(file.name)
          }
        }
      });
      isPerFileLimitExceed ||= file.size > fileUploadLimit;

      if(file.size > fileUploadLimit) {
        if(invalidFiles.findIndex(f=>f.name === file.name) === -1) {
          invalidFiles.push(file.name)
        }
      }
    });
    setspFileValidation(isspecialcharvalid);
    setspdupNameValidation(isdupName);
    setPerFileLimitExceed(isPerFileLimitExceed);
    setSharePointInvalidFiles([...invalidFiles]);
  }

  return (       
    <div>
      {showLoader && <LoaderComponent />}
      <MotifModal
        label="modalDocumentUpload"
        className="motifModalupload genericModal modalNoHeaderBorder modalHeaderVerticalPadding"
        size="xl"
        show={setOpenModal}
        onClose={() => {
          setOpenModal(!openShow);
        }}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Close Button",
            title: "Close",
          }}
        >
          Upload document
        </MotifModalHeader>
        <MotifModalBody>
          <div>
            <MotifFormField>
              <MotifFileUploader
                className="uploaderdiv"
                id="my-id"
                label="Click to upload or drag and drop PDF, PPTX, DOC, XLS or TXT"
                onDrop={handleLocalFileUpload}
              />
              <div className="ContentDivider1">
                <div className="Divider1" />
                <div className="Text1">Or</div>
                <div className="Divider1" />
              </div>
              <div className="DefaultInputFieldBase1">
                <div className="DefaultInputFieldBase1">
                  <div className="Label1">Upload documents from SharePoint Folder</div>
                  <div className="Frame53841">
                    <div className="upload-sharepoint-form-cont">
                      <MotifFormField className="upload-sharepoint-form">
                        <div className="sharpointInput">
                          <MotifInput
                            id="txtSharepoint"
                            clearButtonTitle="clear"
                            value={sharePointSiteUrl}
                            placeholder="Add SharePoint Folder Link"
                            label="txtSharepoint"
                            type="text"
                            onChange={(e) => changeSiteUrlValue(e.target.value)}
                            className={
                              isInValidSiteUrl ? "motif-input-invalid" : ""
                            }
                          />
                          <div
                            className={
                              isInValidSiteUrl ||
                              isInValidSharePointSite ||
                              isInValidSitePath ||
                              isSharePointValidLink
                                ? "sharepointLinkIcon2"
                                : "sharepointLinkIcon1"
                            }
                          >
                            <img src={link} alt="link icon" />
                          </div>
                          {isInValidSiteUrl && (
                            <MotifErrorMessage>
                              The link is invalid. Please try again.
                            </MotifErrorMessage>
                          )}
                          {isInValidSharePointSite && (
                            <MotifErrorMessage>
                              You do not have the required permissions to access
                              this link.
                            </MotifErrorMessage>
                          )}
                          {isInValidSitePath && (
                            <MotifErrorMessage>
                              You do not have the required permissions to access
                              this link.
                            </MotifErrorMessage>
                          )}
                          {isSharePointValidLink && (
                            <MotifInlineMessage variant="success">
                              This Link is valid.
                            </MotifInlineMessage>
                          )}
                        </div>

                        <div className={(
                            isInValidSiteUrl || 
                            isInValidSharePointSite ||
                            isInValidSitePath ||
                            isSharePointValidLink
                          ) ? "proceedbtn1 h62": "proceedbtn1 h100"}>
                          <MotifButton 
                           disabled={issbuttonDisabled}
                          onClick={showSharePointView}>
                            Proceed
                          </MotifButton>
                        </div>
                      </MotifFormField>
                    </div>
                  </div>
                </div>
              </div>
            </MotifFormField>
          </div>
        </MotifModalBody>
      </MotifModal>

      <MotifModal
        label="modalLocalUpload"
        className="motifModaluploadDoc genericModal modalNoHeaderBorder modalHeaderVerticalPadding modalMotifFooter"
        show={openFilesGrid}
        onClose={() => setOpneFilesGrid(false)}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Close",
            title: "Close",
          }}
        >
          Uploading From Local Drive - Make Selection
        </MotifModalHeader>
        <MotifModalBody>
          <div className="Frame534013">
            <div className="Frame54261">
              <div className="Table1 table12">
                <div className="CardHeader1">
                  <div className="CardTitle1">
                    <div className="TextAndSupportingText1">
                      <div className="text12">
                        <div className="Text13">
                          Document Library will contain {totalFiles} documents of combined size of {totalFilesSize} MB following upload
                        </div>
                      </div>
                      <div className="SupportingText1">
                        Document Library limits are {MaxFileFlimit} documents with document size limit of {utils.getGBFormat(MaxFileFlimitSize)} GB{" "}
                        <br/>
                        <span className="file-upload-error-message">
                          {" "}
                          {errorfile
                            ? "Incorrect File Format Selected."
                            : errorFilescount
                            ? filecountLimit + " extra files Selected."
                            : errorFilseSizeCount
                            ? extrasize + "MB extra File Size Selected."
                            : errrduplicate
                            ? " Duplicate file(s) found, click 'Remove Selected' and re-upload the file(s) with a revised name."
                            : errorCharacters
                            ? " Special Character File name Selected."
                            : perFileLimitExceed
                            ? `Per file Limit exceeded (${utils.getBytesInMB(fileUploadLimit)} MB)`
                            : " "}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="CardActions1">
                    <div className="Button1">
                      <MotifButton
                        className={
                          errorfile ||
                          errorFilescount ||
                          errrduplicate ||
                          errorCharacters ||
                          perFileLimitExceed
                            ? "btnRed"
                            : "btnBlock"
                        }
                        onClick={removeSelectedItems}
                      >
                        Remove Selected{" "}
                      </MotifButton>
                    </div>
                    <div className="Button2">
                      <img
                        className="searchIcon"
                        src={searchIcon}
                        alt="search icon"
                      />

                      <MotifSearch
                        aria-label="Search"
                        hideClearButton
                        id="Local-search"
                        onChange={filterLocalFiles}
                        placeholder="Search"
                        className="searchTxtBox"
                        value={SearchLocal}
                      />
                    </div>
                  </div>
                </div>

                <div className="local-selected-file-div">
                  <MotifTable
                    rowData={selectedFile}
                    columnDefs={colDefs}
                    suppressCellFocus
                    suppressRowClickSelection
                    rowSelection="multiple"
                    onGridReady={(event) => onGridReady(event)}
                    onRowSelected={(event) => onRowSelection(event)}
                    pagination
                    paginationPageSize={pagesize}
                    paginationSelect={
                      <MotifPaginationSelect>
                        <MotifPaginationSelectItem
                          onClick={function noRefCheck() {
                            SetPageSize(5);
                          }}
                          selected
                        >
                          Show 5
                        </MotifPaginationSelectItem>
                        <MotifPaginationSelectItem
                          onClick={function noRefCheck() {
                            SetPageSize(10);
                          }}
                        >
                          Show 10
                        </MotifPaginationSelectItem>
                        <MotifPaginationSelectItem
                          onClick={function noRefCheck() {
                            SetPageSize(15);
                          }}
                        >
                          Show 15
                        </MotifPaginationSelectItem>
                      </MotifPaginationSelect>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </MotifModalBody>
        <MotifFooter>
          <div className="local-upload-footer-div">
            <MotifButton
              onClick={() => setOpneFilesGrid(false)}
              size="medium"
              type="button"
              variant="secondary"
            >
              Cancel
            </MotifButton>
            <MotifButton
              disabled={
                errorfile ||
                errorFilescount ||
                errorFilseSizeCount ||
                !selectedFile.length > 0 ||
                errrduplicate ||
                errorCharacters ||
                perFileLimitExceed
                  ? true
                  : false
              }
              onClick={onSumbitupload}
              size="medium"
              type="button"
            >
              Confirm
            </MotifButton>
          </div>
        </MotifFooter>
      </MotifModal>

      <MotifModal
        label="modalSharepointUpload"
        className="sharepoint-modal genericModal modalNoHeaderBorder modalHeaderVerticalPadding modalMotifFooter"
        show={showSharePointModal}
        onClose={() => setShowSharePointModal(!showSharePointModal)}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Close Button",
            title: "Close",
          }}
        >
          Uploading from SharePoint - {sitePath}
        </MotifModalHeader>
        <MotifModalBody>
          {showDirectory && (
            <div ref={spGridRef}>
              <MotifTable
                rowData={sharePointFiles}
                columnDefs={colDefsSharePointGrid}
                suppressCellFocus
                suppressRowClickSelection
                rowSelection="multiple"
                pagination
                paginationPageSize="4"
                onPaginationChanged={() => {setIsSharePointGridPageChanged(!isSharePointGridPageChanged)}}
                onRowSelected={(event) => {
                  let selectedFiles = event.api.getSelectedRows();
                  setSelectedSharePointFiles(selectedFiles);
                  let selectedFilesCount = selectedFiles.length;
                  setTotalFiles(
                    filescountInfo?.filesuploadcount + selectedFilesCount
                  );

                  let selectedSize =
                    filescountInfo?.totalFileSize +
                    _.sumBy(selectedFiles, "size");

                  setTotalFilesSize(utils.getBytesInMB(selectedSize));
                  if (filescountInfo?.availableFilesizeLimit < selectedSize) {
                    seterrorFileSizeCount(true);
                    setExtraSize(
                      utils.getBytesInMB(
                        selectedSize - filescountInfo?.availableFilesizeLimit
                      )
                    );
                  } else {
                    seterrorFileSizeCount(false);
                    setExtraSize("");
                  }
                }}
              >
                <MotifTableTitleBar variant="alt">
                  <div className="CardHeader1">
                    <div className="CardTitle1">
                      <div className="TextAndSupportingText1">
                        <div className="text12">
                          <div className="Text13">
                            Document Library will contain {totalFiles} documents of combined size of {totalFilesSize} MB following upload
                          </div>
                        </div>
                        <div className="SupportingText1">
                          Document Library limits are {MaxFileFlimit} documents with document size limit of {MaxFileFlimitSize} GB<br/>
                          {totalFiles > MaxFileFlimit && (
                            <span className="file-upload-error-message">
                              {" "}
                              {totalFiles - MaxFileFlimit} extra files found.
                            </span>
                          )}
                          {perFileLimitExceed && (
                            <span className="file-upload-error-message">
                              Per file Limit exceeded (
                              {utils.getBytesInMB(fileUploadLimit)} MB)
                            </span>
                          )}
                          {(spdupNameValidation || spFileValidation) && (
                            <span className="file-upload-error-message">
                              Duplicate file(s) or files containing special characters identified, de-select the file(s) highlighted in red to continue.
                            </span>
                          )}
                          {errorFilseSizeCount && (
                            <span className="file-upload-error-message">
                              {extrasize} MB extra File Size Selected.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="CardActions1">
                      <div className="Button2">
                        <MotifFormField>
                          <img
                            className="sharePointSearchIcon"
                            src={searchIcon}
                            alt="sharePointSearchIcon"
                          />
                          <MotifSearch
                            aria-label="Search"
                            hideClearButton
                            id="sharepoint-search"
                            className="searchTxtBox"
                            onChange={filterSharePointFiles}
                            placeholder="Search"
                            value={sharePointSearch}
                          />
                        </MotifFormField>
                      </div>
                    </div>
                  </div>
                </MotifTableTitleBar>
              </MotifTable>
            </div>
          )}
        </MotifModalBody>
        <MotifFooter>
          <div className="add-document-sharepoint-footer-container">
            <div className="add-document-sharepoint-footer-div">
              <MotifButton
                onClick={() => setShowSharePointModal(!showSharePointModal)}
                size="medium"
                type="button"
                variant="secondary"
                label="sharepointCancelButton"
              >
                Cancel
              </MotifButton>
              <MotifTooltip
                trigger={
                  <MotifButton
                    disabled={sharePointConfirmButtonEnabled}
                    onClick={uploadSharePointDocument}
                    size="medium"
                    type="button"
                    label="sharepointConfirmButton"
                  >
                    Confirm
                  </MotifButton>
                }
                hide={!sharePointConfirmButtonEnabled}
                placement="bottom"
              >
                Select file(s) to upload
              </MotifTooltip>
            </div>
          </div>
        </MotifFooter>
      </MotifModal>
    </div>
  );
};

export default UploadModal;

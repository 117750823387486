import React, { useEffect, useState } from "react";
import { MotifButton, MotifFormField, MotifInput } from "@ey-xd/motif-react";
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from "@ey-xd/motif-react/Modal";
import _ from "lodash";
import "../Common/PromptTabDetail.css"

const EditStructureModal = (props) => {

    const { title, subTitle, operationType, targetType, selectedScope, category, section, subSection, addNewNodeToTree, closeEditStructureModal, renameScopeStructure } = props;

    const inputPlaceHolder = (targetType === "category" ? "Enter category name" : (targetType === "section" ? "Enter section name" : "Enter subsection name"));
    const fieldLabel = (targetType === "category" ? "Category" : (targetType === "section" ? "Section" : "Subsection"));
    const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);
    const [isSpecChar, setIsSpecChar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [maxLengthReached, setMaxLengthReached] = useState(false);
    const regex = /^[a-zA-Z0-9 ]*$/;
    const MAXLENGTH = 50;

    const getInitialState = () => {
        if (operationType === "add") {
            return ("");
        }
        else {
            return (targetType === "category" ? category.value : (targetType === "section" ? section.value : subSection.value))
        }
    }
    
    const [enteredText, setEnteredText] = useState(getInitialState());

    useEffect(() => {
        if (enteredText === "" || isSpecChar) {
            setIsConfirmDisabled(true);
        } else {
            if (operationType === "add") {
                setIsConfirmDisabled(false);
            } else {
                if (enteredText !== getInitialState()) {
                    setIsConfirmDisabled(false);
                } else {
                    setIsConfirmDisabled(true);
                }
            }
        }
    }, [enteredText, isSpecChar, operationType])

    /**
     * This method is to handle the category select change event
     * @param {*} value 
     */
    const onTextChange = (e) => {
        setEnteredText(e.target.value);
        let value = e.target.value
        if (value.length > MAXLENGTH) {
            value = value.slice(0, MAXLENGTH);
            setMaxLengthReached(true)
        }
        else {
            setMaxLengthReached(false);
        }
        if (!regex.test(value)) {
            setErrorMessage("Special characters not allowed");
            setIsSpecChar(true);

        } else {
            setErrorMessage('');
            setIsSpecChar(false);

        }
        setEnteredText(value);

    }

    const onTextBlur = () => {
        setMaxLengthReached(false);
    }

    /**
     * This method will be closed the modal
     */
    const btnCloseClick = () => {
        closeEditStructureModal(false);
    };

    /**
     * This method is rename or add  category or section or subsection to structure
     */
    const btnConfirmClick = () => {
        if (operationType === "add") {
            const newObject = {
                children: [],
                id: null,
                nodeType: (targetType === "category" ? 1 : (targetType === "section" ? 2 : 3)),
                value: enteredText
            }
            addNewNodeToTree(newObject);
        }
        else {
            let nodeId = targetType === "category" ? category.id : (targetType === "section" ? section.id : subSection.id);
            renameScopeStructure(targetType, nodeId, enteredText);
        }        
        closeEditStructureModal(false);
    }

    return (
        <div>
            <MotifModal
                id="motifModalEditStructure"
                className="genericModal"
                size="md"
                show={true}
                focusTrapOptions={{
                    tabbableOptions: {
                        displayCheck: "none",
                    },
                }}
                onClose={btnCloseClick}
            >
                <MotifModalHeader>
                    <div className="share-alert-header-title">
                        {title}
                    </div>

                </MotifModalHeader>
                <MotifModalBody>
                    <div className={operationType === "edit" ? "ii-move-prompt-header-container" : "ii-move-prompt-header-container_add"}>
                        {operationType === "edit" && (
                            <span className="ii-move-prompt-header renameTitle">
                                {subTitle}
                            </span>
                        )}
                    </div>
                    <div className="ii-move-prompt-modal-row">
                        <span className="ii-move-prompt-modal-label">Project Scope</span>
                        <span className="ii-move-prompt-modal-label scope">{selectedScope.scopeName}</span>
                    </div>
                    <div className="ii-move-prompt-modal-row new">
                        {
                            (targetType === "section" || targetType === "subSection") &&
                            (
                                <div className="category-div">
                                    <div className="subinner-div">
                                        <div className="inner-div">
                                            <div className="inner-label">Category</div>
                                            <div className="inner-value">{category.value}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            targetType === "subSection" &&
                            (
                                <div className="section-div">
                                    <div className="subinner-div">
                                        <div className="inner-div">
                                            <span className="inner-label">Section</span>
                                            <span className="inner-value">{section.value}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <span className="ii-move-prompt-modal-label subSection">{fieldLabel}</span>
                        <MotifFormField>
                            <MotifInput
                                label="inputtitle"
                                className={`manage-structure-modal-input ${errorMessage ? 'error-border' : ''}`}
                                hideClearButton={true}
                                onChange={onTextChange}
                                type='text'
                                placeholder={inputPlaceHolder}
                                value={enteredText}
                                onBlur={onTextBlur}
                            />
                            <div className="error_container">
                                {maxLengthReached && !errorMessage && (
                                    <div className="max_limit">
                                        Maximum limit reached - 50 characters
                                    </div>
                                )}
                                {errorMessage && <div className='errorText'>
                                    {errorMessage}
                                </div>}
                            </div>
                        </MotifFormField>
                    </div>
                </MotifModalBody>
                <MotifModalFooter>
                    <div className="share-alert-btns-div">
                        <div>
                            <MotifButton
                                variant="primary-alt"
                                onClick={() => {
                                    btnCloseClick();
                                }}
                                className="share-alert-cancel-button"
                            >
                                Cancel
                            </MotifButton>
                        </div>
                        <div>
                            <MotifButton
                                className="share-alert-go-to-tw-button"
                                onClick={btnConfirmClick}
                                disabled={isConfirmDisabled}>
                                Confirm
                            </MotifButton>
                        </div>
                    </div>
                </MotifModalFooter>
            </MotifModal>
        </div>
    );
}
export default EditStructureModal;
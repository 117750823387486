import { createSlice } from "@reduxjs/toolkit";

const instantInsightSlice = createSlice({
  name: "instantInsight",
  initialState: {
    targetSubSection: null,
    selectedPromptsToMove: null,
  },
  reducers: {
    setTargetSubSection: (state, action) => {
      state.targetSubSection = action.payload.targetSubSection;
    },
    setSelectedPromptsToMove: (state, action) => {
      state.selectedPromptsToMove = action.payload.selectedPromptsToMove;
    },
  },
});

export const { setTargetSubSection, setSelectedPromptsToMove } =
  instantInsightSlice.actions;

export default instantInsightSlice.reducer;

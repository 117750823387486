import React from "react";
import MotifTabNavigation, {
  MotifTabControl,
} from "@ey-xd/motif-react/TabNavigation";
import "../ProjectDashboard/ProjectDashboard.css";
import "./Notification.css";

const Notification = () => {
  const tabIds = {
    Active: "Active",
    Draft: "Draft",
    History: "History",
  };

  const handleNotificationManagementTabClick = (tab) => {
    switch (tab) {
      case tabIds.Active:
        //navigate("InstantInsight");
        break;
      case tabIds.Draft:
        //navigate("InstantInsight");
        break;
      case tabIds.History:
        //("InstantInsight");
        break;
      default:
        break;
    }
  };

  return (
    <div className="ProjectDashboardPageContainer">
      <div className="HeaderLayout">
        <div className="HeaderContainer">
          <div className="HeaderTitle">
            <span className="HeaderText">Notifications</span>
          </div>
          <div className="HeaderAction"></div>
        </div>
      </div>
      <div className="notification-managment-container">
        <MotifTabNavigation defaultActiveKey={0}>
          <MotifTabControl
            onClick={() => handleNotificationManagementTabClick(tabIds.Active)}
          >
            Active
          </MotifTabControl>
          <MotifTabControl
            onClick={() =>
                handleNotificationManagementTabClick(tabIds.Draft)
            }
          >
            Draft
          </MotifTabControl>
          <MotifTabControl
            onClick={() =>
                handleNotificationManagementTabClick(tabIds.History)
            }
          >
            History
          </MotifTabControl>
        </MotifTabNavigation>
      </div>
    </div>
  );
};

export default Notification;

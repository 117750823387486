import React, { useContext, useEffect, useState } from 'react';
import { MotifIconButton } from "@ey-xd/motif-react/components/button";
import {
    MotifButton,
    MotifSelect,
    MotifOption,
    MotifFormField,
    MotifLabel
} from "@ey-xd/motif-react";
import PlatformService from '../../../../services/platformService';
import TreeView from "./TreeView";
import arrowLeft from "../../../../assets/image/arrow-left-black.svg";
import noProject from "../../../../assets/image/no-project-image.png";
import editPencil from "../../../../assets/image/edit-pencil.svg";
import plus from "../../../../assets/image/plus_scope.svg";
import { DashboardContext } from "../../../../context/dashboardContext.js";
import EditStructureModal from "../InstantInsightPrompts/EditStructureModal";
import './ManageStructure.css';
import { MotifToast } from "@ey-xd/motif-react";

export default function ManageStructure(props) {
    const platformService = new PlatformService();
    const { updateAddScopeSectionVisibility } = { ...props };
    const { scopeAll } = useContext(DashboardContext);

    //#region Component State
    
    const [selectedScope, setSelectedScope] = useState();
    const [scopeHasStructure, setScopeHasStructure] = useState(true);
    const [treeData, setTreeData] = useState(null);
    const [message, setMessage] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [selectedSubSection, setSelectedSubSection] = useState(null);
    const [showSection, setShowSection] = useState(false);
    const [showSubSection, setShowSubSection] = useState(false);
    const [categories, setCategories] = useState([]);
    const [sections, setSections] = useState([]);
    const [subSections, setSubSections] = useState([]);
    const filteredScope = scopeAll?.filter((scope) => scope.spiiStatus === 3 || scope.spiiStatus === 4);
    const [showEditStructureModal, setShowEditStructureModal] = useState(false);
    const [renamedSuccess, setRenamedSuccess] = useState(false);
    const [renamedError, setRenamedError] = useState(false);
    const [isCategoryDisabled, setIsCategoryDisabled] = useState(true);
    const [isSectionDisabled, setIsSectionDisabled] = useState(true);
    const [isSubSectionDisabled, setIsSubSectionDisabled] = useState(true);
    const [operationType, setOperationType] = useState("");
    const [targetType, setTargetType] = useState("");
    const [nodeExpansionGraph, setNodeExpansionGraph] = useState([]);
    const [editStructureModalTitle, setEditStructureModalTitle] = useState("");
    const [hierarchySaved, setHierarchySaved] = useState(false);
    const constantData = require("../../../../data/constant.js");

    //#endregion

    //#region Side Effects

    useEffect(() => {
        const fetchScopeSectionSubSections = async () => {
            if (selectedScope) {
                try {
                    const treeData = await platformService.getScopeTreeData(selectedScope.scopeId);
                    const formattedTreeData = [...treeData.children];
                    setTreeData(formattedTreeData);
                    resetEditStructure();
                    if (formattedTreeData.length === 0) {
                        setScopeHasStructure(false);
                        setMessage("No Structure created Please create Category, Section and Subsections")
                    }
                    else {
                        setScopeHasStructure(true);
                    }
                } catch (error) {
                    console.error("Error fetching scope section subsections:", error);
                }
            }
            else {
                setMessage("No Selection made Select a Project Scope to view the tree hierarchy")
            }
        };
        fetchScopeSectionSubSections();
    }, [selectedScope, hierarchySaved])

    useEffect(() => {
        if (operationType === "edit") {
            switch (targetType) {
                case "category":
                    setEditStructureModalTitle("Rename Category");
                    break;
                case "section":
                    setEditStructureModalTitle("Rename Section");
                    break;

                case "subSection":
                    setEditStructureModalTitle("Rename Subsection");
                    break;

                default:
                    break;
            }
        }
        else if (operationType === "add") {
            switch (targetType) {
                case "category":
                    setEditStructureModalTitle("Add New Category");
                    break;
                case "section":
                    setEditStructureModalTitle("Add New Section");
                    break;

                case "subSection":
                    setEditStructureModalTitle("Add New Subsection");
                    break;

                default:
                    break;
            }
        }
    }, [operationType, targetType])

    useEffect(()=>{
        if (treeData?.length === 0) {
            setScopeHasStructure(false);
        }
        else {
            setScopeHasStructure(true);
        }
        const categories = treeData || [];
        setCategories(categories);
        if(selectedCategory){
            setSections(selectedCategory.children);
        }
        if (selectedSection) {
            setSubSections(selectedSection.children);
        }
        if (targetType === "category") {
            setShowSection(false);
            setShowSubSection(false);
        }
        if (targetType === "section") {
            setShowSubSection(false);
        }
    },[treeData])

    useEffect(() => {
        if (selectedCategory) {
            setSections(selectedCategory.children);
            setShowSection(true);
        }
    }, [selectedCategory])

    useEffect(() => {
        if (selectedSection) {
            setSubSections(selectedSection.children);
            setShowSubSection(true);
        }
    }, [selectedSection])

    //#endregion

    //#region Methods

    const resetEditStructure = () => {
        setSelectedCategory(null);
        setSelectedSection(null);
        setSelectedSubSection(null);
        setShowSection(false);
        setShowSubSection(false);
    }

    const addNewNodeToTree = async(newObject) => {
        if(targetType === "category"){
            // add to categories
            setTreeData([...treeData, newObject]);
            setSelectedCategory(newObject);
            setNodeExpansionGraph([...nodeExpansionGraph, newObject]);
        }
        else if(targetType === "section"){
            // add to section
            let category = treeData.find((category)=>{
                return category.id === selectedCategory.id
            })
            category.children = [...category.children, newObject];
            setTreeData([...treeData]);
            setSelectedSection(newObject);
            setNodeExpansionGraph([...nodeExpansionGraph, newObject]);
        }
        else if(targetType === "subSection"){
            // add to subSection
            let category = treeData.find((category)=>{
                return category.id === selectedCategory.id
            })
            let section = category.children.find((section)=>{
                return section.id === selectedSection.id
            })
            section.children = [...section.children, newObject];
            setTreeData([...treeData]);
            // Save the hierarchy
            setSelectedSubSection(newObject);
            setNodeExpansionGraph([...nodeExpansionGraph, newObject]);
            await platformService.saveScopeTreeHierarchy({
                "scopeId": selectedScope.scopeId,
                "categoryId": selectedCategory.id,
                "categoryName": selectedCategory.value,
                "sectionId": selectedSection.id,
                "sectionName": selectedSection.value,
                "subSectionId": newObject.id,
                "subSectionName": newObject.value
            });
            setHierarchySaved(true);
        }
        setIsCategoryDisabled(true);
        setIsSectionDisabled(true);  
        setIsSubSectionDisabled(true);
    }

    const onBackBtnClick = () => {
        updateAddScopeSectionVisibility(false);
    }

    const noData = (section) => {
        return (
            <div className="noScopeDiv">
                <img src={noProject} className="no scopes" alt=""></img>
                <div className="noScopeMsgCont">
                    <div className="no-project-scope-title">
                        No Selection Made
                    </div>
                    <div className="no-project-scope-detail">
                        {
                            section === "treeview" ? message : "Please make a selection from the left pane"
                        }
                    </div>
                </div>
            </div>
        )
    }

    const onScopeChange = (item) => {
        setSelectedScope(item);
    }

    const onCategoryChange = (item) => {
        setNodeExpansionGraph([...nodeExpansionGraph, item]);
        setSelectedCategory(item);
        setSelectedSection(null);
        setSelectedSubSection(null);
        setIsCategoryDisabled(false);
    }

    const onSectionChange = (item) => {
        setNodeExpansionGraph([...nodeExpansionGraph, item]);
        setSelectedSection(item);
        setSelectedSubSection(null);
        setIsSectionDisabled(false);
    }

    const onSubsectionChange = (item) => {
        setNodeExpansionGraph([...nodeExpansionGraph, item]);
        setSelectedSubSection(item)
        setIsSubSectionDisabled(false);
    }

    const renameBtnClick = (event, type) => {
        setOperationType("edit");
        setTargetType(type);
        setShowEditStructureModal(true);
    }

    const addBtnClick = (event, type) => {
        setOperationType("add");
        setTargetType(type);             
        setShowEditStructureModal(true);
    }

    /**
     * This method is to save the update node value to database or temp dataset
     * Parameters:
      * targetType: this one is the selected type of node
      * selectedId: this one is the selected node id by user
      * selectedText: this one is the updated value by user
      *
     */
    const renameScopeStructure = async (targetType, selectedId, selectedText) => {        
        if (operationType === "edit") {
            if(selectedId!==null)
                {                    
                    let structureRequest = {};
                    structureRequest.id = selectedId;
                    structureRequest.type = targetType;
                    structureRequest.name = selectedText;
                    const updateDataStatus = await platformService.renameScopeStructure(structureRequest);
                    if(updateDataStatus===true)
                    {
                        setRenamedSuccess(true);
                        const treeData = await platformService.getScopeTreeData(selectedScope.scopeId);
                        const formattedTreeData = [...treeData.children];
                        setTreeData(formattedTreeData);  
                        setIsCategoryDisabled(true);
                        setIsSectionDisabled(true);  
                        setIsSubSectionDisabled(true);
                    }
                    else{
                        setRenamedError(true);
                    }                    
                }
            else {
                if (targetType === "category") {
                    updateValueByNodeType(1, selectedText);
                    setRenamedSuccess(true);
                }
                else if (targetType === "section") {
                    updateValueByNodeType(2, selectedText);
                    setRenamedSuccess(true);
                }
                else if (targetType === "subSection") {
                    updateValueByNodeType(3, selectedText);
                    setRenamedSuccess(true);
                    setIsCategoryDisabled(true);
                    setIsSectionDisabled(true);
                    setIsSubSectionDisabled(true);
                }
            }            
        }
    }

    /**
     * This method is to handle the updation call of tree node value
     * Parameters:
      * targetNodeType: this one is the selected type of node
      * newValue: this one is the selected value by user
      *
     */
    const updateValueByNodeType = (targetNodeType, newValue) => {
        const updatedTreeData = [...treeData];
        updatedTreeData.forEach(node => updateTreeValueByNodeType(node, targetNodeType, newValue));
        setTreeData(updatedTreeData);
    }

    /**
     * This method is to update specific node value in a tree
     * Parameters:
      * node: this one is the selected node by user
      * targetNodeType: this one is the selected type of node
      * newValue: this one is the selected value by user
      *
     */
    const updateTreeValueByNodeType = (node, targetNodeType, newValue) => {    
    if (node.nodeType === targetNodeType && node.id === null) {
        node.value = newValue;
    }    
    if (Array.isArray(node.children)) {
        node.children.forEach(child => updateTreeValueByNodeType(child, targetNodeType, newValue));
    }
    }

    /**
     * This method is to capitalize the first letter of test for toast message
     * @param {*} value
     */
    const capitalize = (word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    //#endregion

    //#region Component JSX

    return (
        <div className="manageStructureComponent">
            <div className="back-section project-scope-field-txt">
                <MotifIconButton className="icon-btn" onClick={onBackBtnClick}>
                    <div className="back-btn">
                        <img src={arrowLeft} alt="Back" /><span>Back</span>
                    </div>  
                </MotifIconButton>
            </div>
            <div className="parent-top-heading">
                <div className="top-heading">
                    Manage Structure
                </div>
            </div>
            <div className='projectScopeSlection'>
                <span className="select-project-scope-dropdown-title">Project Scope</span>
                <MotifFormField>
                    {
                        filteredScope?.length === 0 ?
                            <MotifLabel id="selectscope">No Project Scope Found</MotifLabel>
                            : null
                    }
                    <MotifSelect
                        id="scopeSelect"
                        label="selectscope"
                        className="InputInstantInsight"
                        ariaLabelledBy="select-label"
                        placeholder="Select Project Scope"
                        onChange={onScopeChange}
                        disabled={filteredScope?.length === 0 ? true : false}
                        required
                    >
                        {filteredScope &&
                            [...filteredScope.map((item) => {
                                return (
                                    <MotifOption
                                        className={item.scopeName}
                                        value={item}
                                    >
                                        {item.scopeName}
                                    </MotifOption>
                                );
                            })]
                        }
                    </MotifSelect>
                </MotifFormField>
            </div>
            <div className='manageStructureMain'>
                <div className='editStructure'>
                    <div className='structureTitle'>
                        Edit Structure
                        <span>Add or rename the Category, Section and Subsection</span>
                    </div>
                    <div className='structureData'>
                        {!selectedScope ? noData("editSection") :
                            <>
                                <div className='infoGroup'>
                                    <MotifLabel>Category</MotifLabel>
                                    <MotifSelect
                                        label="selectCategory"
                                        placeholder='Select Category'
                                        className="Input"
                                        value={selectedCategory}
                                        onChange={onCategoryChange}
                                        required
                                    >
                                        {
                                            categories &&
                                            categories.map((category) => {
                                                return (
                                                    <MotifOption
                                                        key={category.id}
                                                        value={category}
                                                    >
                                                        {category.value}
                                                    </MotifOption>
                                                );
                                            })
                                        }
                                    </MotifSelect>
                                    <div className='buttonsGroup'>
                                        <MotifIconButton className="iconBtn" onClick={(e) => renameBtnClick(e, "category")} disabled={isCategoryDisabled}>
                                            <img src={editPencil} alt="Rename" /><span>Rename</span>
                                        </MotifIconButton>
                                        <MotifButton variant="secondary" className="iconBtn btn-add-scope" onClick={(e) => addBtnClick(e, "category")}
                                        >
                                            <img src={plus} alt="Add" />Add
                                        </MotifButton>
                                    </div>
                                </div>
                                {
                                    showSection && (
                                        <div className='infoGroup'>
                                            <MotifLabel>Section</MotifLabel>
                                            <MotifSelect
                                                label="selectSection"
                                                placeholder='Select Section'
                                                className="Input"
                                                value={selectedSection}
                                                onChange={onSectionChange}
                                                required
                                            >
                                                {
                                                    sections &&
                                                    sections.map((section) => {
                                                        return (
                                                            <MotifOption
                                                                key={section.id}
                                                                value={section}
                                                            >
                                                                {section.value}
                                                            </MotifOption>
                                                        );
                                                    })
                                                }
                                            </MotifSelect>
                                            <div className='buttonsGroup'>
                                                <MotifIconButton className="iconBtn" onClick={(e) => renameBtnClick(e, "section")} disabled={isSectionDisabled}>
                                                    <img src={editPencil} alt="Rename" /><span>Rename</span>
                                                </MotifIconButton>
                                                <MotifButton variant="secondary" className="iconBtn btn-add-scope"
                                                    onClick={(e) => addBtnClick(e, "section")}>
                                                    <img src={plus} alt="Add" />Add
                                                </MotifButton>
                                            </div>
                                        </div>
                                    )
                                }
                            </>
                        }   

                        {
                            showSubSection && (
                                <div className='infoGroup'>
                                    <MotifLabel>Subsection</MotifLabel>
                                    <MotifSelect
                                        label="selectSubSection"
                                        placeholder='Select Subsection'
                                        className="Input"
                                        value={selectedSubSection}
                                        onChange={onSubsectionChange}
                                        required
                                    >
                                        {
                                            subSections &&
                                            subSections.map((subSection) => {
                                                return (
                                                    <MotifOption
                                                        id={subSection.id}
                                                        value={subSection}
                                                    >
                                                        {subSection.value}
                                                    </MotifOption>
                                                );
                                            })
                                        }
                                    </MotifSelect>
                                    <div className='buttonsGroup'>
                                        <MotifIconButton className="iconBtn" onClick={(e) => renameBtnClick(e, "subSection")} disabled={isSubSectionDisabled}>
                                            <img src={editPencil} alt="Rename" /><span>Rename</span>
                                        </MotifIconButton>
                                        <MotifButton variant="secondary" className="iconBtn btn-add-scope" onClick={(e) => addBtnClick(e, "subSection")}
                                        >
                                            <img src={plus} alt="Add" />Add
                                        </MotifButton>
                                    </div>
                                </div>
                            )
                        }

                        {
                            showEditStructureModal && (
                                <EditStructureModal
                                    title={editStructureModalTitle}
                                    subTitle={targetType === 'category'
                                        ? constantData.renameCategoryHeader
                                        : targetType === 'section'
                                            ? constantData.renameSectionHeader
                                            : targetType === 'subSection'
                                                ? constantData.renameSubSectionHeader
                                                : ''}
                                    operationType={operationType}
                                    targetType={targetType}
                                    selectedScope={selectedScope}
                                    category={selectedCategory}
                                    section={selectedSection}
                                    subSection={selectedSubSection}
                                    addNewNodeToTree={addNewNodeToTree}
                                    closeEditStructureModal={() => {
                                        setShowEditStructureModal(false);
                                    }}
                                    renameScopeStructure={renameScopeStructure}
                                />
                            )
                        }
                    </div>
                </div>
                <div className='treeView'>
                    <div className='structureTitle'>
                        Tree View
                        <span>Preview of Category, Section and Subsection</span>
                    </div>
                    <div className='structureData'>
                        {!selectedScope || !scopeHasStructure ? noData("treeview") :
                            <div>
                                <TreeView treeData={treeData} showCheckbox={true} nodeExpansionGraph={nodeExpansionGraph} />
                            </div>
                        }
                    </div>
                </div>
            </div>
            {renamedSuccess && (
                <MotifToast
                    variant="success"
                    actionName=""
                    position="bottom"
                    onClose={() => {
                        setRenamedSuccess(false);
                    }}
                >
                    <div>{capitalize(targetType)} renamed successfully.</div>
                </MotifToast>
            )}
            {renamedError && (
                <MotifToast
                    variant="error"
                    actionName=""
                    position="bottom"
                    onClose={() => {
                        setRenamedError(false);
                    }}
                >
                    <div>Renaming the {capitalize(targetType)} failed due to a technical error. Please try again later.
                    </div>
                </MotifToast>
            )}
        </div>
    )

    //#endregion
}

import React, { useContext, useEffect, useState } from "react";
import {
  MotifButton,
  MotifCheckbox,
  MotifFooter,
  MotifFormField,
  MotifSearch,
  MotifTooltip,
} from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import MotifTabNavigation, {
  MotifTabControl,
} from "@ey-xd/motif-react/TabNavigation";
import _ from "lodash";
import Utils from "../../../utils/Utils";
import DocumentService from "../../../services/documentService";
import "./DocumentUploader.css";
import search from "../../../assets/image/search.svg";
import DocumentTable from "./DocumentTable";
import { DashboardContext } from "../../../context/dashboardContext";
import iconProgressLoaderCircle from "../../../assets/image/Loading_indicator_circles.svg";
import { getDocument } from 'pdfjs-dist';
import warningCircle from '../../../assets/image/warning-upload.svg';

const DocumentUploader = (props) => {
  const utils = new Utils();
  const documentservice = new DocumentService();

  const { setIsDocumentUploadProcessInitiated } = useContext(DashboardContext);

  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [fileSearchValue, setFileSearchValue] = useState("");
  const [totalDocumentsCount, setTotalDocumentsCount] = useState(0);
  const [totalDocumentsSize, setTotalDocumentsSize] = useState(0);
  const [allowRowSelection, setAllowRowSelection] = useState(false);
  const [activeTabStates, setActiveTabStates] = useState("");
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [showDocumentRemovedMessage, setShowDocumentRemovedMessage] =
    useState(false);
  const [tableHeight, setTableHeight] = useState(100);
  const [showLoader, setShowLoader] = useState(false);
  const [unprotectedFiles, setUnprotectedFiles] = useState([]);
  const [isProtectedFiles, setIsProtectedFiles] = useState(false);
  const [isUnProtectedFiles, setIsUnProtectedFiles] = useState(false);
  const [protectedFiles, setProtectedFiles] = useState([]);
  const [showPassTab, setShowPassTab] = useState(false);

  const tabStates = {
    ShowDocuments: "ShowDocuments",
    ModifyDocuments: "ModifyDocuments",
    DuplicateDocuments: "DuplicateDocuments",
    SpecialCharsDocuments: "SpecialCharsDocuments",
    UnsupportedDocuments: "UnsupportedDocuments",
    PasswordProtectedDocuments: "PasswordProtectedDocuments",
  };

  useEffect(() => {
    setActiveTabStates(tabStates.ShowDocuments);
      /**
     * get protected files
     */
    handleUploadedFiles(props.documentList);
    setDocuments(props.documentList);
    updatePageHeight();
    setShowLoader(true);
  }, [props.documentList]);

  useEffect(() => {
    let totalFilesCount = props?.documentStates?.documentsCount;
    let totalFilesSize = props?.documentStates?.documentsSize;
    let totalCount = documents.length;
    let totalSize = _.sumBy(documents, (doc) => doc?.size) ?? 0;
    setTotalDocumentsCount(totalFilesCount + totalCount);
    setTotalDocumentsSize(totalFilesSize + totalSize);
    if(fileSearchValue){
      let docs = _.filter(documents, function (item) {
        return (
          item.file.name.toLowerCase().includes(fileSearchValue.toLowerCase()) 
        );
      });
      setFilteredDocuments(docs);
    } else {
      setFilteredDocuments(documents);
    }
  }, [documents, fileSearchValue]);

  useEffect(()=>{
    if(documents.length > 0 && protectedFiles.length===0){
      handleDocumentTabClick(1);
    }
  },[protectedFiles])


  // useEffect(()=>{


  // },[fileSearchValue])

    /**
     * Show the ‘Loader’ message for 3 seconds, and then make it disappear
     */
    useEffect(() => {
      setTimeout(() => {
        setShowLoader(false);
      }, 4000);
    }, []);
  
    /**
   * Identify selected file is protected or unprotected
   * @param {*} chosenFiles 
   */

  const handleUploadedFiles = async (chosenFiles) => {
     /**
     * get protected files
     */
     const vProtectedFiles = await getProtectedFiles(chosenFiles);
     if (vProtectedFiles && (vProtectedFiles.length > 0)) {
       setProtectedFiles(vProtectedFiles);
       setIsProtectedFiles(true);
       setActiveTabStates(tabStates.PasswordProtectedDocuments);
     } else {
       setIsUnProtectedFiles(true);
     }
    }

    /**
   * Identify selected file is protected or unprotected
   * @param {*} vSelectedFiles 
   * @returns the protected files
   */
  const getProtectedFiles = async (vSelectedFiles) => {
    let vProtectedFiles = [];
    let vUnprotectedFiles = [];
    if (vSelectedFiles) {
      for (const vFile of vSelectedFiles) {
        if (vFile.file.type === "application/pdf") {
          const isProtected = await isFileProtected(vFile.file);
          if (isProtected) {
            vProtectedFiles.push(vFile);
          } else {
            vUnprotectedFiles.push(vFile);
          }
        } else {
          vUnprotectedFiles.push(vFile);
        }
      }
    }
    setProtectedFiles(vProtectedFiles);
    setUnprotectedFiles(vUnprotectedFiles);
    setDocuments(vUnprotectedFiles);
    return vProtectedFiles;
  };

  /**
   * Check if file is protected or not
   * @param {*} vFile 
   * @returns 
   */
  const isFileProtected = (vFile) => {
    let isProtected = false;
    return new Promise((resolve, reject) => {
      const vReader = new FileReader();
      vReader.onload = async () => {
        return resolve(isPdfPasswordProtected(vReader.result));
      };
      vReader.onerror = () => reject(vReader.error);
      vReader.readAsArrayBuffer(vFile);
    });
  };

  const isPdfPasswordProtected = async (pdfBytes) => {
    try {
      await getDocument(pdfBytes).promise;
      return false;
    } catch (error) {
      if (error.name === 'PasswordException') {
        return true;
      }
      throw error;
    }
  };
    
  /**
   * This method will provides the dynamic width to the document and refrence based on that parent div
   */
  const updatePageHeight = () => {
    setTableHeight(Math.round(window.innerHeight / 4));
  };

  const onSubmitValidDocuments = async () => {
    if (selectedDocuments.length > 0) {
      const result = await documentservice.uploadLocalDocumentsV2(selectedDocuments);
      if (result.status === 200) {
        setIsDocumentUploadProcessInitiated(true);
        props.localFileUploadSuccess(true);
      } else {
        props.localFileUploadSuccess(false);
      }
      props.onCloseUploadModal(false);
    }
  };

  const handleDocumentTabClick = (tabId) => {
    hideAllTabContent();
    switch (tabId) {
      case 1:
        //document.getElementById("tabDocuments").style.display = "block";
        setActiveTabStates(tabStates.ShowDocuments);
        break;
      case 2:
        document.getElementById("tabDuplicateDocuments").style.display =
          "block";
        setActiveTabStates(tabStates.DuplicateDocuments);
        break;
      case 3:
        document.getElementById("tabSpecialCharsDocuments").style.display =
          "block";
        setActiveTabStates(tabStates.SpecialCharsDocuments);
        break;
      case 4:
        document.getElementById("tabUnsupportedDocuments").style.display =
          "block";
        setActiveTabStates(tabStates.UnsupportedDocuments);
        break;
        case 5:
        document.getElementById("tabPasswordProtectedDocuments").style.display =
          "block";
        setActiveTabStates(tabStates.PasswordProtectedDocuments);
        break;
      default:
        document.getElementById("tabDocuments").style.display = "block";
        setActiveTabStates(tabStates.ShowDocuments);
        break;
    }
  };

  const allowDocumentTableSelection = () => {
    setActiveTabStates(tabStates.ModifyDocuments);
    setAllowRowSelection(true);
  };

  const removeDocumentSelection = () => {
    setActiveTabStates(tabStates.ShowDocuments);
    setAllowRowSelection(false);
  };

  const onSelectDocuments = (documents) => {
    setShowDocumentRemovedMessage(false);
    setSelectedDocuments(documents);
  };

  const moveProtectedFileWithPassword = (file) =>{
    setDocuments((documents)=>_.concat(documents, file));
    setProtectedFiles((protectedFiles)=>_.filter(protectedFiles, doc=>doc.file.name!==file.file.name && doc.file.lastModified !== file.file.lastModified));
  }

  const hideAllTabContent = () => {
    //document.getElementById("tabDocuments").style.display = "none";
    // document.getElementById("tabDuplicateDocuments").style.display = "none";
    // document.getElementById("tabSpecialCharsDocuments").style.display = "none";
    // document.getElementById("tabUnsupportedDocuments").style.display = "none";
    //document.getElementById("tabPasswordProtectedDocuments").style.display = "none";    
  };

  const removeDocuments = () => {
    if (selectedDocuments.length > 0) {
      let filterDocuments = documents;
      _.remove(filterDocuments, (item) =>
      _.some(selectedDocuments, function(doc){
        return doc.file.name===item.file.name
      })
      );
      setDocuments([...filterDocuments]);
      removeDocumentSelection();
      setSelectedDocuments([]);
    }
  };

  return (
    <MotifModal
      id="documentUploadModal"
      show={true}
      size="xl"
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      onClose={() => {
        props.onCloseUploadModal(false);
      }}
    >
      <MotifModalHeader
        closeButtonProps={{
          "aria-label": "Close",
          title: "Close",
        }}
      >
        Uploading from local drive - Make selection
      </MotifModalHeader>
      <MotifModalBody>
      {showLoader ?
        <div className="loaderDiv">
          <div>
          <img src={iconProgressLoaderCircle} alt='Progress' className="refreshIcon " />
          </div>
          <div className="refreshListText">
            Loading
          </div>
        </div>
        : <>     
        <div className="divider-container">
          <div className="upload-doc-divider"></div>
        </div>
        <div className="modal-uploader-body">
          <div className="document-uploader-tabs">
            <MotifTabNavigation defaultActiveKey={isProtectedFiles ? 1 : 0} id="documentUploaderTabs">
              <MotifTabControl
                id="uploadDocuments"
                onClick={() => handleDocumentTabClick(1)}
                className={!protectedFiles.length ? 'motif-active' : ''}
              >
                Documents
              </MotifTabControl>
              {/* <MotifTabControl
                  id="uploadDuplicateDocuments"
                  onClick={() => handleDocumentTabClick(2)}
                >
                  Duplicates
                </MotifTabControl>
                <MotifTabControl
                  id="uploadSpecialCharsDocuments"
                  onClick={() => handleDocumentTabClick(3)}
                >
                  Special characters
                </MotifTabControl>
                <MotifTabControl
                  id="uploadUnsupportedDocuments"
                  onClick={() => handleDocumentTabClick(4)}
                >
                  Unsupported
                </MotifTabControl> */}
                <MotifTabControl
                id="uploadPasswordProtectedDocuments"
                onClick={() => handleDocumentTabClick(5)}
                disabled={showPassTab}
                className={!protectedFiles.length>0 ? 'hidden-doc-tab' : ''}
              >
                Password protected
                <img alt='icon' src={warningCircle} />
              </MotifTabControl>
            </MotifTabNavigation>
          </div>
          <div className="uploader-modal-body-wrapper">
            {[tabStates.ShowDocuments, tabStates.ModifyDocuments, tabStates.PasswordProtectedDocuments].includes(
              activeTabStates
            ) && (
              <>
              {activeTabStates === tabStates.ShowDocuments &&
              <>
                  <div className="document-header" style={{display:'none'}}>
                    <div className="header-wrapper">
                      <span className="document-uploader-title">
                        Document list
                      </span>
                      <span className="document-uploader-description">
                        Click ‘Confirm’ to upload
                      </span>
                    </div>

                    <MotifFormField>
                      <img className="searchIconDocument" src={search} alt="" />
                      <MotifSearch
                        className="searchTxtBoxDocument"
                        aria-label="Search"
                        hideClearButton
                        id="docs-search"
                        placeholder="Search"
                        value={fileSearchValue}
                        onChange={(e) => {
                          setFileSearchValue(e.target.value);
                        }}
                      />
                    </MotifFormField>
                  </div>
                  <div className="document-checkbox-filter-wrapper" style={{display:'none'}}>
                    <div className="document-checkbox-filers">
                      <div className="checkbox-filters-box">
                        <div>
                          <MotifCheckbox
                            id="pdfDocFilter"
                            name="pdfDocFilter"
                            className="checkbox-filter"
                          >
                            PDF
                          </MotifCheckbox>
                        </div>
                        <div>
                          <MotifCheckbox
                            id="pptDocFilter"
                            name="pptDocFilter"
                            className="checkbox-filter"
                          >
                            PPTX
                          </MotifCheckbox>
                        </div>
                        <div>
                          <MotifCheckbox
                            id="docDocFilter"
                            name="docDocFilter"
                            className="checkbox-filter"
                          >
                            DOC
                          </MotifCheckbox>
                        </div>
                        <div>
                          <MotifCheckbox
                            id="xlsDocFilter"
                            name="xlsDocFilter"
                            className="checkbox-filter"
                          >
                            XLS
                          </MotifCheckbox>
                        </div>
                        <div>
                          <MotifCheckbox
                            id="txtDocFilter"
                            name="txtDocFilter"
                            className="checkbox-filter"
                          >
                            TXT
                          </MotifCheckbox>
                        </div>
                      </div>
                      <span className="checkbox-filter-text">
                        Select to filter by file type
                      </span>
                    </div>
                    <div className="document-states-wrapper">
                      <span className="document-states-value">
                        {totalDocumentsCount}/
                        {props?.documentStates?.maxDocumentCount}
                      </span>
                      <span className="document-states-value">
                        {utils.getBytesInMB(totalDocumentsSize)}MB/
                        {props?.documentStates?.maxDocumentSize}GB
                      </span>
                    </div>
                  </div>

                  <div className="document-tab-header">
                    <div className="document-uploader-card">
                      <span className="document-uploader-card-header">
                      Document Library will contain {totalDocumentsCount} documents of combined size of {utils.getBytesInMB(totalDocumentsSize)} MB following upload
                      </span>
                      <span className="document-uploader-card-text">
                      Select upto {props?.documentStates?.maxDocumentCount} files within document size limit of {props?.documentStates?.maxDocumentSize} GB
                      </span>
                    </div>
                    <MotifFormField>
                      <img className="searchIconDocument" src={search} alt="" />
                      <MotifSearch
                        className="searchTxtBoxDocument"
                        aria-label="Search"
                        hideClearButton
                        id="docs-search"
                        placeholder="Search"
                        value={fileSearchValue}
                        onChange={(e) => {
                          setFileSearchValue(e.target.value);
                        }}
                      />
                    </MotifFormField>

                    
                  </div>
              </>
              }
                {activeTabStates === tabStates.PasswordProtectedDocuments &&
              <>
                  <div className="document-header">
                    <div className="header-wrapper">
                      <span className="document-uploader-title">
                      Password protected files identified, enter a password to continue
                      </span>
                      <span className="document-uploader-description">
                      Files without a password will be discarded
                      </span>
                    </div>                  
                  </div>                 
              </>
              }
              </>
            )}

            <div className="document-uploader-body">
            {activeTabStates === tabStates.ShowDocuments &&
              <div id="tabDocuments" className="document-tabs">
                <div
                  className="document-uploader-document-table"
                  style={{ height: `${tableHeight}px` }}
                >
                  <DocumentTable
                    documentType={"documents"}
                    documents={filteredDocuments}
                    allowSelection={allowRowSelection}
                    onDocumentsSelection={onSelectDocuments}
                    showMessage={showDocumentRemovedMessage}
                  />
                </div>
              </div>
            }
              <div
                id="tabDuplicateDocuments"
                className="document-tabs hiddenTabContent"
              >
                <h3>Duplicate Documents</h3>
              </div>
              <div
                id="tabSpecialCharsDocuments"
                className="document-tabs hiddenTabContent"
              >
                <h3>Special Chars Documents</h3>
              </div>
              <div
                id="tabUnsupportedDocuments"
                className="document-tabs hiddenTabContent"
              >
                <h3>Unsupported Documents</h3>
              </div>
             
              <div
                id="tabPasswordProtectedDocuments"
                className="document-tabs"
              >
                 {activeTabStates === tabStates.PasswordProtectedDocuments &&
                 <div
                  className="document-uploader-document-table"
                  style={{ height: `${tableHeight}px` }}
                >
                  <DocumentTable
                    documentType={"passprotecteddocuments"}
                    documents={protectedFiles}
                    allowSelection={allowRowSelection}
                    onDocumentsSelection={onSelectDocuments}
                    showMessage={showDocumentRemovedMessage}
                    onPasswordSubmit={moveProtectedFileWithPassword}
                  />
                </div>
                  }
              </div>
              
            </div>
          </div>
        </div>
        </>}
      </MotifModalBody>
      <MotifFooter>
        <div className="document-uploader-footer">
          <div className="document-upload-left-flex">
          {activeTabStates === tabStates.ShowDocuments && !showLoader && (
            // <>
            //   <MotifButton
            //     label="btnModify"
            //     onClick={allowDocumentTableSelection}
            //     size="medium"
            //     type="button"
            //     variant="secondary"
            //   >
            //     Modify selection
            //   </MotifButton>
            // </>
            <MotifButton
                      label="btnModify"
                      size="medium"
                      type="button"
                      variant="secondary"
                      className="doc-remove-button"
                      onClick={removeDocuments}
                      disabled={selectedDocuments.length <= 0}
                    >
                      Remove documents
                    </MotifButton>
          )}
          </div>
          {[
            tabStates.ShowDocuments,
            tabStates.SpecialCharsDocuments,
            tabStates.UnsupportedDocuments,
            tabStates.PasswordProtectedDocuments
          ].includes(activeTabStates) && (
            <div className="document-upload-primary-buttons">
              <MotifButton
                label="btnModify"
                size="medium"
                type="button"
                variant="secondary"
                onClick={() => {
                  props.onCloseUploadModal(false);
                }}
              >
                Cancel
              </MotifButton>
              {!showLoader && activeTabStates === tabStates.ShowDocuments &&
              <MotifButton
                size="large"
                type="button"
                disabled={selectedDocuments.length <= 0}
                onClick={onSubmitValidDocuments}
              >
                Confirm
              </MotifButton>
              }
            </div>
          )}

          {activeTabStates === tabStates.ModifyDocuments && (
            <div className="modify-documents-footer-wrapper">
              <span className="modify-selection-text">Modify selection</span>
              {selectedDocuments?.length <= 0 && (
                <MotifTooltip
                  trigger={
                    <MotifButton
                      label="btnModify"
                      size="medium"
                      type="button"
                      variant="secondary"
                      disabled={selectedDocuments.length <= 0}
                    >
                      Remove documents
                    </MotifButton>
                  }
                >
                  Select at least one document to continue
                </MotifTooltip>
              )}

              {selectedDocuments?.length > 0 && (
                <MotifTooltip
                  trigger={
                    <MotifButton
                      label="btnModify"
                      size="medium"
                      type="button"
                      variant="secondary"
                      onClick={removeDocuments}
                      className="doc-upload-remove-documents-button"
                    >
                      Remove documents
                    </MotifButton>
                  }
                >
                  Click to remove selected documents
                </MotifTooltip>
              )}

              <MotifButton
                label="btnModify"
                size="medium"
                type="button"
                variant="secondary"
                onClick={removeDocumentSelection}
              >
                Back
              </MotifButton>
            </div>
          )}
        </div>
      </MotifFooter>
    </MotifModal>
  );
};
export default DocumentUploader;

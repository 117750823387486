import React from 'react';
import { useMsal } from '@azure/msal-react';
import {
    MotifVerticalNavigationMenuItem, MotifVerticalNavigationMenu, MotifVerticalNavigation,
    MotifVerticalNavigationContent, MotifVerticalNavigationFooter
} from "@ey-xd/motif-react";
import { Link, Outlet, useLocation } from 'react-router-dom';
import "./AdminDashboard.css";
import adminDashboard from "../../assets/image/admin-dashboard.svg";
import menu from "../../assets/image/menu.svg";
import avatarImage from "../../assets/image/default-user-icon.jpg";
import logOut from "../../assets/image/log-out.svg";
import logo from "../../assets/image/logo.svg";
import configureSetting from "../../assets/image/configure-setting.svg";
import notification from "../../assets/image/notification.svg";
import promptManagement from "../../assets/image/repository.svg";
import Footer from "../common/Footer.js";

/**
   * Description
   * -----------
   * This method will be helpful for using the react hooks in class component
   */
function withMyHook(Component) {
    return function WrappedComponent(props) {
        const myInstance = useMsal();
        const myLocation = useLocation();
        return <Component {...props} myInstance={myInstance} myLocation={myLocation} />;
    }
}

class AdminDashboard extends React.Component {
    constructor() {
        super();

        this.state = {
            collapseMenu: true,
            showUserMenu: false,
            adminDashboardClick: true,
            configSettingsClick: false,
            promptManagement: false,
            notificationClick: false
        }
        this.highlightAdminDashboardNavigationOption = this.highlightAdminDashboardNavigationOption.bind(this);
    }

    //Set Explore Your Data menu option as selected
    highlightAdminDashboardNavigationOption() {
        if (this) {
            this.setState({ adminDashboardClick: true });
            this.setState({ configSettingsClick: false });
            this.setState({ promptManagement: false });
            this.setState({ notificationClick: false });
        }
    };
     //Set Explore Your Data menu option as selected
     highlightConfigSettingsNavigationOption() {
        if (this) {
            this.setState({ adminDashboardClick: false });
            this.setState({ configSettingsClick: true });
            this.setState({ promptManagement: false });
            this.setState({ notificationClick: false });
        }
    };
     //Set Explore Your Data menu option as selected
     highlightPromptManagementNavigationOption() {
        if (this) {
            this.setState({ adminDashboardClick: false });
            this.setState({ configSettingsClick: false });
            this.setState({ promptManagement: true });
            this.setState({ notificationClick: false });
        }
    };
     //Set Explore Your Data menu option as selected
     highlightNotificationNavigationOption() {
        if (this) {
            this.setState({ adminDashboardClick: false });
            this.setState({ configSettingsClick: false });
            this.setState({ promptManagement: false });
            this.setState({ notificationClick: true });
        }
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        const url = window.location.pathname;        

        // Set highlight as per url
        this.setMenuHighlightAsPerURL(url);
    }
    /**
     * Set menu option highlight as per url.
     * @param {string} url 
     */


    setMenuHighlightAsPerURL = (url) => {
        // eslint-disable-next-line default-case
        switch (url) {
            case "/adminDashboard/dashboardPlatformAdmin":
                this.highlightAdminDashboardNavigationOption();
                break;
        }
    }

    onToggleButtonClick = () => {
        if (this.state.collapseMenu === true) {
            this.setState({ collapseMenu: false });
        }
        else {
            this.setState({ collapseMenu: true });
        }
    };
    showLoggedInUserMenu = () => {
        this.setState({ showUserMenu: true });
        document.body.addEventListener('click', this.hideLoggedInUserMenu);
    };
    hideLoggedInUserMenu = () => {
        this.setState({ showUserMenu: false });
    };
    OnLogoClick = () => {
        this.setState({ navigateToDashboard: true });
    };
    render() {
        return (
            <div>
                <div className="adminDashboardDetails">
                    <div className="adminDashboardHeaderNav">
                        <div className="adminDashboardLogoAndMenu" >
                            <div className="adminDashboardLogoButton" >
                                <div className="adminDashboardLogoButtonBase" >
                                    <img alt='icon' onClick={() => this.onToggleButtonClick()}
                                        src={menu} header
                                    ></img>
                                </div>
                            </div>
                            <div className="ProjectDashboardLogoEngGap" >
                                <div className="ProjectDashboardLogoProduct" >
                                    <div className="ProjectDashboardLogo" >
                                        <div className="ProjectDashboardContainerLogo" >
                                            <img alt='icon' className="img-hover" src={logo} onClick={() => this.OnLogoClick()}></img>
                                        </div>
                                    </div>
                                    <div className="ProductName">Digital Diligence Assistant</div>
                                </div>
                            </div>
                        </div>
                        <div className="ProjectDashboardContent1" >
                        <div className="ProjectDashboardNavDropdown" >
                                <div className="ProjectDashboardDropdown" >
                                    {this.state.showUserMenu && (<div className='ProjectDashboardDropdownDiv'>
                                        <div className="userMenupopupcontainer">
                                            <div className='userMenupopupcontainerDiv'>
                                                <div className="usermenuwidthscratch">
                                                    <div className="usermenuwidth">
                                                        <div className='usermenuwidthDiv'>
                                                            <div className="usermenuchane">
                                                                <div className="usermenushow">
                                                                    <div className="displaymenu">

                                                                        <div
                                                                            className='displaymenuDiv'
                                                                        >
                                                                            {
                                                                                <img
                                                                                    src={avatarImage}
                                                                                    alt="Avatar"
                                                                                    className='AvatarDiv'
                                                                                ></img>
                                                                            }
                                                                            {
                                                                                <div className='CurrentUserNameDiv'>
                                                                                    {" "}
                                                                                    <b>{sessionStorage.getItem(
                                                                                        "CurrentUserName"
                                                                                    )}</b>
                                                                                    <div className='EmailDiv'>
                                                                                        {" "}
                                                                                        {sessionStorage.getItem("Email")}
                                                                                    </div>

                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        <div className="Divider1 divider" />

                                                                        <div>
                                                                            <img className='logoutimg' alt='icon' src={logOut} />
                                                                            <a href="#"
                                                                                className="logout"
                                                                                target="_blank"
                                                                                rel="noopeer noreferrer"
                                                                                onClick={this.handleLogOut}
                                                                            > Log out
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                                    <div className="Avatar" onMouseOver={this.showLoggedInUserMenu} onMouseDown={this.hideLoggedInUserMenu}>
                                        <img alt='icon' className="AvatarImage" src={avatarImage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div id="adminDashNav" className="adminDashboardNavDiv">
                        <MotifVerticalNavigation collapse={this.state.collapseMenu}>
                            <MotifVerticalNavigationContent>
                                <MotifVerticalNavigationMenu role="menu">

                                    <Link to="/adminDashboard/dashboardPlatformAdmin" role="menuitem"
                                        onClick={() => {
                                            this.highlightAdminDashboardNavigationOption();
                                        }} >
                                        <div role="menu">
                                            <MotifVerticalNavigationMenuItem className={this.state.adminDashboardClick ? "adminDashboardActiveMenu" : ""}
                                                icon={<img alt='icon' src={adminDashboard} />}
                                                label="Admin Dashboard"
                                                type="submit"
                                            >
                                                Admin Dashboard
                                            </MotifVerticalNavigationMenuItem>
                                        </div>
                                    </Link>
                                    <Link to="/adminDashboard/ConfigureSetting" role="menuitem"
                                        onClick={() => {
                                            this.highlightConfigSettingsNavigationOption();
                                        }} >
                                        <div role="menu">
                                            <MotifVerticalNavigationMenuItem className={this.state.configSettingsClick ? "adminDashboardActiveMenu" : ""}
                                                icon={<img alt='icon' src={configureSetting} />}
                                                label="Configure Settings"
                                                type="submit"
                                            >
                                                Configure Settings
                                            </MotifVerticalNavigationMenuItem>
                                        </div>
                                    </Link>
                                    <Link to="/AdminDashboard/PromptManagement/SystemPrompts" role="menuitem"
                                        onClick={() => {
                                            this.highlightPromptManagementNavigationOption();
                                        }} >
                                        <div role="menu">
                                            <MotifVerticalNavigationMenuItem className={this.state.promptManagement ? "adminDashboardActiveMenu" : ""}
                                                icon={<img alt='icon' src={promptManagement} />}
                                                label="Prompt Management"
                                                type="submit"
                                            >
                                                Prompt Management
                                            </MotifVerticalNavigationMenuItem>
                                        </div>
                                    </Link>
                                    <Link to="/adminDashboard/Notification" role="menuitem"
                                        onClick={() => {
                                            this.highlightNotificationNavigationOption();
                                        }} >
                                        <div role="menu">
                                            <MotifVerticalNavigationMenuItem className={this.state.notificationClick ? "adminDashboardActiveMenu" : ""}
                                                icon={<img alt='icon' src={notification} />}
                                                label="Notifications"
                                                type="submit"
                                            >
                                                Notifications
                                            </MotifVerticalNavigationMenuItem>
                                        </div>
                                    </Link>
                                </MotifVerticalNavigationMenu>
                            </MotifVerticalNavigationContent>

                        </MotifVerticalNavigation>
                        <div className='utilmargin'>
                            <Outlet></Outlet>
                        </div>                        
                    </div>
                        <Footer></Footer>                    
                </div>
            </div>
        )
    }
}
export default withMyHook(AdminDashboard);
import axios from "./axiosWithMsal";
import CommonService from "./commonService.js";

class PlatformService {
  commonService = new CommonService();
  constructor() {
    this.apiUrl = this.commonService.getAPIUrl();
  }

  /**
   * This method returns structured data for insights tree
   * @returns Object
   */
  getScopeTreeData = async (scopeId) => {
    try {
      return await axios
        .get(this.apiUrl + "Platform/GetScopeTreeDetails?ScopeId=" + scopeId, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
        })
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "GetScopeTreeData"
      );
    }
  };

  /**
   * This method returns instant insight active prompts list
   * @returns Object
   */
  getInstantInsightActivePrompts = async (subSectionId) => {
    try {
      return await axios
        .get(
          this.apiUrl +
            "Platform/GetPlatformPromptDetails?SubSectionId=" +
            subSectionId,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "getInstantInsightPrompts"
      );
    }
  };

  /**
   * This method returns instant insight test prompts list
   * @returns Object
   */
  getInstantInsightTestPrompts = async (subSectionId) => {
    try {
      return await axios
        .get(
          this.apiUrl +
            "Platform/GetPlatformTestPromptDetails?SubSectionId=" +
            subSectionId,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "getInstantInsightPrompts"
      );
    }
  };
  /**
   * This method delete instant insight prompts list
   * @returns sucess
   */
  DeletePrompts = async (platformPromptRequest) => {
    await axios
      .post(
        this.apiUrl + "Platform/DeletePlatformPrompts",
        platformPromptRequest,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .catch((error) => {
        this.commonService.logException(
          error,
          "Platform Component",
          "promptDelete"
        );
      });
  };

  /**
   * This method moves platform prompts to another subsection
   * @returns sucess
   */
  moveInstantInsightPrompts = async (platformPromptRequest) => {
    await axios
      .post(
        this.apiUrl + "Platform/MovePlatformPrompts",
        platformPromptRequest,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then(function (response) {
        return true;
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Platform Component",
          "moveInstantInsightPrompts"
        );
        return false;
      });
    return true;
  };

  /**
   * This method publish confirm 
   * @returns sucess
   */
ConfirmPublish = async (payload) => {
  try {
    const response = await axios.post(
      this.apiUrl + "Platform/PublishTestPrompts", 
      payload,
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    return response.status;  
  } catch (error) {
    
    this.commonService.logException(error, "Platform Component", "promptPublish");
    
  }
};

  /**
    * This method saves a new scope tree hierarchy.
    * @returns Response status code.
    */
  saveScopeTreeHierarchy = async (payload) => {
    try {
      const response = await axios.post(
        this.apiUrl + "Platform/SaveScopeHierarchy",
        payload,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.status;
    } catch (error) {
      this.commonService.logException(error, "Platform service", "Save scope tree hierarchy");
      throw error;
    }
  };

   /**
   * This method edits the prompt text.
   * @returns {Boolean} Status of edit operation.
   */
   editSystemTestPromptText = async (prompt) => {
    try {
      const response = await axios.post(
        this.apiUrl + "Platform/EditSystemPrompts",
        prompt,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.status == 200 ? true : false;
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "editSystemTestPromptText"
      );
      return false;
    }
  };

  /**
   * This method is to get the api details of the system prompts
   * @returns sucess
   */
  getSystemPromptAPIList = async (projectScopeId, type) => {
    try {
      return await axios
        .get(
          this.apiUrl +
            "Platform/GetProjectScopeAPIList?ProjectScopeId=" +
            projectScopeId +
            "&Type=" +
            type,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "getSystemPromptAPIList"
      );
    }
  };

  /**
   * This method returns system prompts
   * @returns Object
   */
  getSystemPrompts = async (category, type, scopeId) => {
    try {
      return await axios
        .get(
          this.apiUrl +
            "Platform/GetSystemPrompts?Category=" +
            category +
            "&Type=" +
            type +
            "&ScopeId=" +
            scopeId  ,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "getSystemPrompts"
      );
    }
  };

  /**
   This method is to save renames scope structure to database
   * @returns sucess
   */
  renameScopeStructure = async (structureRequest) => {
    try {
      const response = await axios.post(
        this.apiUrl + "Platform/RenameScopeStructure",
        structureRequest,
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      return response.status === 200 ? true : false;
    } catch (error) {
      this.commonService.logException(
        error,
        "Platform Component",
        "renameScopeStructure"
      );
      return false;
    }
  };

}
export default PlatformService;

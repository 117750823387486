import { configureStore } from "@reduxjs/toolkit";
import instantInsightReducer from "./reducers/instantInsightSlice";
import promptManagementReducer from "./reducers/promptManagementSlice";

const store = configureStore({
  reducer: {
    instantInsight: instantInsightReducer,
    promptManagement: promptManagementReducer
  },
});
export default store;

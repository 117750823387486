import React from 'react';
import { MotifButton, MotifFooter, MotifModal, MotifModalBody, MotifModalHeader } from "@ey-xd/motif-react";
import './PublishPrompt.css';

export default function PublishPrompt({ show, onHide, scopeName, category, PromptConfirmClick }) {
    return (
        <MotifModal
            id="PublishPrompmt"
            show={show}
            size="md"
            focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: "none",
                },
            }}
            onClose={onHide}
        >
            <MotifModalHeader
                closeButtonProps={{
                    "aria-label": "Close",
                    title: "Close",
                }}
            >
                Publish Prompt Category
            </MotifModalHeader>

            <MotifModalBody>
                <div className="project-scope-field-txt">Publish prompts for selected Project Scope and Category. Click ‘Confirm’ to apply.</div>
                <div className="scopeSpacing">
                    <div className="title">Project Scope</div>
                    <div className="info">{scopeName}</div>
                </div>
                <div className="scopeSpacing">
                    <div className="title">Prompt Category</div>
                    <div className="info">{category}</div>
                </div>
            </MotifModalBody>

            <MotifFooter className="footer">
                <div className="footer-btns-div">
                    <div>
                        <MotifButton
                            variant="secondary"
                            onClick={onHide}
                        >
                            Cancel
                        </MotifButton>
                    </div>
                    <div>
                        <MotifButton
                            className="confirm-btn"
                            onClick={PromptConfirmClick}
                        >
                            Confirm
                        </MotifButton>
                    </div>
                </div>
            </MotifFooter>
        </MotifModal>
    )
}

import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import MotifAccordion, {
  MotifAccordionTrigger,
  MotifAccordionContent,
} from "@ey-xd/motif-react/Accordion";
import MotifCheckbox from "@ey-xd/motif-react/Checkbox";
import { MotifIconButton } from "@ey-xd/motif-react/components/button";
import { MotifChip } from "@ey-xd/motif-react";
import editPencil from "../../../../assets/image/edit.svg";
import { DashboardContext } from "../../../../context/dashboardContext";

const PromptAccordion = (props) => {
  const { scopeAll } = useContext(DashboardContext);
  const { selectedScopeId } = useSelector((state) => state.promptManagement);
  const [open, setOpen] = useState(false);
  const [isEditIconVisible, setIsEditIconVisible] = useState(props.tab === "SystemPrompts" &&
    props.type === "testPrompt");
  const [currentSelectedScope, setCurrentSelectedScope] = useState(null);
  const [speydStatusCheck, setSpeydStatusCheck] = useState(null);
  const [spiiStatusCheck, setSpiiStatusCheck] = useState(null);

  useEffect(() => {
    const currentSelectedScope = scopeAll.filter((scope) => {
      return scope.scopeId === selectedScopeId;
    })
    setCurrentSelectedScope(currentSelectedScope[0]);
    setSpeydStatusCheck(currentSelectedScope[0]?.spEydStatus)
    setSpiiStatusCheck(currentSelectedScope[0]?.spiiStatus)
  }, [scopeAll, selectedScopeId, props.node])

  /**
   * This method is a click operation of the accordion click
   * @param {*} event
   */
  const onClickAccordion = (event) => {
    if (event.target.className === "motif-checkbox-custom" && (props?.prompt?.isEditFlag || props.tab === "InstantInsightPrompts")) {
      props.onCheckPrompt(props?.prompt?.platformPromptId, true);
    } else if (
      event.target.parentNode.className === "motif-checkbox-checked-icon" && (props?.prompt?.isEditFlag || props.tab === "InstantInsightPrompts")
    ) {
      props.onCheckPrompt(props?.prompt?.platformPromptId, false);
    } else if (event.target.nodeName === "svg" || event.target.nodeName === "path") {
      setOpen(!open);
    } else if (
      event.target.nodeName === "svg" ||
      (event.target.className?.trim() !== "ii-prompt-detail-text" &&
        event.target.className?.trim() !== "motif-accordion-content")
    ) {
      setOpen(!open);
    }
  };

  return (
    <MotifAccordion
      open={open}
      alignIconRight={true}
      useChevronIcon={true}
      onClose={() => { }}
      onOpen={() => { }}
      onClick={($event) => onClickAccordion($event)}
      className={
        props?.prompt?.isChecked
          ? "ii-prompt-selected-accordion"
          : "ii-prompt-accordion"
      }
      data-testid="accordionPromptTitle"
    >
      <MotifAccordionTrigger>
        <div className="accordion-title">
          <div className="ii-response-accordion-trigger">
            {(() => {
              if (
                props.tab === "SystemPrompts" &&
                props.type === "activePrompt"
              ) {
                return "";
              } else {
                return (
                  props.tab === "SystemPrompts" && props.type === "testPrompt" && 
                  ((props.node?.api === "Explore your data" && speydStatusCheck === 4)
                  || (props.node?.api === "Instant Insight" && spiiStatusCheck === 4)) && (
                    <MotifCheckbox
                      name="chkPrompt"
                      checked={props?.prompt?.isChecked}
                      value={props?.prompt?.isChecked}
                      disabled={!props?.prompt?.isEditFlag}
                    ></MotifCheckbox>
                  )
                );
              }
            })()}
            {
              props?.prompt?.platformPromptTitle
            }
            {
              props?.prompt?.isEditFlag &&
              (
                <MotifChip
                  onClick={null}
                  title="Edited"
                  className="edited-chip"
                  style={{ "display": isEditIconVisible ? "block" : "none" }}
                >
                  Edited
                </MotifChip>
              )
            }
          </div>
          <div>
            <MotifIconButton onClick={props.onEditIconClick} style={{ "display": isEditIconVisible ? "block" : "none" }}>
              <img alt='icon' src={editPencil} />
            </MotifIconButton>
          </div>
        </div>
      </MotifAccordionTrigger>
      <MotifAccordionContent>
        <div className="ii-prompt-detail-text">
          {props?.prompt?.platformPromptText != null ? props?.prompt?.platformPromptText : "No prompt found"}
        </div>
      </MotifAccordionContent>
    </MotifAccordion>
  );
};

export default PromptAccordion;

import { createSlice } from "@reduxjs/toolkit";

const promptManagementSlice = createSlice({
    name: "promptManagement",
    initialState: {
        selectedScopeId: null,
    },
    reducers: {
        setSelectedScopeId: (state, action) => {
            state.selectedScopeId = action.payload.selectedScopeId;
        },
    },
});

export const { setSelectedScopeId } = promptManagementSlice.actions;

export default promptManagementSlice.reducer;

import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import DeepLink from './lib/deepLinks';
import DeepLinkService from './services/deepLinkService';
import { useMsal } from '@azure/msal-react';

const DeepLinkManager = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const deepLinkService = new DeepLinkService();
  const deepLink = DeepLink();

  useEffect(() => {
    // Check if the user is authenticated using the msal instance
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      const link = deepLink.getDeepLink();
      if (link) {
        const project_UUID = deepLink.getDeepLinkDataRequirements()["project_UUID"];
        const dataHostingLocation = deepLink.getDeepLinkDataRequirements()["dataHostingLocation"];
        if (project_UUID) {
          deepLinkService.GetProjectDetailById(project_UUID, dataHostingLocation)
            .then((data) => {
              if (data.length > 0) {
                const datareturn = data[0];
                sessionStorage.setItem("DashboardProjectName", datareturn.projectName);
                sessionStorage.setItem("DashboardProjectId", project_UUID);
                // Navigate to the stored deep link
                navigate(link, { replace: true });
                deepLink.remove(); // Clean up after navigation
              }
              else {
                console.error("No project data found for the given UUID.");
              }
            })
            .catch((error) => {
              console.error('Failed to fetch project details:', error);
            })
            .finally(() => {
              setIsInitialized(true); // Mark as initialized whether success or failure
            })
        }
        else {
          setIsInitialized(true); // Mark as initialized if project_UUID is not found
        }
      }
      else {
        setIsInitialized(true); // Mark as initialized if no deep link is found
      }
    }
    else {
      setIsInitialized(true); // Mark as initialized if user is not authenticated
    }


  }, [navigate, instance, deepLinkService]);

  if (!isInitialized) {
    // Render nothing (or a loading spinner) until deep link is processed
    return <div>Loading...</div>;
  }

  return <Outlet />;
};

export default DeepLinkManager;
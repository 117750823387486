import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardContext } from "../../context/dashboardContext";
import { paansConfig } from "../../config/paansConfig";
import ProjectList from "./projectlist";
import { MotifButton, MotifFormField, MotifSearch } from "@ey-xd/motif-react";
import { groupConfig } from "../../config/groupConfig.js";
import styles from "./dashboard.module.scss";
import _ from "lodash";
import plus from "../../assets/image/plus.svg";
import search from "../../assets/image/search.svg";
import bannerImage1 from "../../assets/image/bannerimage.png";
import gridMinus from "../../assets/image/grid-minus.svg";
import sessiomanager from "../../utils/sessionmanager.js";
import CreateProjectPopup from "./CreateProjectPopup.js";
import Footer from "../common/Footer.js";
import Header from "../common/Header";
import EngagementLocationService from "../../services/engagementLocationService";

export function Dashboard(props) {
  const navigate = useNavigate();
  const [isSATUser, setSATUser] = useState(false);
  const [isPlatFormUser, setPlatFormUser] = useState(false);
  const [isPaansAccepted, setPaansAccepted] = useState(false);
  const [isProjectAvailable, setIsProjectAvailable] = useState(false);
  let [productList, setProductList] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [isProjectSearch, setIsProjectSearch] = useState(false);
  let { paans } = useContext(DashboardContext);
  let { projects } = useContext(DashboardContext);
  let [recentProject, setRecentProject] = useState({});
  const [showCreateProjectPopup, setShowCreateProjectPopup] = useState(false);
  const constantData = require("../../data/constant.js");
  const [position, setPosition] = useState(constantData.StyleType);
  const engagementLocationService = new EngagementLocationService();
  const [showEngagementLocationPopup, setShowEngagementLocationPopup] = useState(false);
  const [locationList, setLocationList] = useState([]);



  /**
   * Description
   * -----------
   * This useEffect is used to show the dashboard as per the user role.
   */
  useEffect(() => {
    setSATUser(localStorage.getItem("groupName") === groupConfig.groupNameSat);
    setPlatFormUser(
      localStorage.getItem("groupNamePlatform") ===
      groupConfig.groupNamePlatForm
    );
    if (Object.keys(paans).length && !paans.hasAccepted) {
      const urlPaans = paansConfig.PAANSEndpoint + paans.redirectUrl;
      window.location.href = urlPaans;
    } else if (Object.keys(paans).length && paans.hasAccepted) {
      setPaansAccepted(true);
      if (projects.length > 0) {
        setIsProjectAvailable(true);
        setRecentProject(projects[0]);
        setProductList(projects.slice(1));
      }
      else{
        setIsProjectAvailable(false);
        setRecentProject(null);
        setProductList(null);
      }
    }
    if (!localStorage.getItem("EngagementLocation")) {
      setShowEngagementLocationPopup(true);
    }
  }, [paans, projects]);

  /**
   * Description
   * -----------
   * This useEffect is used to set the default dashboard.
   */
  useEffect(() => {
    sessiomanager.setDashboardDefault();
    engagementLocationService.getEngagementLocationData().then((data) => {
      setLocationList(data);
    });
  }, []);

  /**
   * Description
   * -----------
   * This useEffect is to set the position of the document.
   */
  useEffect(() => {
    document.body.style.overflow = position;
  }, [position]);

  /**
   * Description
   * -----------
   * This method is to show disclaimer popup
   */
  const navigateCreateProject = () => {
    showPopUp(true);
  };

  /**
   * Description
   * -----------
   * This method is to filter the projects from the search input.
   * Parameters:
   * e: this one is onChange event parameter to get the input text
   */
  const filterProjects = (e) => {
    setSearchValue(e.target.value);
    let filterProjects1 = _.filter(projects, function (item) {
      return item.projectName
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    if (e.target.value !== "") {
      setRecentProject(null);
      setProductList(filterProjects1);
      setIsProjectSearch(true);
    } else {
      setRecentProject(filterProjects1[0]);
      setProductList(filterProjects1.slice(1));
      setIsProjectSearch(false);
    }
  };

  /**
   * Description
   * -----------
   * This method is to control body header style
   */
  const showPopUp = (isvisible) => {
    setShowCreateProjectPopup(isvisible);
    if (!isvisible) {
      setPosition("");
    } else {
      setPosition(constantData.StyleType);
    }
  };  
  /**
   * Description
   * -----------
   * This method is to navigate to user to create project page
   */
  const onAcknowledgementClick = () => {
    showPopUp(false);
    if (position !== "") {
      document.body.style.overflow = "";
      navigate("/createproject");
    }
  };
  /**
   * Description
   * -----------
   * This method is to control body header style
   */
  const showLocationPopUp = (isvisible) => {
    setShowEngagementLocationPopup(isvisible);
    if (!isvisible) {
      setPosition("");
    } else {
      setPosition(constantData.StyleType);
    }
  };
  /**
  * Description
  * -----------
  * This method is to confirm Engagement Location Popup 
  */
  const onConfirmLocationClick = (selectedLocation) => {
    if(selectedLocation!==undefined)
    {
      showLocationPopUp(false);
      if (position !== "") {
        document.body.style.overflow = "";
      }
      saveEngagementLocation(selectedLocation);
    }
  };
 /**
  * Description
  * -----------
  * This method is to  save the location details into localstorage
  */
  const saveEngagementLocation = (selectedLocation) => {
    if(selectedLocation!=="")
    {
    localStorage.setItem('EngagementLocation',
      JSON.stringify(locationList.filter(item => item.content === selectedLocation)[0]));
      window.location.reload();
    }
  }
  /**
   * Description
   * -----------
   * This method is to navigate to user to platform admin dashboard
   */
  const navigateToPaltformAdmin = () => {
    navigate("/adminDashboard/dashboardPlatformAdmin");
  };

  return (
    isPaansAccepted && (
      <div className={styles.container}>
        <Header />
        <div className={styles.dashboard}>
          <div className={styles.pageHeader}>
            <div className={styles.headerContent}>
              <div className={styles.headerTitle}>
                <div className={styles.headerText}>
                  <div className={styles.headerTextDiv}>
                    <div className={styles.headerValue}>Dashboard</div>
                  </div>
                </div>
              </div>
              <div className={styles.headerAction}>
                {isPlatFormUser && (
                  <MotifButton
                    label="btnCreate"
                    className={styles.switchToProjectDashboard}
                    onClick={navigateToPaltformAdmin}
                  >
                    <img src={gridMinus} alt=""></img>
                    <span className={styles.txtSwitchProjDash}>
                      Switch to Admin Dashboard
                    </span>
                  </MotifButton>
                )}
                {isProjectAvailable && isSATUser && (
                  <MotifButton
                    label="btnCreate"
                    className={styles.createProject}
                    onClick={navigateCreateProject}
                  >
                    <img src={plus} alt=""></img>
                    Create Project
                  </MotifButton>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          {showCreateProjectPopup && (
            <CreateProjectPopup
              setOpenModal={showPopUp}
              setAcknow={onAcknowledgementClick}
            ></CreateProjectPopup>
          )}
        </div>
        <div className={styles.dashboard}>
          <div className={styles.bannerDiv}>
            <div className={styles.bannerImage}>
              <img src={bannerImage1} draggable="false" alt=""></img>
            </div>
            <div className={styles.HeadingAndSubheading}>
              <div className={styles.Subheading}>Getting started</div>
              <div className={styles.HeadingAndText}>
                <div className={styles.Heading}>
                  Hey, here is an overview of your project engagements
                </div>
                <div className={styles.SupportingText}>
                  Learn all about the projects available to you.{" "}
                </div>
              </div>
            </div>
          </div>

          {projects?.length > 0 && (
            <div className={styles.searchDiv}>
              <div className={styles.recentLabel}>Recent</div>
              <MotifFormField>
                <img className={styles.searchIcon} src={search} alt="" />
                <MotifSearch
                  className={styles.searchTxtBox}
                  aria-label="Search"
                  hideClearButton
                  id="states-search2"
                  placeholder="Search project"
                  value={searchValue}
                  onChange={filterProjects}
                />
              </MotifFormField>
            </div>
          )}
        </div>

        <ProjectList
          projects={productList ? productList : projects}
          recentProject={recentProject}
          isSATUser={isSATUser}
          isProjectSearch={isProjectSearch}
          onCreateNewProject={navigateCreateProject}
          setSearchValue={setSearchValue}
        />
        {/* </div>  */}
        <Footer></Footer>
      </div>
    )
  );
}

export default Dashboard;

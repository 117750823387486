export const groupConfig = {
  groupName: process.env.REACT_APP_GROUPNAME,
  graphApiUrl: process.env.REACT_APP_GRAPH_API_URL,
  groupNameDaisEy: process.env.REACT_APP_GROUPNAME_DAISEY,
  groupNameNonDaisEy: process.env.REACT_APP_NONGROUPNAME_DAISEY,
  groupNamePlatformAdmin: process.env.REACT_APP_GROUPNAME_PLATFORM_ADMIN,
  groupNameNonSat: process.env.REACT_APP_GROUPNAME_NONSAT,
  groupNameSat: process.env.REACT_APP_GROUPNAME_SAT,
  groupNamePlatForm: process.env.REACT_APP_GROUPNAME_PLATFORM,
};

export const ReportAuthorConfig = {
  documentUploadSizeLimitInMB: 1000,
  documentUploadCountLimitInCount: 5,
  secretKey: process.env.REACT_APP_SECRET_KEY
};

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import "../ExploreData/MarkdownStream.css";

function MarkdownWithoutStream(props) {
    const [text, setText] = useState('');
    const markdownRef = useRef();

    useEffect(() => {
        setText(props.markdownText);
    }, [props.markdownText]);

    useEffect(() => {
        props.setMarkdownResponseHTMLString(markdownRef.current?.outerHTML);
    })

    return (
        <>
            {text && text.length > 0 && (
                <p ref={markdownRef}>
                    <ReactMarkdown className="markdown-table" remarkPlugins={[remarkGfm]}>
                        {text}
                    </ReactMarkdown>
                </p>
            )}
        </>
    );
  }
  
MarkdownWithoutStream.propTypes = {
    markdownText: PropTypes.string.isRequired,
    setMarkdownResponseHTMLString: PropTypes.func
};

export default MarkdownWithoutStream;
import React, { useEffect, useState, useRef } from "react";
import MotifModal, { MotifModalHeader, MotifModalBody } from '@ey-xd/motif-react/Modal';
import { MotifInput, MotifModalFooter, MotifRichTextEditor, MotifErrorMessage, MotifFormField, MotifInlineMessage } from "@ey-xd/motif-react";
import MotifButton from '@ey-xd/motif-react/Button';
import information from "../../../assets/image/information.svg";
import Utils from '../../../utils/Utils';
import './BadgePopUp.css';

const prompt = require("../../../data/model/prompt.js");
export function BadgePopup({ setOpenModal, promptdata = prompt.PromptData, setconfirm }) {
    const [open, setOpen] = useState(true);
    const [prompt, setPrompt] = useState(promptdata.prompttext);
    const [title, setTitle] = useState("");
    const [isMaxChar, setIsMaxChar] = useState(false);
    const [isSpecChar, setIsSpecChar] = useState(false);
    const [isValidTitle, setIsValidTitle] = useState(false);
    const [tag] = useState("");
    const utils = new Utils();
    const regex = /[`!@#$%^*+=\]{};':"\|,<>?~]/;
    const promptRef = useRef(null);

    const motifReactEditorModules = {
        toolbar: [[{
          list: 'ordered'
        }, {
          list: 'bullet'
        }, 
      ]],
        clipboard: {
          matchVisual: false
        }
      };

    useEffect(() => {
        const checkModalLoaded = () => {
            const promptEditor = promptRef.current;
            if (promptEditor) {
                const numberedBtn = promptEditor.querySelector(
                    `button[value="ordered"]`
                );
                const bulletBtn = promptEditor.querySelector(`button[value="bullet"]`);
                if (numberedBtn) {
                    utils.addCustomTooltip(numberedBtn, 'Numbered list', 'modalTooltipNumberedList', 'bottom');
                }
                if (bulletBtn) {
                    utils.addCustomTooltip(bulletBtn, 'Bulleted list', 'modalTooltipBulletedList', 'bottom');
                }
            } else {
                // Retry after a short delay if the modal is not yet loaded
                setTimeout(checkModalLoaded, 10);
            }
        };
        checkModalLoaded();

        return () => {
            let tooltipNumberedList = document.getElementById('modalTooltipNumberedList');
            if (tooltipNumberedList) {
                document.body.removeChild(tooltipNumberedList);
            }
            let tooltipBulletedList = document.getElementById('modalTooltipBulletedList');
            if (tooltipBulletedList) {
                document.body.removeChild(tooltipBulletedList);
            }
        };
    }, []);
    
    /**
   * Description
   * -----------
   * This method is to close popup once the user clicks cross button or cancel
   */
    const onCloseClick = () => {
        setOpen(false);
        setOpenModal(false);
    }
    /**
  * Description
  * -----------
  * This method is to get the prompt that user has given in popup
  * Parameters:
  * e: this one is onChange event parameter to get the input title
  */
    const onPromptChange = (value) => {
        setPrompt(value);
    };
    /**
    /**
   * Description
   * -----------
   * This method is to get the title that user has given in popup
   * Parameters:
   * e: this one is onChange event parameter to get the input title
   */
    const onTitleChange = (e) => {
        if (e.target.value.length > 0) {
            if (e.target.value.length <= 50) {
                setIsValidTitle(true);
                setIsMaxChar(false);
                setTitle(e.target.value);
                if (regex.test(e.target.value)) {
                    setIsValidTitle(false);
                    setIsSpecChar(true);
                }
                else {
                    setIsValidTitle(true);
                    setIsSpecChar(false);
                }
            }
            else {
                setIsMaxChar(true);
            }
        }
        else {
            setIsValidTitle(false);
            setTitle(e.target.value);
            setIsMaxChar(false);
            setIsSpecChar(false);
        }
    };   


    /**
     * This method removes the max character message 
     */
    const handleBlur = () => {
        setIsMaxChar(false);
    }
    /**
   * Description
   * -----------
   * This method is to close popup and insert data into database once the user clicks confirm button
   */
    const onConfirmClick = () => {
        promptdata.prompttext = prompt;
        promptdata.title = title;
        promptdata.tags = tag;
        setconfirm(promptdata);
        setOpen(false);
        setOpenModal(false);
    }

    /**
     * This method checks whether button should be enable or not.
     * @returns boolean
     */
    const confirmDisableCheck = () => {
        let promptTextVal = prompt;
        promptTextVal = promptTextVal?.replace(/<[^>]+>/g, '').trim();
        return promptTextVal === '' || !isValidTitle;
    }


    return (

        <div >
            <MotifModal id="badgepopupmodal" show={open} size="xl" focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: 'legacy-full'
                }
            }}
                onClose={() => { onCloseClick() }}
                className="genericModal modalHeaderVerticalPadding modalMotifFooterModal"
            >
                <MotifModalHeader
                    closeButtonProps={{
                        'aria-label': 'Close',
                        title: 'Close'
                    }}
                    className="add-prompt-modal-header"
                >
                    Add to Personal Prompt Library
                </MotifModalHeader>
                <MotifModalBody>
                    <React.Fragment>
                        <div id="divspace">
                            <span >
                                Title
                            </span>
                        </div>
                        <div id="divspace">
                            <MotifFormField>
                            <MotifInput
                                    label="inputtitle"
                                    onChange={onTitleChange}
                                    type='text'
                                    placeholder='Enter a title'
                                    value={title}
                                    onBlur={handleBlur}
                                />
                            {isSpecChar &&
                                    <MotifErrorMessage className="spcl-char-error-message">
                                        Special characters not allowed
                                    </MotifErrorMessage>
                                }
                                {!isSpecChar && isMaxChar &&
                                    <MotifInlineMessage className="max-char-reached-messaage">
                                        Maximum limit reached - 50 characters
                                    </MotifInlineMessage>
                                }
                            </MotifFormField>
                                
                        </div>
                        <div id="divspace" className="prompt-divspace">
                            <span >
                                Prompt
                            </span>
                        </div>
                        <div id="divspace" ref={promptRef}>
                            <MotifRichTextEditor 
                                id="prompt-editor-library"
                                placeholder="Enter a prompt" 
                                value={prompt}
                                modules = {motifReactEditorModules}
                                onChange={onPromptChange}
                            />
                        </div>       
                        <div className="aligned">
                            <img src={information} className='info-icon' alt='icon'/>
                            <span className='info'>Please ensure client information is not included in the prompt</span>
                        </div>
                    </React.Fragment>
                </MotifModalBody>
                <MotifModalFooter>
                    <React.Fragment >
                        <div className="BadgeFooterButtonContent" >
                            <div class="EditProjectEditTitle" >
                                <div class="EditProjectEditTextAndSupportingText" >
                                    <div class="EditProjectProjectHeaderText" >
                                    </div>
                                </div>
                            </div>
                            <div class="EditProjectEditActions" >
                                <div class="EditProjectEditCancelButton" >
                                    <div class="EditProjectEditCancelButtonBase" >
                                        <div class="EditProjectEditCancelText" >
                                            <MotifButton
                                                className=""
                                                onClick={() => { onCloseClick() }}
                                                variant="secondary"
                                            >
                                                Cancel
                                            </MotifButton>
                                        </div>
                                    </div>
                                </div>

                                <div class="EditProjectEditSaveButton" >
                                    <div class="EditProjectEditSaveButtonBase" >
                                        <div >
                                            <MotifButton
                                                onClick={() => { onConfirmClick() }}
                                                disabled={confirmDisableCheck()}
                                            >
                                                Confirm

                                            </MotifButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>

                </MotifModalFooter>

            </MotifModal>
        </div >
    )
}

export default BadgePopup;
import axios from "../services/axiosWithMsal";
import CommonService from "./commonService.js";
class DocumentService {
  commonService = new CommonService();
  constructor() {
    this.apiUrl = this.commonService.getAPIUrl();
  }

  async getDocuments(projectId) {
    const userDocumentRequest = {
      Project_UUID: projectId,
    };

    const response = await axios
      .get(this.apiUrl + "Document/GetDocumentDetails", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        params: userDocumentRequest,
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Upload Document",
          "Get Documents"
        );
      });

    return response?.data;
  }
  async getDocumentSettings() {
    const response = await axios
      .get(this.apiUrl + "Project/GetDocumentSetting", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Upload Document",
          "Get Document Setting"
        );
      });
    return response.data;
  }
  async UploadDocumentToSharePoint(sharepointDocuments) {
    await axios
      .post(this.apiUrl + "Document/SPUploadDocument", sharepointDocuments, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        this.commonService.logException(
          error,
          "Upload Document",
          "SharePoint Upload Document",
          sharepointDocuments
        );
      });
  }

  async uploadLocalDocuments(files) {
    const formData = new FormData();
    for (const f of files) {
      formData.append(`files`, f);
    }
    formData.append(
      "P_Blob_Container_Name",
      sessionStorage.getItem("DashboardProjectId")
    );
    formData.append(
      "P_UploadUserName",
      sessionStorage.getItem("CurrentUserName")
    );
    const response = await axios
      .post(this.apiUrl + "Document/LocalUploadDocument", formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Upload Document",
          "Upload Local Document"
        );
        return {
          status: 500,
        };
      });

    return response;
  }

  async uploadLocalDocumentsV2(files) {
    const formData = new FormData();
    files.forEach((doc, index) => {
      formData.append(`files[${index}].File`, doc.file);
      formData.append(`files[${index}].Password`, doc.password ? doc.password : "");
    });
    formData.append(
      "P_Blob_Container_Name",
      sessionStorage.getItem("DashboardProjectId")
    );
    formData.append(
      "P_UploadUserName",
      sessionStorage.getItem("CurrentUserName")
    );
    const response = await axios
      .post(this.apiUrl + "Document/LocalUploadDocument", formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Upload Document",
          "Upload Local Document"
        );
        return {
          status: 500,
        };
      });

    return response;
  }

  async deleteDocument(projectId, documentUUID, documentName) {
    const deleteDocumentRequest = {
      project_UUID: projectId,
      document_UUID: documentUUID,
      documentName: documentName,
    };

    const response = await axios
      .post(this.apiUrl + "Document/DeleteDocument", deleteDocumentRequest, {
        headers : {
          "Content-type": "application/json; charset=UTF-8",
        }
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Upload Document",
          "Delete Document"
        );
      });

    return response.data;
  }

  /**
   * This api will return the current file upload progress status based on it we will continue polling to show upload message
   * @param {number} projectId 
   * @returns 
   */
  async getDocumentUploadProcessDetail(projectId) {
    const userDocumentRequest = {
      Project_UUID: projectId,
      Owner: sessionStorage.getItem("CurrentUserName")
    };

    const response = await axios
      .get(this.apiUrl + "Document/GetFileUploadProgress", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        params: userDocumentRequest,
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Upload Document",
          "Get File Upload Progress"
        );
      });

    return response?.data;
  }

  /**
   * This api will delete the record from DocumentUploadProgress table
   * @param {number} projectId 
   * @returns 
   */
  async deleteFileUploadProgress(projectId) {
    const deleteDocumentRequest = {
      project_UUID: projectId,
      Owner: sessionStorage.getItem("CurrentUserName")
    };

    const response = await axios
      .post(this.apiUrl + "Document/DeleteFileUploadProgress", {}, {
        headers : {
          "Content-type": "application/json; charset=UTF-8",
        },
        params: deleteDocumentRequest
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Upload Document",
          "Delete File Upload Progress"
        );
      });

    return response.data;
  }
}

export default DocumentService;

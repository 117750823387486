import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { groupConfig } from "../../config/groupConfig";
import DeepLink from "../../lib/deepLinks";
export function LoginSuccess() {

  const navigate = useNavigate();

  useEffect(() => {
    const deepLink = DeepLink().getDeepLink();
    if (deepLink) {
      navigate(deepLink);
    }
    else {

      const grpName = localStorage.getItem('groupNamePlatform');

      if (grpName === groupConfig.groupNamePlatForm) {
        navigate("/adminDashboard/dashboardPlatformAdmin");
      }
      else {
        navigate("/dashboard");
      }
  }

  }, []);

  return <div>redirecting to dashboard</div>;
}

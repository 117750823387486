import React, { useState } from "react";
import PromptTabDetail from "../Common/PromptTabDetail";
import ManageStructure from "./ManageStructure";

const InstantInsightPrompts = () => {
  const [displayAddScopeSection, setDisplayAddScopeSection] = useState(false);

  const updateAddScopeSectionVisibility = (value) => {
    setDisplayAddScopeSection(value);
  }
  return (
    <>
      {
        !displayAddScopeSection ?
          <PromptTabDetail tab={"InstantInsightPrompts"} updateAddScopeSectionVisibility={updateAddScopeSectionVisibility} />
          :
          <ManageStructure updateAddScopeSectionVisibility={updateAddScopeSectionVisibility} />
      }
    </>
  )
};
export default InstantInsightPrompts;

import MotifModal, { MotifModalHeader, MotifModalBody } from '@ey-xd/motif-react/Modal';
import { MotifInput, MotifModalFooter, MotifFormField, MotifErrorMessage } from "@ey-xd/motif-react";
import MotifButton from '@ey-xd/motif-react/Button';
import React, { useState, useEffect } from "react";
import help from "../../../assets/image/help-circle.svg";
import { MotifChipGroup, MotifTooltip } from "@ey-xd/motif-react";
import MotifChip from '@ey-xd/motif-react/Chip';
const prompt = require("../../../data/model/prompt.js");



export function FavouritePopUp({ setOpenModal, promptdata = prompt.PromptData, setconfirm, isExplore }) {
    const [open, setOpen] = useState(true);
    const [prompt] = useState(promptdata.prompttext);
    const [title, setTitle] = useState(promptdata.title);
    const [tag, setTag] = useState("");
    const [isMaxChar, setIsMaxChar] = useState(false);
    const [isSpecChar, setIsSpecChar] = useState(false);
    const regex = /[`!@#$%^*+=\]{};':"\|,<>?~]/;
    const [tagList, setTagList] = useState([]);
    const [isTitleGiven, setIsTitleGiven] = useState(false);
    /**
    * Description
    * -----------
    * This method is to close popup once the user clicks cross button or cancel
    */
    const onCloseClick = () => {
        setOpen(false);
        setOpenModal(false);
    }

    useEffect(() => {
        if (!isExplore) {
            const promptTagList = promptdata.tags.split(',').filter(item => item);
            if (promptTagList.length > 0) {
                setTagList(tagList => [...tagList, ...promptTagList])
            }
            if (promptdata.title !== "") { setIsTitleGiven(true); }
        }
    }, [isExplore, promptdata]);
    /**
     * Description
     * -----------
     * This method is to get the title that user has given in popup
     * Parameters:
     * e: this one is onChange event parameter to get the input title
     */
    const onTitleChange = (e) => {
        if (e.target.value.length > 0) {
            setIsTitleGiven(true);
            if (e.target.value.length <= 30) {
                setIsMaxChar(false);
                if (regex.test(e.target.value)) {
                    setIsSpecChar(true);
                }
                else {
                    setIsSpecChar(false);
                    setTitle(e.target.value);
                }
            }
            else {
                setIsMaxChar(true);
                setIsTitleGiven(false);
            }
        }
        else {
            setIsTitleGiven(false);
            setTitle(e.target.value);
            setIsMaxChar(false);
        }
    };
    /**
    * Description
    * -----------
    * This method is to get the title that user has given in popup
    * Parameters:
    * e: this one is onChange event parameter to get the input title
    */
    const onTagChange = (e) => {
        setTag(e.target.value);
    };
    /**
   * Description
   * -----------
   * This method is to get the title that user has given in popup
   * Parameters:
   * e: this one is onChange event parameter to get the input title
   */
    const onTagKeyPress = () => {
        setTagList([...tagList, tag])
        setTag("");
    };
    /**
     * Description
     * -----------
     * This method is to close popup and insert data into database once the user clicks confirm button
     */
    const onTagRemove = (tags) => () => {
        var index = tagList.indexOf(tags.item)
        if (index !== -1) {
            tagList.splice(index, 1);
            setTagList(tagList);
        }
    };
    /**
     * Description
     * -----------
     * This method is to close popup and insert data into database once the user clicks confirm button
     */
    const onConfirmClick = () => {
        promptdata.title = title;
        promptdata.tags = tagList.join();
        setconfirm(promptdata);
        setOpen(false);
        setOpenModal(false);
    }
    return (

        <div className='favcontent'>
            <MotifModal id="favouritepopupmodal" size="lg" show={open} focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: 'none'
                }

            }}
                onClose={() => { onCloseClick() }}
            >
                <MotifModalHeader
                    closeButtonProps={{
                        'aria-label': 'Custom Close Button aria-label',
                        title: 'Custom Close Button title'
                    }}
                >
                    {isExplore && (
                        "Add to Favorites"
                    )}
                    {!isExplore && (
                        "Edit title  and tags"
                    )}
                </MotifModalHeader>
                <MotifModalBody>
                    <React.Fragment>
                        <div id="favtitledivspace">
                            <span >
                                Title (required)
                            </span>
                        </div>
                        <div id="favtitledet">
                            <MotifFormField>
                                <MotifInput
                                    label="inputtitle"
                                    onChange={onTitleChange}
                                    type='text'
                                    value={title}
                                    placeholder='Add a title'
                                    disabled={promptdata.prompttype==="II" ? true : false}
                                    hideClearButton={true}
                                    className='inputtitle'
                                />
                               
                                <MotifTooltip
                                    placement="left"
                                    contentClassName="toolhelp"
                                    trigger={
                                        <img className="favorite-rp-help-icon" src={help} alt='icon' />
                                    }
                                    closeButtonProps={{ style: { display: "none" } }}
                                >
                                    {promptdata.prompttype==="II" ? "The title for Instant Insights favorites is non-editable.":  (<>Please enter a name in alphabets, <br />special characters are not allowed.</>)}
                                   
                                </MotifTooltip>
                            </MotifFormField>
                            {isSpecChar &&
                                    <MotifErrorMessage>
                                        Special characters not allowed
                                    </MotifErrorMessage>
                                }
                                {isMaxChar &&
                                    <MotifErrorMessage>
                                        Maximum limit reached - 30 characters
                                    </MotifErrorMessage>
                                }
                        </div>
                        <div >
                            <span >
                                Tag
                            </span>
                        </div>
                        <div id="divspace">
                            <span >
                                <MotifInput
                                    label="inputtitle"
                                    onChange={onTagChange}
                                    type='text'
                                    value={tag}
                                    placeholder='Add a tag'
                                    onKeyPress={(e) => { if (e.key === 'Enter') { onTagKeyPress() } }}
                                    hideClearButton={true}
                                    className='inputtitle'
                                />
                            </span>
                        </div>
                        <div id="divspace">
                            <span >
                                Press 'enter' to confirm
                            </span>
                        </div>
                        <div id="divspace">
                            <MotifChipGroup
                                displayName="group"
                                type="chip"
                            >

                                {tagList.map(item => {
                                    return (

                                        <MotifChip
                                            displayName="group"
                                            removable={true}
                                            onRemove={onTagRemove({ item })}
                                        >
                                            {item}
                                        </MotifChip>
                                    );
                                })}

                            </MotifChipGroup>
                        </div>
                    </React.Fragment>
                </MotifModalBody>
                <MotifModalFooter>
                    <React.Fragment >
                        <div className="BadgeFooterButtonContent" >
                            <div class="EditProjectEditTitle" >
                                <div class="EditProjectEditTextAndSupportingText" >
                                    <div class="EditProjectProjectHeaderText" >
                                    </div>
                                </div>
                            </div>
                            <div class="EditProjectEditActions" >
                                <div class="EditProjectEditCancelButton" >
                                    <div class="EditProjectEditCancelButtonBase" >
                                        <div class="EditProjectEditCancelText" >
                                            <MotifButton
                                                variant="secondary"
                                                onClick={() => { onCloseClick() }}
                                            >
                                                Cancel
                                            </MotifButton>
                                        </div>
                                    </div>
                                </div>

                                <div class="EditProjectEditSaveButton" >
                                    <div >
                                        <div >
                                            <MotifButton
                                                onClick={() => { onConfirmClick() }}
                                                disabled={!isTitleGiven}
                                            >
                                                {isExplore && (
                                                    "Add"
                                                )}
                                                {!isExplore && (
                                                    "Confirm"
                                                )}
                                            </MotifButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>

                </MotifModalFooter>

            </MotifModal>
        </div >
    )
}
export default FavouritePopUp;
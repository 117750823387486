import React, { useEffect, useState, useContext } from 'react';
import './ViewProjectDetail.css';
import { MotifBreadcrumb, MotifBreadcrumbItem, MotifSelect, MotifOption } from "@ey-xd/motif-react";
import dashboardnav from "../../../assets/image/dashboardnav.svg";
import { DashboardContext } from "../../../context/dashboardContext";
import '../../CreateProject/AddMember.css';
import { Providers, SimpleProvider, ProviderState } from '@microsoft/mgt-element';
import GraphService from '../../../services/graphService';
import MemberService from '../../../services/memberService';
import MotifTable from '@ey-xd/motif-react/Table';
import avatarImage from "../../../assets/image/avatarDefault.png";
import logo from "../../../assets/image/logo.svg";
import { useNavigate } from "react-router-dom";

export function ViewProjectDetail() {
    const [proDetClick, setProDocClick] = useState(true);
    const [memClick, setMemClick] = useState(false);
    const [category, setCategory] = useState("");
    const [paceID, setPaceID] = useState("");
    const [engagementId, setEngagementId] = useState("");
    let categoryList = useState([]);
    const { GetProjectDetailById } = useContext(DashboardContext);
    const [projectName, setProjectName] = useState('');
    const [clientName, setClientName] = useState("");
    const [confidentialProject, setConfidentialProject] = useState(false);
    const [area, setArea] = useState("");
    const [region, setRegion] = useState("");
    const [country, setCountry] = useState("");
    const [dealSize, setDealSize] = useState("");
    const [targetSector, setTargetSector] = useState("");
    const [targetSubSector, setTargetSubSector] = useState("");
    const [targetSubSubSector, setTargetSubSubSector] = useState("");
    const [dataHosting, setDataHosting] = useState("");
    const [description, setDescription] = useState("");
    let areaList = useState([]);
    let regionList = useState([]);
    let countryList = useState([]);
    let dealSizeList = useState([]);
    let targetSectorList = useState([]);
    let targetSubSectorList = useState([]);
    let targetSubSubSectorList = useState([]);
    let dataHostingList = useState([]);
    const projectMasterData = require("../../../data/projectMasterData");
    const projectId = sessionStorage.getItem('DashboardProjectId');
    const dataHostingLocation = sessionStorage.getItem('DataHostingLocation');
    const navigate = useNavigate();
    const [projectScopeName, setProjectScopeName] = useState([]);
    let projectScopeList = [];
    let scopeNameList = [];
    let { scopeAll } = useContext(DashboardContext);
    
    let [editrowData, setRowData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        Providers.globalProvider = new SimpleProvider((scopes) => {
            const graphService = new GraphService();
            const graphApiToken = graphService.getToken();
            return Promise.resolve(graphApiToken);
        });
        fetchMemberDetails();

    }, []);
    // Fetch All the member details that are assigned to the project
    const fetchMemberDetails = async () => {

        let dataReturn = [];
        setRowData([]);

        Providers.globalProvider.setState(ProviderState.SignedIn);
        const memberService = new MemberService();
        let data = await memberService.GetProjectDetailById(projectId, dataHostingLocation);
        if (data) {

            dataReturn = data;
            const graphService = new GraphService();
            let getMembersAdded = [];
            for (const element of dataReturn) {

                const userbymail = await graphService.getUserDetailsByInput(element.email);
                const userAvatar = await graphService.getUserAvatar(userbymail.value[0].id);
                let newRow = []
                let role ="";
                if (element.role == null) {
                    role ="Owner";
                }
                else{
                    role =element.role ;
                }
                newRow = { FullName: userbymail.value[0].displayName, Title: userbymail.value[0].jobTitle, Role: role, isFixed: true, Email: userbymail.value[0].mail, FirstName: userbymail.value[0].surname, LastName: userbymail.value[0].givenName, Project_UUID: sessionStorage.getItem('DashboardProjectId'), ProjectName: sessionStorage.getItem('DashboardProjectName'), Avatar: userAvatar, GroupName: '',isAlreadySaved: true };
                getMembersAdded.push(newRow);
            }            
            setRowData(prevState => ([...prevState, ...getMembersAdded]));
        }
    };

     /**
     * Description
     * -----------
     * This method is to get existing project detail filtering the projectid
     */
     const getScopeList = async () => {        
        if (scopeAll) {
            projectScopeList=scopeAll;
        }
    }

    // Grid  Table header names
    const colDefs = [
        {
            headerName: 'Name',
            field: 'FullName',
            width: 300,
            cellRenderer: params => {
                return <PeoplePickerAvatarRenderer value={params}></PeoplePickerAvatarRenderer>;
            }
        },
        {
            field: 'Title',
            width: 300,
            headerComponentParams: (params) => {
                return { template: `<div>Title` }
            },
        },
        {
            headerName: 'Role',
            field: 'Role',
            cellRenderer: params => {
                return params.data.Role;               
            }

        },
        {
            headerName: "",
            field: '',
            width: 100
        }

    ];
    // Custom components for Dropdown and Avatar in motif table
    const frameworkComponents = {
        dropdownRenderer: DropdownRenderer,
        peoplePickerAvatarRenderer: PeoplePickerAvatarRenderer
    }

    useEffect(() => {
        categoryList = projectMasterData.CategoryConfig;
        areaList = projectMasterData.AreaConfig;
        regionList = projectMasterData.RegionConfig;
        countryList = projectMasterData.CountryConfig;
        dealSizeList = projectMasterData.DealConfig;
        targetSubSectorList = projectMasterData.SubSectorConfig;
        targetSectorList = projectMasterData.SectorConfig;
        targetSubSubSectorList = projectMasterData.SubSubSectorConfig;
        dataHostingList = projectMasterData.DataHostingListConfig;
        setDetailData();
    }, []);

    const setDetailData = async () => {

        let data = await GetProjectDetailById(projectId, dataHostingLocation);
        if (data) {
            setPaceID(data[0].paceId);
            setCategory(categoryList.filter((item) => item.id == data[0].category)[0].content);
            setEngagementId(data[0].engagementId);
            setProjectName(data[0].projectName);
            setClientName(data[0].clientName);
            setDescription(data[0].description);
            setArea(areaList.filter((item) => item.id == data[0].area)[0].content);
            setRegion(regionList.filter((item) => item.id == data[0].region)[0].content);
            setCountry(countryList.filter((item) => item.id == data[0].country)[0].content);
            setDealSize(dealSizeList.filter((item) => item.id == data[0].dealSize)[0].content);
            setTargetSector(targetSectorList.filter((item) => item.id == data[0].targetSector)[0].content);
            setDataHosting(dataHostingList.filter((item) => item.id == data[0].dataHostingLocation)[0].content);
            setTargetSubSector(targetSubSectorList.filter((item) => item.id == data[0].target_SubSector
            )[0].content);
            if (data[0].confidential == projectMasterData.ConfidentialUnCheck) {
                setConfidentialProject(false);
            }
            else {
                setConfidentialProject(true);
            }
            if (data[0].confidential == projectMasterData.ConfidentialCheck) {
                setClientName(projectMasterData.ClientNameMask);
            }
            else {
                setClientName(data[0].clientName);
            }
            setTargetSubSubSector(targetSubSubSectorList.filter((item) => item.id == data[0].target_Sub_SubSector
            )[0].content);

            await getScopeList();
            let projectScopeSelected = JSON.parse(data[0].scopeId);
            if (projectScopeSelected !== null) {
                if (projectScopeSelected.length > 0) {
                    projectScopeSelected.forEach((key) => {
                        scopeNameList.push(projectScopeList.filter((item) => item.scopeId === key)[0].scopeName);
                    });
                    setProjectScopeName(scopeNameList.toString());
                }
            }
        }
    }
    const onLogoButtonClick = () => {
        navigate("/dashboardPlatformAdmin");
      };
    return (
        <>
            <div class="DashProjProjectLandingPage">
                <div className="ViewProjHeaderNav">
                    <div className="ViewProjLogoAndMenu">
                        <div className="ViewProjLogoEngGap">
                            <div className="ViewProjLogoProduct">
                                <div className="ViewProjLogo">
                                    <div className="ViewProjContainerLogo">
                                        <img src={logo} onClick={onLogoButtonClick} className="img-hover"></img>
                                    </div>
                                </div>
                                <div className="ViewProjProductName">Digital Diligence Assistant</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="DashProjPageHeader">
                    <div class="DashProjBreadcrumb">
                        <div class="DashProjBreadcrumbBase" >
                            <MotifBreadcrumb
                                triggerButtonProps={{
                                    'aria-label': 'Custom Trigger Button aria-label'
                                }}
                            >
                                <MotifBreadcrumbItem>
                                    <a href="/dashboardPlatformAdmin">
                                        Dashboard
                                    </a>
                                </MotifBreadcrumbItem>
                            </MotifBreadcrumb>

                            <div class="DashProjNavArrowRight" >
                                <img src={dashboardnav}></img>
                            </div>
                            <div class="DashProjBreadcrumbItem" >
                                <div class="DashProjText1" >{projectName}</div>
                            </div>
                        </div>
                    </div>
                    <div class="DashProjContent">
                        <div class="DashProjTitle" >
                            <div class="DashProjTextAndSupportingText">
                                <div class="DashProjText2" >
                                    <div class="DashProjText3" title={projectName}>{projectName}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="right-panel-nav-container">
                        <div className="right-panel-nav-source-doc-container">
                            <div
                                style={{ width: 129 }}
                                data-testid="btnProjectDetails"
                                className={
                                    proDetClick
                                        ? "right-panel-active-card right-panel-active-select"
                                        : "right-panel-active-card"

                                }
                                onClick={() => {
                                    setProDocClick(true);
                                    setMemClick(false);
                                }}
                            >
                                <div className="right-panel-nav-source-doc">Project Details</div>
                            </div>
                            <div
                                style={{ width: 68 }}
                                data-testid="btnMembers"
                                className={
                                    memClick
                                        ? "right-panel-active-card right-panel-active-select"
                                        : "right-panel-active-card"
                                }
                                onClick={() => {
                                    setProDocClick(false);
                                    setMemClick(true);
                                }}
                            >
                                <div className="right-panel-container-favourate-tab">Members</div>
                            </div>
                        </div>
                        {proDetClick ? (
                            <>
                                <div class="ProjViewDetailFrame1" >
                                    <div class="ViewProjSelect">
                                        <div class="DashProjInputDropdownBase" >
                                            <div class="DashProjInputWithLabel" >
                                                <div class="DashProjLabel" >Project type</div>
                                                <div class="DashProjLabel1" label="lblcategory">{category}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ViewProjSelect" >
                                        <div class="DashProjInputDropdownBase">
                                            <div class="DashProjInputWithLabel" >
                                                <div class="DashProjLabel">PACE ID</div>
                                                <div class="DashProjLabel1" label="lblpaceid">{paceID}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ViewProjSelect" >
                                        <div class="DashProjInputDropdownBase">
                                            <div class="DashProjInputWithLabel" >
                                                <div class="DashProjLabel" >Engagement ID</div>
                                                <div class="DashProjLabel1" label="lblengagementid">{engagementId}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ProjViewDetailFrame2" >
                                    <div class="ViewProjSelect" >
                                        <div class="DashProjInputDropdownBase" >
                                            <div class="DashProjInputWithLabel">
                                                <div class="DashProjLabel">Project name</div>
                                                <div title={projectName} class="DashProjLabel1" label="lblprojectname" >{projectName}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ViewProjSelect" >
                                        <div class="DashProjInputDropdownBase" >
                                            <div class="DashProjInputWithLabel" >
                                                <div class="DashProjLabel" >Confidential project</div>
                                                <div class="DashProjLabel1" label="lblregion">{confidentialProject?"Yes":"No"}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="DashProjSelect1" >
                                        <div class="DashProjFrame5383" >
                                            <div class="DashProjLabel">Client name</div>
                                        </div>
                                        <div class="DashProjInputDropdownBase1" >
                                            <div class="DashProjInputWithLabel1" >
                                                <div title={clientName} class="DashProjLabel1" label="lblclientname">{clientName}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="ProjViewDetailFrame3" >
                                    <div class="ViewProjSelect" >
                                        <div class="DashProjInputDropdownBase" >
                                            <div class="DashProjInputWithLabel" >
                                                <div class="DashProjLabel" >Area</div>
                                                <div title={area} class="DashProjLabel1" label="lblarea">{area}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ViewProjSelect" >
                                        <div class="DashProjInputDropdownBase" >
                                            <div class="DashProjInputWithLabel" >
                                                <div class="DashProjLabel" >Region</div>
                                                <div title={region} class="DashProjLabel1" label="lblregion">{region}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ViewProjSelect" >
                                        <div class="DashProjInputDropdownBase" >
                                            <div class="DashProjInputWithLabel" >
                                                <div class="DashProjLabel" >Country</div>
                                                <div title={country} class="DashProjLabel1" label="lblcountry">{country}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dealSize" >
                                    <div class="ViewProjSelect" >
                                        <div class="DashProjInputDropdownBase" >
                                            <div class="DashProjInputWithLabel" >
                                                <div class="DashProjLabel" >Deal size (USD)</div>
                                                <div title={dealSize} class="DashProjLabel1" label="lbldealsize">{dealSize}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tarSector">
                                    <div class="ViewProjSelect" >
                                        <div class="DashProjInputDropdownBase" >
                                            <div class="DashProjInputWithLabel" >
                                                <div class="DashProjLabel" >Target sector</div>
                                                <div title={targetSector} class="DashProjLabel1" label="lbltargetsector">{targetSector}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tarSubSector">
                                    <div class="ViewProjSelect" >
                                        <div class="DashProjInputDropdownBase" >
                                            <div class="DashProjInputWithLabel" >
                                                <div class="DashProjLabel" >Target sub-sector</div>
                                                <div title={targetSubSector} class="DashProjLabel1" label="lbltargetsubsector">{targetSubSector}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tarSubSubSector" >
                                    <div class="ViewProjSelect" >
                                        <div class="DashProjInputDropdownBase" >
                                            <div class="DashProjInputWithLabel" >
                                                <div class="DashProjLabel" >Target sub-sub-sector</div>
                                                <div title={targetSubSubSector} class="DashProjLabel1" label="lbltargetsubsubsector">{targetSubSubSector}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tarDataHosting" >
                                    <div class="DashProjInputDropdownBase" >
                                        <div class="DashProjInputWithLabel" >
                                            <div class="DashProjLabel" >Data hosting</div>
                                            <div class="DashProjLabel1" label="lbldatahosting">{dataHosting}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="projectScope" >
                                    <div class="DashProjInputDropdownBase" >
                                        <div class="DashProjInputWithLabel" >
                                            <div class="DashProjScopeLabel" >Project Scope (Optional)</div>
                                            <div class="DashProjLabel1" label="lblprojectscope">{projectScopeName}</div>
                                        </div>
                                    </div>
                                </div>

                                {/*<div class="projectDescription" >
                                    <div class="DashProjTextArea" >
                                        <div class="DashProjTextarea1" >
                                            <div class="DashProjLabelGroup" >
                                                <div class="DashProjLabelOutside" label="lbldescription">Description</div>
                                            </div>
                                            <div title={description} class="DashProjLabelDescription">
                                                {description}
                                            </div>
                                        </div>
                                    </div>
                                </div>*/}
                            </>
                        ) : memClick ? (
                            <>
                                <div className="MemberDashProjFrame3" >
                                    <div className={
                                        "ag-theme-quartz"
                                    }
                                        style={{ width: '1000px', height: '400px' }} >
                                        <MotifTable columnDefs={colDefs} rowData={editrowData} frameworkComponents={frameworkComponents}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div >
        </>
    )

}
const DropdownRenderer = (props) => {
    const options = ['Admin', 'Viewer'];
    const [value, setValue] = useState(props.value.data.Role);

    useEffect(() => {
        setValue(props.value.data.Role)
    }, [props.value.data.Role]);
    const handleChange = (event) => {
        props.onCellValueChanged();
        const Role = event;
        props.value.data.Role = Role;
        setValue(Role);
        if (props.value.data.isAlreadySaved) {
            props.value.data.isUpdated = true;
        }
        props.value.node.setDataValue('Role', Role);
    };
    return (
        <MotifSelect
            className="Input"
            ariaLabelledBy="select-label"
            value={props.value != null && props.value.data != null ?  props.value.data.Role: ""}
            onChange={handleChange}
        >
            {options.map((option,index) => {
                return (
                    <MotifOption value={option} key={index}>
                        {option}
                    </MotifOption>
                );
            })}
        </MotifSelect>

    );
};
// Avatar implementation in Motif table
const PeoplePickerAvatarRenderer = (value) => {
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [userName, setuserName] = useState(null);
    const [userEmail, setuserEmail] = useState(null);

    useEffect(() => {

        if (value.value.data.isAlreadySaved) {
            setuserName(value.value.data.FullName);
            setuserEmail(value.value.data.Email);

            if (value.value.data.Avatar != undefined) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setAvatarUrl(e.target.result);
                };
                reader.readAsDataURL(value.value.data.Avatar);
            }
            else {
                setAvatarUrl(avatarImage);
            }

        }
        else {
            if (value.value.data.Avatar != undefined) {
                setAvatarUrl(value.value.data.Avatar);
            }
            else {
                setAvatarUrl(avatarImage);
            }
            setuserName(value.value.data.FullName);
            setuserEmail(value.value.data.Email);
        }
    })

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {(
                <img src={avatarUrl} alt="Avatar" style={{ width: "30px", height: "30px", position: "relative",borderRadius: "48px" }}></img>

            )}
            {<div style={{ marginLeft: '8px' }}> {userName ? userName : ''}
                <div style={{ marginLeft: '1px' }}> {userEmail ? userEmail : ''}</div>
            </div>}


        </div>
    )

};
export default ViewProjectDetail;
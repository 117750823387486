import React from "react";
import "./InstantInsights.css";
import InProgressBar from "./InProgressBar";
import noProject from "../../../assets/image/no-project-image.png";

export default function NoInstantGenerated() {
    return (
       
<div className="noScopeBoard">
    <div className="noScopes">
        <div className="noScopeDiv_noinstant">
            <div>
                <img src={noProject} className="no scopes" alt="no projects"></img>
            </div>
            <div className="instants-being-generated">Your insights are being generated</div>
            <div className="noResponseMsg">               
                    <div className="no-instant-generated-msg">
                        You will be notified when Instant Insights <br>
                        </br>
                        are complete
                    </div>
         
            </div>
            <div>
            
                                    <div className="progressbar-div">
                                    <h5 className="instants-generate">Generating insights...</h5>
                                        <InProgressBar />
                                    </div>
                                    <div className="spaceprogress">

                                    </div>
                                    <div className="instants-being-status">
                    The status of insights of each sub-section can be seen by <br>
                        </br>
                        expanding the menu on the left hand pane.
                    </div>
                                    <div >               
                    
         
            </div>
                </div>
              
        </div>
    </div>
</div>
    )
}
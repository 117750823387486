import React, { useState, useContext, useEffect } from "react";
import { MotifButton, MotifInput } from "@ey-xd/motif-react";

import { NavLink, useNavigate } from "react-router-dom";
import "./ConfigureSetting.css";
import "../common/Header.css";
import dashboardnav from "../../assets/image/dashboardnav.svg";
import { DashboardContext } from "../../context/dashboardContext.js";
import arrowLeft from "../../assets/image/arrow-left.svg";
import logo from "../../assets/image/logo.svg";
import avatarImage from "../../assets/image/default-user-icon.jpg";
import logOut from "../../assets/image/log-out.svg";
import { useMsal } from '@azure/msal-react';
import ConfigurationService from "../../services/configurationService";

export default function ConfigureSetting(props) {
  const [docSizeChange, setdocSizeChange] = useState("");

  const [docNoChange, setdocNoChange] = useState("");

  const [validateData, setButtonEnabled] = useState(true);

  const [confirmDivDisplay, setConfirmDivDisplay] = useState(false);

  const { SaveDocumentSetting } = useContext(DashboardContext);
  const { instance } = useMsal();
  const [showUserMenu, setShowUserMenu] = useState(false);
  
  const configurationService = new ConfigurationService();
  useEffect(()=>{
    configurationService.getDocumentSettings().then((response) => {
      setdocNoChange(response.noOfDocuments);
      setdocSizeChange(response.documentSize);
    });
  }, []);

  const InitializePage = () => {
    setdocNoChange("");
    setdocSizeChange("");
    setButtonEnabled(true);
    setConfirmDivDisplay(false);
  };
  const navigate = useNavigate();

  function checkNegativeNumber(input) {
    const regex = /^\d+$/;
    return !(input && !regex.test(input));
  }

  function docNoTextChange(docNoValue) {
    if ( docNoValue.length <=4 && checkNegativeNumber(docNoValue)) {
      setdocNoChange(docNoValue);
    }
  }

  function docSizeTextChange(docSizeValue) { 
    if ( docSizeValue.length <=4 && checkNegativeNumber(docSizeValue)) {
      setdocSizeChange(docSizeValue);
    }
  }

  const handleSaveDocumentSetting = async (e) => {
    e.preventDefault();

    const response = await SaveDocumentSetting(docNoChange, docSizeChange);

    if (response.ok) {
      setConfirmDivDisplay(true);
    }
  };

  function validateSetting() {
    if (docNoChange !== "" && docSizeChange !== "") setButtonEnabled(false);
    else setButtonEnabled(true);
  }
  const onLogoButtonClick = () => {
    navigate("/dashboardPlatformAdmin");
  };

  /**
  * Description
  * -----------
  * This method shows the username on avatar image
  */
  const showLoggedInUserMenu = () => {
    setShowUserMenu(true);
    document.body.addEventListener("click", hideLoggedInUserMenu);
  };

  /**
  * Description
  * -----------
  * This method hides the username on avatar image
  */
  const hideLoggedInUserMenu = () => {
    setShowUserMenu(false);
  };

  /**
   * Description
   * -----------
   * This method logout from Azure Ad and navigate to logout page
   */
  const handleLogOut = (e) => {
    e.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    const logoutRequest = {
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: "/LogOut",
    };
    instance.logout(logoutRequest);
  };


  return (
    <div className="config-container">
      {confirmDivDisplay && (
        <div className="ConfirmationStyle1" id="confirmDiv">
          <div className="ConfirmationStyle2">
            <div className="ConfirmationStyle3">
              <div className="ConfirmationStyle4">
                Configure settings updated{" "}
              </div>
            </div>
            <div className="ConfirmationStyle5">
              You’ve successfully updated your configuration settings
            </div>
          </div>
          <div className="ConfirmationStyle6">
            <div className="ConfirmationStyle7">
              <div className="ConfirmationStyle8">
                <div className="ConfirmationStyle9">
                  <NavLink
                    to="/dashboardPlatformAdmin"
                    className="Text RemoveUnderline"
                  >
                    Back to Dashboard
                  </NavLink>{" "}
                </div>
                <div className="ConfirmationStyle10">
                  <img className="darken" src={arrowLeft} alt=""></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="Frame1DocSetting">
        <div className="Frame2DocSetting">
          <div className="Frame3DocSetting">
            <div className="Frame4DocSetting">
              <div className="Frame5DocSetting">
                <div className="Frame6DocSetting">
                  <NavLink
                    to="/adminDashboard/dashboardPlatformAdmin"
                    className="Text RemoveUnderline"
                  >
                    Dashboard
                  </NavLink>
                </div>
              </div>
              <div className="Settings1">
                <img src={dashboardnav} alt=""></img>
              </div>
              <div className="Settings3">
                <div className="Settings4">Configure Settings</div>
              </div>
            </div>
          </div>
          <div className="Settings5">
            <div className="Settings6">
              <div className="Settings7">
                <div className="Settings8">
                  <div className="Settings9">Configure Settings</div>
                </div>
              </div>
            </div>

            <div className="ResetButton3">
              <MotifButton onClick={InitializePage}>Reset</MotifButton>
            </div>

            <div className="SaveButton1">
              <div className="SaveButton2">
                <div className="SaveButton3">
                  <MotifButton
                    disabled={validateData}
                    onClick={handleSaveDocumentSetting}
                  >
                    Save
                  </MotifButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="Noofdocuments1">Document settings</div>
        <div className="Noofdocuments2">
          <div className="Noofdocuments3">
            <div className="Noofdocuments4">
              <div className="Noofdocuments5">
                <div className="Noofdocuments6">No. of documents</div>

                <div className="Noofdocuments8">
                  <MotifInput
                    id="docNo"
                    required
                    type="number"
                    className="WidthTextbox"
                    placeholder="Enter maximum number of documents"
                    onChange={(e) => {
                      docNoTextChange(e.target.value);
                    }}
                    value={docNoChange}
                    onBlur={validateSetting}
                  ></MotifInput>
                </div>
              </div>
              <div className="SetMaximumLimit1">
                <div className="SetMaximumLimit2">
                  Set maximum limit of documents uploaded per project
                </div>
              </div>
            </div>
          </div>
          <div className="DocumentSize1">
            <div className="DocumentSize2">
              <div className="DocumentSize3">
                <div className="DocumentSize4">Document size</div>

                <div className="DocumentSize6">
                  <MotifInput
                    id="docSize"
                    required
                    className="WidthTextbox"
                    type="number"
                    placeholder="Enter maximum document size"
                    onChange={(e) => {
                      docSizeTextChange(e.target.value);
                    }}
                    value={docSizeChange}
                    onBlur={validateSetting}
                  ></MotifInput>
                </div>
              </div>
              <div className="MaxDocSize1">
                <div className="MaxDocSize2">
                  Set maximum document size per project <b>(in GB)</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

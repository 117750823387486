import React, { useEffect, useState } from "react";
import MotifAccordion, {
  MotifAccordionTrigger,
  MotifAccordionContent,
} from "@ey-xd/motif-react/Accordion";
import { MotifTooltip } from "@ey-xd/motif-react";
import { MotifIconButton } from "@ey-xd/motif-react/Button";
import TextHighlighter from "../../common/TextHighlighter.js";
import Utils from "../../../utils/Utils.js";
import favIcon from "../../../assets/image/favorite.svg";
import redWarningInfoIcon from "../../../assets/image/Light circle.svg";
import refreshIcon from "../../../assets/image/refresh-double.svg";
import noLinkIcon from "../../../assets/image/no-link.svg";
import LikeDislike from "../../common/LikeDislike.js";
import MarkdownWithoutStream from "./MarkdownWithoutStream.js";

const PromptResponseAccordion = (props) => {
  const constantData = require("../../../data/constant.js");
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isClearSelection, setIsClearSelection] = useState(false);
  const [markdownResponseHTMLString, setMarkdownResponseHTMLString] = useState('');
  const utils = new Utils();
  const { instantInsightPromptHistoryId, userActionId, userAction, sectionName, setLaunchedBy } = props.promptnResponse;

  const promptObject = {
    promptHistoryId: instantInsightPromptHistoryId,
    userActionId: userActionId,
    userAction: userAction,
    sectionName: sectionName
  };
  const vExtendHeightAwaitTime = 500;  //  Time in milliseconds for Height increase/decrease of accordion expand/collapse
  const vCurrentProjDbNavLayerHeight = 900;  // Current hardcoded height of 'projectdashboardnavdiv'
  const vPromptAccordion = 'prompt-accordion';  //  Prompt accordion class
  const vParentProjDbNavLayer = 'projectdashboardnavdiv';  //  Parent Project Dashboard Navigation Layer
  const vGutterGap = 17;  //  Right section gutter gap
  const vDefRightSectionHeight = 90 + 22 + 28 + 22 + (2 * vGutterGap); //  Breadcrumb, Header, Right header, Right sub header, 2 times Gap

  /**
   * This method calls when select all flag reset
   */
  const clearSelectAll = () => {
    setIsSelectAll(false);
  };

  /**
   * This method calls when selection happens
   */
  const setTextSelection = (selection) => {
    props.onSelectionText(selection);
  };

  /**
   * This method calls when un-favorite the response
   */
  const setUnFavorite = (e) => {
    e.stopPropagation();
    props.markResponseUnFavorite(
      true,
      props.promptnResponse.instantInsightPromptHistoryId,
      props.promptnResponse.instantInsightFavoritePromptId
    );
  };

  /**
   * This useEffect method calls when user mark for selection
   */
  useEffect(() => {
    if (
      props.promptnResponse.enableMarkSelection &&
      props.promptnResponse.selectAllText
    ) {
      setIsSelectAll(true);
    }
    if (
      props.promptnResponse.enableMarkSelection &&
      !props.promptnResponse.selectAllText
    ) {
      setIsClearSelection(!isClearSelection);
    }
  }, [props.allowMarkSelection]);

  const handleAcknowledgeRefreshIconClick = () => {
    props.setLaunchedBy("prompt");
    props.setShowAcknowledgeRefreshPopup(true);
    props.setPromptForAcknowledgement(props.promptnResponse)
  }
  /**
   * Function to adjust 'projectdashboardnavdiv' height when accordion expands or collapses by user
   */
  return (
    <MotifAccordion
      className={
        "prompt-accordion" + (props.isHighlighted ? " blue-highlight" : "")
      }
      alignIconRight={true}
      useChevronIcon={true}
      onClose={() => {
        props.handlePromptUnSelect();
        
      }}
      onOpen={() => {
        props.handlePromptSelect(props.promptnResponse);
      }}
    >
      <MotifAccordionTrigger>
        <div className="accaordian-header-container">
          <div className="accordian-header">
            <div className="accordian-header-div">
              <div className="accordian-header-text">
                { ((!props.promptnResponse.promptResponse || 
                    (props.promptnResponse.promptResponse && !props.promptnResponse.sourceDocuments) ) ? <img className="icon-red-info" src={redWarningInfoIcon}></img> : <></>)
                }
                { !props.promptnResponse.isRefreshAcknowledged ? <img className="tree-icon-refresh" src={refreshIcon} onClick={() => { handleAcknowledgeRefreshIconClick() }}></img> : <></> }
                {props.promptnResponse.promptTitle}

                <div className="linkspace"></div>
                {props.promptnResponse.isBroken === 1 && (
                  <MotifTooltip                  
                  trigger={
                    <MotifIconButton >
                      <img src={noLinkIcon} alt="" />
                    </MotifIconButton>
                  }
                >
                  Document deleted
                </MotifTooltip>
                )}
              </div>
            </div>
          </div>
          <div className="gapbetweenicons">
            {
              /* 944664 - Out of Scope - Thumps up/down option for Red flag items and Management follow up questions.*/
              props.promptnResponse.sectionName !== "Red-Flag Items"
              && props.promptnResponse.sectionName !== "Management Follow-up questions"
              && (
                <LikeDislike data={promptObject} type="instantinsightsservice" />
              )}
            {props.promptnResponse.favoriteContent && (
              <MotifTooltip
                trigger={
                  <MotifIconButton onClick={setUnFavorite}>
                    <img alt="" src={favIcon} />
                  </MotifIconButton>
                }
              >
                Remove from favorites
              </MotifTooltip>
            )}
          </div>
        </div>
      </MotifAccordionTrigger>
      <MotifAccordionContent>
        {
          (() => {
            if (props.promptnResponse.promptResponse) {
              return (
                <>
                  <div className={'markdown-hide'}>
                    <MarkdownWithoutStream markdownText={utils.htmlToMarkdown(props.promptnResponse.promptResponse)} 
                      setMarkdownResponseHTMLString={setMarkdownResponseHTMLString}/>
                  </div>
                  {!props.promptnResponse.enableMarkSelection &&
                    !props.promptnResponse.favoriteContent && (
                      <MarkdownWithoutStream markdownText={utils.htmlToMarkdown(props.promptnResponse.promptResponse)} 
                        setMarkdownResponseHTMLString={setMarkdownResponseHTMLString}/>
                    )}
                  {(props.promptnResponse.enableMarkSelection ||
                    props.promptnResponse.favoriteContent) && (
                      <p>
                        <TextHighlighter
                          htmlString={props.promptnResponse.displayPackage === 'showdown' ? utils.markdownToHtml(props.promptnResponse.promptResponse) : markdownResponseHTMLString}
                          selectAll={isSelectAll}
                          onClearSelectAll={clearSelectAll}
                          onSelection={setTextSelection}
                          currentSelection={props.promptnResponse.favoriteContent}
                          clearSelection={isClearSelection}
                          allowSelection={props.promptnResponse.enableMarkSelection}
                        />
                      </p>
                    )}
                </>
              )
            }
            else {
              return (<p>No information available in the current document corpus relevant to this prompt.</p>)
            }
          })()
        }
      </MotifAccordionContent>
    </MotifAccordion>
  );
};
export default PromptResponseAccordion;

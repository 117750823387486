/**
 * Expand prompt modal
 * Created on 26-08-24
 */
import React, { useEffect, useState, useCallback, useRef } from "react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import { MotifIconButton } from "@ey-xd/motif-react/Button";

import collapseprompt from "../../../assets/image/collapse.svg";

import { MotifRichTextEditor, MotifTooltip } from "@ey-xd/motif-react";
import "./ExploreData.css";
import Utils from "../../../utils/Utils";

const ExpandPromptModal = (props) => {
  const utils = new Utils();
  const [questionBar, setQuestionBar] = useState(props.questionBar);
  const promprRef = useRef(null);

  const handlePromptChange = (delta, old, source) => {
    setQuestionBar(delta);
  };

  const motifReactEditorModules = {
    toolbar: [
      [
        {
          list: "ordered",
        },
        {
          list: "bullet",
        },
      ],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const collapseClick = () => {
    props.setExpandPromptModal(!props.expandPromptModal);
    props.setQuestionBar(questionBar);
  };

  useEffect(() => {
    const checkModalLoaded = () => {
      const promptEditor = promprRef.current;
      if (promptEditor) {
        const numberedBtn = promptEditor.querySelector(
          `button[value="ordered"]`
        );
        const bulletBtn = promptEditor.querySelector(`button[value="bullet"]`);
        if(numberedBtn){
          utils.addCustomTooltip(numberedBtn, 'Numbered list', 'modalTooltipNumberedList', 'top');
        }
        if(bulletBtn){
          utils.addCustomTooltip(bulletBtn, 'Bulleted list', 'modalTooltipBulletedList', 'top');
        }
      } else {
        // Retry after a short delay if the modal is not yet loaded
        setTimeout(checkModalLoaded, 10);
      }
    };
    checkModalLoaded();

    return () => {
      let tooltipNumberedList = document.getElementById('modalTooltipNumberedList');
      if (tooltipNumberedList) {
        document.body.removeChild(tooltipNumberedList);
      }
      let tooltipBulletedList = document.getElementById('modalTooltipBulletedList');
      if (tooltipBulletedList) {
        document.body.removeChild(tooltipBulletedList);
      }
    };
  }, []);
  
  return (
    <MotifModal
      id="expandpromptModal"
      className="expand-modal-container"
      size="xl"
      show={true}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      onClose={collapseClick}
    >
      <MotifModalHeader
        className="expand-modal-header"
        closeButtonProps={{
          "aria-label": "Custom Close Button aria-label",
          title: "Custom Close Button title",
        }}
        closeModalButton={

          <MotifIconButton
            className="btn-collapse"
            onClick={() => collapseClick()}
          >
            <MotifTooltip
              placement="bottom"
              hideCloseButton={true}
              contentClassName="tooltipWithoutCloseCenterText tooltop-collapse"
              trigger={
                <img src={collapseprompt} alt="collapse" />
              }
            >
              Collapse
            </MotifTooltip>
          </MotifIconButton>
        }
      >
        Ask a question
      </MotifModalHeader>
      <MotifModalBody className="expand-modal-body">
        <div id="modalExpandPrompt" ref={promprRef}>
          <MotifRichTextEditor
            id="expand-prompt-editor"
            className="expand-text-editor"
            placeholder="Ask a question..."
            value={questionBar}
            modules={motifReactEditorModules}
            onChange={handlePromptChange}
          />
        </div>
      </MotifModalBody>
    </MotifModal>
  );
};

export default ExpandPromptModal;

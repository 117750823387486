import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Observable } from 'rxjs';
import remarkGfm from "remark-gfm";
import "./MarkdownStream.css";

function MarkdownStream(props) {
    const [text, setText] = useState('');

    useEffect(() => {
        const textObservable = new Observable(subscriber => {
            if (props.markdownText) {
                let index = 0;
                const intervalId = setInterval(() => {
                    if (index < props.markdownText.length) {
                        subscriber.next(props.markdownText[index]);
                        index++;
                    } else {
                        clearInterval(intervalId);
                        subscriber.complete();
                    }
                }, 10);
            }
        });

        const subscription = textObservable.subscribe({
            next: char => setText(prevText => prevText + char),
            error: err => console.error(err),
            complete: () => console.log('Streaming complete')
        });

        return () => subscription.unsubscribe();
    }, [props.markdownText]);

    return (
        <>
            {text && text.length > 0 && (
                <ReactMarkdown className="markdown-table" remarkPlugins={[remarkGfm]}>
                    {text}
                </ReactMarkdown>
            )}
        </>
    );
  }
  
export default MarkdownStream;
import React, { useEffect, useState } from "react";
import {
  MotifErrorMessage,
  MotifFormField,
  MotifMessage,
  MotifPaginationSelect,
  MotifPaginationSelectItem,
} from "@ey-xd/motif-react";
import { MotifToast } from "@ey-xd/motif-react";
import MotifTable from "@ey-xd/motif-react/Table";
import { getDocument } from "pdfjs-dist";
import _ from "lodash";
import Utils from "../../../utils/Utils";
import docIcon from "../../../assets/image/page.svg";
import keyIcon from "../../../assets/image/key.svg";
import eyeIcon from "../../../assets/image/eye_opned.svg";
import eyeCloseIcon from "../../../assets/image/eye_closed.svg";
import {
  MotifButton,
  MotifInput,
  MotifLabel,
  MotifTooltip,
} from "@ey-xd/motif-react";
import "./DocumentTable.css";

const DocumentTable = (props) => {
  const utils = new Utils();

  const [documents, setDocuments] = useState([]);
  const [documentGrid, setDocumentGrid] = useState(null);
  const [isDocumentRemoved, setIsDocumentRemoved] = useState(false);

  useEffect(() => {
    setDocuments(props.documents);
    if (documentGrid) {
      documentGrid.api.setRowData(props.documents);
    }
  }, [props.documents]);

  // useEffect(() => {
  //   if (documentGrid) {
  //     documentGrid.columnApi.setColumnsVisible(["0"], props.allowSelection);
  //   }
  // }, [props.allowSelection]);

  // useEffect(() => {
  //   setIsDocumentRemoved(props.showMessage);
  //   setTimeout(() => {
  //     setIsDocumentRemoved(false);
  //   }, 3000);
  // }, [props.showMessage]);

  /**
   * Description
   * -----------
   * defined columns to show the selected local documents
   */
  const [colDefs] = useState([
    {
      field: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 80,
      hide: props.documentType !== "documents",
    },
    {
      field: "name",
      width: 500,
      wrapText: (props.documentType === "passprotecteddocuments")?false:true,
      autoHeight: true,
      displayName: "Documents",
      cellRenderer: (params) => (
        <div className={(props.documentType === "passprotecteddocuments")?"document-uploader-doc-name-wrapper-password":"document-uploader-doc-name-wrapper"}>
          <div className="document-uploader-doc-icon">
            <img src={docIcon} alt={params.data.file.name} />
          </div>
                <div>
                  {(props.documentType === "passprotecteddocuments") ? (
                    <MotifTooltip placement="bottom"
                      trigger={
                        <MotifLabel className="document-uploader-doc-name-label">
                          {params.data.file.name}
                        </MotifLabel>
                      }
                    >
                      {params.data.file.name}
                    </MotifTooltip>
                  ) : (<>
                  {!params.data.password && <MotifLabel className="document-file-name">{params.data.file.name}</MotifLabel>}
          {params.data.password && (
            <div className="password-file-container">
              <MotifLabel className="document-file-name">{params.data.file.name}</MotifLabel>
              <span className="password-file-message">Password entered</span>
            </div>
          )}
                  </>
                    
                    
                  )}
              </div>
        </div>
      ),
      flex: 1,
    },
    {
      field: "size",
      displayName: "Size",
      cellRenderer: (params) => (
        <div className="document-file-size-label">{utils.getBytesInMB(params.data.file.size)}MB</div>
      ),
      hide: props.documentType === "passprotecteddocuments" ? true : false,
    },
    {
      field: "password",
      headerName: "Password",
      width: 600,
      cellRenderer: (params) => {
        return (
          <PasswordInputSubmitRenderer
            value={params.data}
            onSubmitValidPassword={onValidPasswordSubmit}
          ></PasswordInputSubmitRenderer>
        );
      },
      hide: props.documentType === "passprotecteddocuments" ? false : true,
    },
  ]);

  // Custom components for Dropdown and Avatar in motif table
  const frameworkComponents = {
    passwordInputSubmitRenderer: PasswordInputSubmitRenderer,
  };

  const onDocumentSelection = (event) => {
    let selectedFiles = event.api.getSelectedRows();
    props.onDocumentsSelection(selectedFiles);
  };


  const onValidPasswordSubmit = (file) => {
    props.onPasswordSubmit(file);
  };

  const onGridReady = (events) => {
    setDocumentGrid(events);
  };

  return (
    <>
      <MotifTable
        rowData={documents}
        columnDefs={colDefs}
        frameworkComponents={frameworkComponents}
        suppressCellFocus
        suppressRowClickSelection
        debounceVerticalScrollbar={true}
        rowSelection="multiple"
        onGridReady={(event) => onGridReady(event)}
        onRowSelected={(event) => onDocumentSelection(event)}
        getRowHeight={(params) => {
          if (props.documentType === "passprotecteddocuments") {
            return 80;
          }
          return 50;
        }}
      />

      {isDocumentRemoved && (
        <MotifToast
          variant="info"
          actionName=""
          position="bottom"
          onClose={() => {
            setIsDocumentRemoved(false);
          }}
        >
          <div>
            Documents removed. Selected documents have been removed
            successfully.
          </div>
        </MotifToast>
      )}
    </>
  );
};

const PasswordInputSubmitRenderer = (props) => {
  const utils = new Utils();
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType]= useState("password");
  const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);

  const onPasswordSubmit = async () => {
    let isValidPassword = await checkFilePassword();
    if (isValidPassword) {
      props.onSubmitValidPassword({ ...props.value, password: utils.encryptText(password) });
    } else {
      setIsPasswordIncorrect(true);
    }
  };

  const checkFilePassword = async () => {
    return new Promise((resolve, reject) => {
      const vReader = new FileReader();
      vReader.onload = async () => {
        return resolve(verifyPdfPassword(vReader.result));
      };
      vReader.onerror = () => reject(vReader.error);
      vReader.readAsArrayBuffer(props.value.file);
    });
  };

  const verifyPdfPassword = async (pdfBytes) => {
    try {
      const loadingTask = getDocument({
        data: pdfBytes,
        password: password,
      });
      const pdfDoc = await loadingTask.promise;
      await pdfDoc.destroy();
      return true;
    } catch (error) {
      if (
        error.name === "PasswordException" &&
        error.message === "Incorrect Password"
      ) {
        return false;
      }
      throw error;
    }
  };

  const changePasswordView = () =>{
    if(passwordType==='password'){
      setPasswordType('text');
    } else {
      setPasswordType('password');
    }
  }
  return (
    <div className="doc-password-input-container">
      <img src={keyIcon} className="doc-password-input-key-icon" alt="" />
     
      <MotifFormField className="password-input-wrapper">
        <MotifInput
          onChange={(e) => {
            setPassword(e.target.value);
            setIsPasswordIncorrect(false);
          }}
          type={passwordType}
          value={password}
          placeholder="Enter Password"
          className="doc-password-input"
        />
        
        {isPasswordIncorrect && (
          <MotifErrorMessage>Password is incorrect</MotifErrorMessage>
        )}
      </MotifFormField>

      <MotifButton
        className="password-submit-button"
        size="large"
        type="password"
        onClick={() => {
          onPasswordSubmit();
        }}
        disabled={password === ""}
      >
        Submit
      </MotifButton>
      {
          passwordType==="password" && (<img src={eyeCloseIcon} className={`doc-password-input-eye-icon ${password !== ""?"doc-password-input-eye-icon-after":""}`} alt="" onClick={changePasswordView} />) 
        }
        {
          passwordType==="text" && (<img src={eyeIcon} className={`doc-password-input-eye-icon ${password !== ""?"doc-password-input-eye-icon-after":""}`} alt="" onClick={changePasswordView}/>) 
        }
    </div>
  );
};
export default DocumentTable;

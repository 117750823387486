import { React, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MotifButton } from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import trashPrompt from "../../../../assets/image/trashPrompt.svg";
import MotifToast from "@ey-xd/motif-react/Toast";
import arrowRight from "../../../../assets/image/arrow-right.svg";
import eyeImage from "../../../../assets/image/eye.svg";
import "./PromptAction.css"
import check_circle from "../../../../assets/image/check-circle.svg";
import PlatformService from "../../../../services/platformService";
import { DashboardContext } from "../../../../context/dashboardContext";

const PromptAction = (props) => {
  const { scopeAll } = useContext(DashboardContext);
  const { selectedScopeId } = useSelector((state) => state.promptManagement);
  const [showModal, setShowModal] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState("");
  const [currentSelectedScope, setCurrentSelectedScope] = useState(null);

  const platformService = new PlatformService();

  useEffect(() => {
    const currentSelectedScope = scopeAll.filter((scope) => {
      return scope.scopeId === selectedScopeId;
    })
    setCurrentSelectedScope(currentSelectedScope[0]);
  }, [scopeAll, selectedScopeId, props.node])

  const handlePublishClick = () => {
    setShowModal(true);
  };

  const handleConfirmPublish = async () => {

    const SPLId = props.checkedPrompts.map((item) => item.platformPromptId).join(",");


    const scopeIds = [...new Set(props.checkedPrompts.map((item) => item.scopeId))][0];

    const requestPayload = {
      ScopeId: scopeIds,
      SPLId: SPLId,
    };

    let response = await platformService.ConfirmPublish(requestPayload)

    if (response == 200) {
      props.getSystemPrompts();
      setShowToast(true);

      setToastType("success");
      setToastMessage("Prompt(s) published successfully");

    }
    setShowModal(false);
  };

  const handleCancelPublish = () => {
    setShowModal(false);
  };





  return (
    <>
      {props.tab === "InstantInsightPrompts" && props.innerTab === "active" && (
        <div className="ii-prompt-action-section">
          <MotifButton
            label="btnCreate"
            className="li-delete-button"
            onClick={props.onDeletePrompt}
            disabled={props.disableActionButtons}
          >
            <img src={trashPrompt} alt="Delete Prompt"></img>
            <span>Delete Prompt</span>
          </MotifButton>
          <MotifButton
            label="btnCreate"
            className="li-move-prompt"
            disabled={props.disableActionButtons}
            onClick={props.onMovePrompt}
          >
            <img src={arrowRight} alt="Move Prompt"></img>
            Move Prompt
          </MotifButton>
        </div>
      )}
      {props.tab === "SystemPrompts" && props.innerTab === "test" && props.node?.api === "Explore your data" &&currentSelectedScope?.spEydStatus === 4 && (
        <div className="ii-prompt-action-section">
          <MotifButton
            label="btnPublish"
            className="li-move-prompt"
            disabled={props.disableActionButtonsforEyd}
            onClick={handlePublishClick}
          >
            <img src={eyeImage} alt="Publish Prompt"></img>
            Publish
          </MotifButton>
        </div>
      )}

      {props.tab === "SystemPrompts" && props.innerTab === "test" && props.node?.api === "Instant Insight" && currentSelectedScope?.spiiStatus === 4 && (
        <div className="ii-prompt-action-section">
          <MotifButton
            label="btnPublish"
            className="li-move-prompt"
            disabled={props.disableActionButtonsII}
            onClick={handlePublishClick}
          >
            <img src={eyeImage} alt="Publish Prompt"></img>
            Publish
          </MotifButton>
        </div>
      )}

      {props.tab === "SystemPrompts" && props.innerTab === "test" && showModal && (
        <MotifModal
          id="motifModalPublishPrompt"

          size="lg"
          show={true}
          focusTrapOptions={{
            tabbableOptions: {
              displayCheck: "none",
            },
          }}
          onClose={handleCancelPublish}
          className="publish-genericModal modalNoHeaderBorder modalMotifFooterModal"

        >
          <div className="ii-acknowledgement-header-div">
            <MotifModalHeader
              className="motif-modal-header"
              closeButtonProps={{
                "aria-label": "Custom Close Button aria-label",
                title: "Close popup",
              }}
            >

              <div className="motif-modal-header-text">
                Publish to Active Prompts
              </div>

            </MotifModalHeader>

          </div>

          <MotifModalBody className="motif-modal-body">
            < >
              <span>
                Publish selected prompts to Active Prompts tab. Click on confirm to apply.
              </span>
              {props.checkedPrompts.length > 0 && (
                <ul className="modalContent"  >
                  {props.checkedPrompts.map((prompt, index) => (
                    <li key={index} >{prompt.platformPromptTitle
                    }</li>
                  ))}
                </ul>
              )}
            </>
          </MotifModalBody>

          <MotifModalFooter className="motif-modal-footer">
            <div className="btns-div">
              <div>
                <MotifButton
                  id="btnCancel"
                  variant="primary-alt"
                  onClick={handleCancelPublish}
                >
                  Cancel
                </MotifButton>
              </div>
              <div>
                <MotifButton
                  id="btnAcknowledge"
                  onClick={handleConfirmPublish}
                >
                  Confirm
                </MotifButton>
              </div>
            </div>
          </MotifModalFooter>
        </MotifModal>
      )}


      {showToast && (
        <MotifToast
          icons={{ toast: <img src={check_circle} alt=""></img> }}

          variant="success"
          className={"publish-motif-toast"}
          position="bottom"
          onClose={() => setShowToast(false)}
        >
          <div className="toast-body">{toastMessage}</div>
        </MotifToast>
      )}

    </>
  );
};

export default PromptAction;

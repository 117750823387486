import { MotifButton } from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import { useSelector } from "react-redux";
import _ from "lodash";
import PlatformService from "../../../../services/platformService";
import icon_warning from "../../../../assets/image/warning-triangle_red.svg";

const ConfirmMovePromptModal = (props) => {
  const platformService = new PlatformService();
  const { targetSubSection, selectedPromptsToMove } = useSelector(
    (state) => state.instantInsight
  );

  /**
   * This method will save the target subsection for the selected prompt
   */
  const confirmMoveInstantInsightPrompt = async () => {
    const request = {
      platformPromptIds: _.map(selectedPromptsToMove, "platformPromptId").join(","),
      targetSubsectionId: targetSubSection?.subSection?.id,
    };
    const isSuccess = await platformService.moveInstantInsightPrompts(request);
    if (isSuccess) {
      props.onMovePromptComplete();
    }
  };

  /**
   * This method will be closed the modal
   */
  const btnModalCloseClick = () => {
    props.closeConfirmMovePrompt(false);
  };
  
  return (
    <div>
      <MotifModal
        id="motifModalConfirmMovePrompt"
        className="genericModal"
        size="md"
        show={true}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={btnModalCloseClick}
      >
        <div>
          <MotifModalHeader>
            <div className="warning-image-div">
              <img
                className="icon-refresh"
                src={icon_warning}
                alt="warniing_image"
              ></img>
            </div>
          </MotifModalHeader>
          <div className="confirm-move-prompt-modal-header">Move prompt(s)</div>
        </div>
        <MotifModalBody>
          <div className="confirm-move-prompt-body-container">
            Confirm you want to move the below prompt(s) to
            <br />
            {targetSubSection?.category?.value}
            {" > "}
            {targetSubSection?.section?.value}
            {" > "}
            {targetSubSection?.subSection?.value}
            <br />
            <br />
            <ul className="move-prompt-list-style">
            {selectedPromptsToMove?.map((prompt) => (
              <li>{prompt?.platformPromptTitle}
              </li>
            ))}
            </ul>
          </div>
        </MotifModalBody>
        <MotifModalFooter>
          <div className="share-alert-btns-div">
            <div>
              <MotifButton
                variant="primary-alt"
                onClick={() => {
                  btnModalCloseClick();
                }}
                className="share-alert-cancel-button"
              >
                Cancel
              </MotifButton>
            </div>
            <div>
              <MotifButton
                className="share-alert-go-to-tw-button"
                onClick={confirmMoveInstantInsightPrompt}
              >
                Confirm
              </MotifButton>
            </div>
          </div>
        </MotifModalFooter>
      </MotifModal>
    </div>
  );
};
export default ConfirmMovePromptModal;

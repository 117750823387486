
import React from "react";
import noProject from "../../../../assets/image/no-project-image.png";
import "./NoPromptFound.css";
export default function NoPromptFound() {
    return (
<div className="ii-prompt-detail-no-selection">
              <img className="icon-noPrompt" src={noProject} alt="no selection made" />
              <div className="ii-prompt-no-selection-div">
                <span className="ii-prompt-no-selection-text">
                  No prompts found
                </span>              
              </div>
            </div>
    )
}
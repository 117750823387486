import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./CreateProject.css";
import { MotifTooltip, MotifButton } from "@ey-xd/motif-react";
import arrowRight from "../../assets/image/arrow-right.svg";
import arrowleftblack from "../../assets/image/arrow-left-black.svg";
import { ProjectDetail } from "./ProjectDetail.js";
import AddDocument from "./AddDocuments/AddDocument.js";
import AddMember from "./AddMember.js";
import dashboardnav from "../../assets/image/dashboardnav.svg";
import { ApiManager } from "../../services/apimanager.js";
import sessiomanager from "../../utils/sessionmanager.js";
import "../ProjectDashboard/ProjectDashboard.css";
import "../ProjectDashboard/EditProject/EditProject.css";
import "../ProjectDashboard/ExploreData/ExploreData.css";
import "../ProjectDashboard/ProjectDetail/ProjectDetail.css";
import "../ProjectDashboard/PromptLibraries/PromptLibraries.css";
import Header from "../common/Header";
import BreadCrumb from "../common/BreadCrumb";

export function CreateProject() {
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const requestData = require("../../data/model/request.js");
  const actionData = require("../../data/model/actiontype.js");
  const constantData = require("../../data/constant.js");
  let createDataList = requestData.CreateData;
  let SharedData = requestData.SharedData; 
  const [isCreateProject, setIsCreateProject] = useState(true);
  const [isAddMember, setIsAddMember] = useState(false);
  const [isUploadDocument, setIsUploadDocument] = useState(false);
  const [isMemberAdded, setIsMemberAdded] = useState(false);
  const [isDocumentAdded] = useState(false);
  let memberAddedList = [];
  const navigate = useNavigate();
  let createdProjectId = "";
  const apimanager = new ApiManager();
  let saveClicked = false;
  let actionDataList = actionData.ActionData;
  actionDataList.create = true;
  actionDataList.edit = false;
  actionDataList.explore = false;
  actionDataList.projectId = "";

  /**
   * Description
   * -----------
   * This method is to create/edit project depending upon editflag
   */

  const getCreatedProjectId = async () => {
    createdProjectId = await apimanager.CreateNewProject(createDataList);
  };

  /**
   * Description
   * -----------
   * This method is to get project id after creating and setting them up in session
   */
  const createandUpdateProjectRequest = async () => {
    if (saveClicked === false) {
      sessiomanager.setBlankMessage();
      if (isCreateProject) {
        try {
          saveClicked = true;
          await getCreatedProjectId();
          // if (createdProjectId !== "" && createdProjectId !== undefined) {
          if (createdProjectId) {
            sessionStorage.setItem("DashboardProjectId", createdProjectId);
            sessionStorage.setItem(
              "DashboardProjectName",
              createDataList.projectName
            );
            sessionStorage.setItem(
              "ProjectCreatedMessage",
              constantData.ProjectCreatedMessage
            );
            setAddMemberPage();
          }
        } catch (error) {}
      }
      if (isAddMember) {
        setProjectDetailPage();
      }
      if (isUploadDocument) {
        sessionStorage.setItem("isBackFromUpload", true);
        setAddMemberPage();
      }
    }
  };

  /**
   * Description
   * -----------
   * This method is to setup  the initial page for addmember or document
   */
  const createandExploreProject = async () => {
    sessiomanager.setBlankMessage();
    if (isAddMember) {
      if (memberAddedList && memberAddedList.length > 0) {
        const memberdata = memberAddedList[0].map((member) => {
          const { Avatar, isFixed, ...rest } = member;
          return rest;
        });
        const memberRequest =memberdata.filter(
          (member) => member.isAlreadySaved == false || member.isUpdated == true 
        );

        apimanager.AddMembers(memberRequest);
      }

      setAddDocumentPage();
    }
    if (isUploadDocument) {
      sessiomanager.setExploreMenu();
      navigate("/ProjectDashboard/ExploreData");
    }
  };
  /*
   * Description
   * -----------
   * This method is called after member is added by user to add the member in list
   * Parameters:
   * ismemberadded: this one is to track if member is added or not
   * memberAdded: this one is to get the member that has been added by user
   */
  const setMemberAdded = (ismemberadded, memberAdded) => {
    setIsMemberAdded(ismemberadded);
    memberAddedList = [];
    memberAddedList.push(memberAdded);
  };
  /*
   * Description
   * -----------
   * This method is called to show the project detail page and hide addmember and document page
   */
  const setProjectDetailPage = () => {
    setIsCreateProject(true);
    setIsAddMember(false);
    setIsUploadDocument(false);
  };
  /*
   * Description
   * -----------
   * This method is called to show the member page and hide project detail and document page
   */
  const setAddMemberPage = () => {
    setIsAddMember(true);
    setIsCreateProject(false);
    setIsUploadDocument(false);
  };
  /*
   * Description
   * -----------
   * This method is called to show the document page and hide add member and project detail page
   */
  const setAddDocumentPage = () => {
    setIsUploadDocument(true);
    setIsAddMember(false);
    setIsCreateProject(false);
  };
  /*
   * Description
   * -----------
   * This method is called to cancel project creation and go back to dashboard page
   */
  const onCancelButtonClick = () => {
    navigate("/Dashboard");
  };
  /*
   * Description
   * -----------
   * This method is called to disable the next button until user fills up all the mandatory details
   */
 
  function disableSave(IsDisable = Boolean, Detail = createDataList) {
    setButtonEnabled(IsDisable);

  }

  return (
    <div>
      <Header />
      <div className="DesktopCreateProject">
        {/* <div className="PageHeader">
          <div className="Breadcrumb">
            <div className="BreadcrumbBase">
              <div className="BreadcrumbItem">
                <div>
                  <NavLink reloadDocument to="/Dashboard" className="Text">
                    Dashboard
                  </NavLink>
                </div>
              </div>
              <div className="NavArrowRight">
                <img src={dashboardnav} alt="Icon"></img>
              </div>
              <div className="BreadcrumbItem">
                <div className="Text1">Create project</div>
              </div>
            </div>
          </div>
          <div className="Content">
            <div className="Title">
              <div className="TextAndSupportingText">
                <div className="Text2">
                  <div className="Text3">Create project</div>
                </div>
              </div>
            </div>
            <div className="Actions">
              {isCreateProject && (
                <div>
                  <div className="cancelbuttondiv">
                    <MotifButton
                      label="btnCancel"
                      className="CancelButton"
                      onClick={onCancelButtonClick}
                    >
                      Cancel
                    </MotifButton>
                  </div>
                </div>
              )}

              <div className="Button">
                <div>
                  <div className="CancelButton">
                    {(isCreateProject || isUploadDocument) && (
                      <MotifTooltip
                        placement="bottom"
                        hide={isUploadDocument ? true : false}
                        trigger={
                          <MotifButton
                            label="btnNext"
                            className={!isCreateProject ? "CancelButton" : ""}
                            onClick={() => createandUpdateProjectRequest()}
                            disabled={isCreateProject ? buttonEnabled : false}
                          >
                            {isCreateProject && (
                              <>
                                Next
                                <img src={arrowRight} alt="Icon"></img>
                              </>
                            )}
                            {isUploadDocument && (
                              <>
                                <img src={arrowleftblack} alt="Icon"></img>
                                Back
                              </>
                            )}
                          </MotifButton>
                        }
                      >
                        Select to move to the <br /> ‘Add members’ page
                      </MotifTooltip>
                    )}
                  </div>
                </div>
              </div>
              {!isCreateProject && (
                <div className="Button">
                  <div>
                    <div className="CancelButton">
                      <MotifTooltip
                        placement="bottom"
                        hide={
                          isAddMember || (isUploadDocument && !isDocumentAdded)
                            ? false
                            : true
                        }
                        trigger={
                          <MotifButton
                            label="btnskipnext"
                            onClick={() => createandExploreProject()}
                          >
                            {isAddMember && isMemberAdded && (
                              <>
                                Next
                                <img src={arrowRight} alt="Icon"></img>
                              </>
                            )}

                            {isAddMember && !isMemberAdded && (
                              <>
                                Skip & Next
                                <img src={arrowRight} alt="Icon"></img>
                              </>
                            )}

                            {isUploadDocument && isDocumentAdded && <>Create</>}

                            {isUploadDocument && !isDocumentAdded && (
                              <>Explore Your Data</>
                            )}
                          </MotifButton>
                        }
                      >
                        {isAddMember && <>Skip and go to Add documents</>}
                        {isUploadDocument && !isDocumentAdded && (
                          <>
                            Start Exploring Your Data.
                            <br />
                            You can add documents to <br /> the project at later
                            stage.
                          </>
                        )}
                      </MotifTooltip>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div> */}
        <div className="PageHeader">
          <div className="HeaderLayout">
            <BreadCrumb projectName="Create Project" />
            <div className="HeaderContainer">
              <div className="HeaderTitle">
                <span className="HeaderText">Create project</span>
              </div>
              <div className="HeaderAction">
                {isCreateProject && (
                  <div>
                    <div className="cancelbuttondiv">
                      <MotifButton
                        label="btnCancel"
                        className="CancelButton"
                        onClick={onCancelButtonClick}
                      >
                        Cancel
                      </MotifButton>
                    </div>
                  </div>
                )}

                <div className="Button">
                  <div>
                    <div className="CancelButton">
                      {(isCreateProject || isUploadDocument) && (
                        <MotifTooltip
                          placement="bottom"
                          hide={isUploadDocument ? true : false}
                          trigger={
                            <MotifButton
                              label="btnNext"
                              className={!isCreateProject ? "CancelButton" : ""}
                              onClick={() => createandUpdateProjectRequest()}
                              disabled={isCreateProject ? buttonEnabled : false}
                            >
                              {isCreateProject && (
                                <>
                                  Next
                                  <img src={arrowRight} alt="Icon"></img>
                                </>
                              )}
                              {isUploadDocument && (
                                <>
                                  <img src={arrowleftblack} alt="Icon"></img>
                                  Back
                                </>
                              )}
                            </MotifButton>
                          }
                        >
                          Select to move to the <br /> ‘Add members’ page
                        </MotifTooltip>
                      )}
                    </div>
                  </div>
                </div>
                {!isCreateProject && (
                  <div className="Button">
                    <div>
                      <div className="CancelButton">
                        <MotifTooltip
                        allowClick={false}
                          placement="bottom"
                          hide={
                            isAddMember ||
                            (isUploadDocument && !isDocumentAdded)
                              ? false
                              : true
                          }
                          trigger={
                            <MotifButton
                              label="btnskipnext"
                              onClick={() => createandExploreProject()}
                            >
                              {isAddMember && isMemberAdded && (
                                <>
                                  Next
                                  <img src={arrowRight} alt="Icon"></img>
                                </>
                              )}

                              {isAddMember && !isMemberAdded && (
                                <>
                                  Skip & Next
                                  <img src={arrowRight} alt="Icon"></img>
                                </>
                              )}

                              {isUploadDocument && isDocumentAdded && (
                                <>Create</>
                              )}

                              {isUploadDocument && !isDocumentAdded && (
                                <>Explore Your Data</>
                              )}
                            </MotifButton>
                          }
                        >
                          {isAddMember && <>Skip and go to Add documents</>}
                          {isUploadDocument && !isDocumentAdded && (
                            <>
                              Start Exploring Your Data.
                              <br />
                              You can add documents to <br /> the project at
                              later stage.
                            </>
                          )}
                        </MotifTooltip>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="ProgressIndicator">
          <div className="ProgressIndicatorStep">
            {isCreateProject && <div className="Line"></div>}
            {(isAddMember || isUploadDocument) && <div className="Line2"></div>}
            <div className="Content3">
              <div className="Text9">Project details</div>
              <div className="SupportingText"></div>
            </div>
          </div>
          <div className="ProgressIndicatorStep">
            {isCreateProject && <div className="Line1"></div>}
            {isAddMember && <div className="Line"></div>}
            {isUploadDocument && <div className="Line2"></div>}
            <div className="Content3">
              <div className="Text9">Add members</div>
              <div className="SupportingText"></div>
            </div>
          </div>
          <div className="ProgressIndicatorStep">
            {(isCreateProject || isAddMember) && <div className="Line1"></div>}
            {isUploadDocument && <div className="Line"></div>}

            <div className="Content3">
              <div className="Text9">Add documents</div>
              <div className="SupportingText"></div>
            </div>
          </div>
        </div>
        {isCreateProject && (
          <ProjectDetail
            disablecheck={disableSave}
            actiontype={actionDataList}
          ></ProjectDetail>
        )}
        {isAddMember && <AddMember setIsMember={setMemberAdded}></AddMember>}
        {isUploadDocument && (
          <AddDocument isDocumentLibrary={false}></AddDocument>
        )}
      </div>
    </div>
  );
}

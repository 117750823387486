import React, { useState, useEffect } from "react";
import { MotifCheckbox, MotifTree, MotifTreeNode } from "@ey-xd/motif-react";

import arrowDown from "../../../../assets/image/Arrow_down.svg";
import arrowUp from "../../../../assets/image/Arrow_up.svg";

import "./TreeView.css";

export default function TreeView(props) {

    const { treeData, nodeExpansionGraph } = props;
    const [showSelectScopeScreen, setShowSelectScopeScreen] = useState(false);
    const [scopeHasStructure, setScopeHasStructure] = useState(true);
    const [selectedScope, setSelectedScope] = useState(null);
    const [message, setMessage] = useState("");

    useEffect(() => {
        setShowSelectScopeScreen(true);
        setScopeHasStructure(false);
        if (scopeHasStructure) {
            setMessage("No Structure created Please create Category, Section and Subsections")
        }
        else if (!selectedScope) {
            setMessage("No Selection made Select a Project Scope to view the tree hierarchy")
        }

    }, [scopeHasStructure, selectedScope])

    const getCheckboxIfApplicable = (node) => {
        if (props.showCheckbox) {
            return (
                <MotifCheckbox
                    key={node.id}
                    checked={node.isSelected}
                    disabled={node.id !== null}
                    onChange={(e) => {
                        
                    }}
                />
            );
        }
        return null;
    };

    const getNodeMarkup = (node) => {
        return (
            <div className={props.showCheckbox ? "div-checkbox" : "icon-only"} onClick={() => {}}
            >
                {getCheckboxIfApplicable(node)}
                <button className="plain-button" title={node.value && (node.value.length > 45 ? node.value : '')}
                disabled={node.disabled}
                >
                    {node.value}
                </button>
            </div>
        );
    };

    const checkIfNodeShouldBeExpanded = (node) => {
        if (nodeExpansionGraph.find((expandedNode) => {
            return expandedNode.id === node.id;
        })) {
            return true;
        }
        else {
            return false;
        }
    }

    const renderTree = (node) => {
        if (!node) return null;

        return (
            <MotifTreeNode 
                value={getNodeMarkup(node)}
                open={checkIfNodeShouldBeExpanded(node)}
                key={node.id}
                icons={{
                    close: <img src={arrowUp}></img>,
                    open: <img src={arrowDown}></img>,
                }}
                >
                {node.children && node.children.map((child) => renderTree(child))}
            </MotifTreeNode>
        );
    };
      
    const createMotifTree = (treeData) => {
        return <MotifTree showLines={true}>{treeData.map((rootNode) => renderTree(rootNode))}</MotifTree>;
    };

    return (
        <div className="tree-div">
            {treeData && createMotifTree(treeData)}
        </div>
    )
}
import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
  MotifInput,
  MotifRichTextEditor,
  MotifFormField,
  MotifErrorMessage,
  MotifInlineMessage,
} from "@ey-xd/motif-react";
import infoIcon from "../../../assets/image/information.svg";
import { useEffect, useRef, useState } from "react";
import Utils from "../../../utils/Utils";

// Component to render Edit Prompt modal
export default function EditPromptModal(props) {
  const utils = new Utils();
  const [prompt, setPrompt] = useState({});
  const [isPromptEdited, setIsPromptEdited] = useState(false);
  const [isSpecChar, setIsSpecChar] = useState(false);
  const [isMaxChar, setIsMaxChar] = useState(false);
  const [isValidTitle, setIsValidTitle] = useState(false);
  const regex = /[`!@#$%^*+=\]{};':"\|,<>?~]/;
  const promptRef = useRef(null);
  const motifReactEditorModules = {
    toolbar: [
      [
        {
          list: "ordered",
        },
        {
          list: "bullet",
        },
      ],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  useEffect(() => {
    setPrompt(props?.selectedPrompt);
  }, [props?.selectedPrompt]);

  useEffect(() => {
    const checkModalLoaded = () => {
      const promptEditor = promptRef.current;
      if (promptEditor) {
        const numberedBtn = promptEditor.querySelector(
          `button[value="ordered"]`
        );
        const bulletBtn = promptEditor.querySelector(`button[value="bullet"]`);
        if (numberedBtn) {
          utils.addCustomTooltip(
            numberedBtn,
            "Numbered list",
            "modalTooltipNumberedList",
            "bottom"
          );
        }
        if (bulletBtn) {
          utils.addCustomTooltip(
            bulletBtn,
            "Bulleted list",
            "modalTooltipBulletedList",
            "bottom"
          );
        }
      } else {
        // Retry after a short delay if the modal is not yet loaded
        setTimeout(checkModalLoaded, 10);
      }
    };
    checkModalLoaded();

    return () => {
      let tooltipNumberedList = document.getElementById(
        "modalTooltipNumberedList"
      );
      if (tooltipNumberedList) {
        document.body.removeChild(tooltipNumberedList);
      }
      let tooltipBulletedList = document.getElementById(
        "modalTooltipBulletedList"
      );
      if (tooltipBulletedList) {
        document.body.removeChild(tooltipBulletedList);
      }
    };
  }, []);

  /**
   * This method calls when prompt title text changed
   * @param {*} value - value of the title
   */
  const onPromptTitleChange = (value) => {
    if (value.length > 0) {
      if (value.length <= 50) {
        setIsValidTitle(true);
        setIsMaxChar(false);
        setPrompt((prevState) => ({
          ...prevState,
          promptTitle: value,
        }));
        if (regex.test(value)) {
          setIsValidTitle(false);
          setIsSpecChar(true);
        } else {
          setIsValidTitle(true);
          setIsSpecChar(false);
        }
      } else {
        setIsMaxChar(true);
      }
    } else {
      setIsValidTitle(false);
      setPrompt((prevState) => ({
        ...prevState,
        promptTitle: value,
      }));
      setIsMaxChar(false);
      setIsSpecChar(false);
    }
  };

  /**
   * This method calls when rich text box text changed
   * @param {*} value - value of the text
   */
  const onPromptTextChange = (value) => {
    setPrompt((prevState) => ({
      ...prevState,
      promptText: value,
    }));
  };

  /**
   * This method enables the confirm button when user type something
   */
  const onPromptEdited = () => {
    setIsPromptEdited(true);
  };

  /**
   * Allow special keys like Tab, Enter, Delete, Backspace & Control char combination to enable Confirm button
   */
  const onPromptEditKeyUp = (e) => {
    const key = e?.key;
    const nativeEvent = e?.nativeEvent;
    if (
      key === "Tab" ||
      key === "Enter" ||
      key === "Delete" ||
      key === "Backspace" ||
      (nativeEvent?.composed && nativeEvent?.ctrlKey)
    ) {
      setIsPromptEdited(true);
    }
  };

  /**
   * This method is to enable/disable the confirm button
   * @returns boolean value
   */
  const confirmDisableCheck = () => {
    let promptTextVal = prompt?.promptText;
    promptTextVal = promptTextVal?.replace(/<[^>]+>/g, "").trim();
    return (
      promptTextVal === "" || prompt?.promptTitle === "" || !isPromptEdited
    );
  };

   /**
     * This method removes the max character message 
     */
  const handleBlur = () => {
    setIsMaxChar(false);
  }

  return (
    <MotifModal
      id="badgepopupmodal"
      size="xl"
      show={props.showEditPromptModal}
      onClose={() => props.toggleEditPromptModal({})}
      className="genericModal modalHeaderVerticalPadding modalMotifFooterModal"
    >
      <MotifModalHeader
        closeButtonProps={{
          "aria-label": "Close Edit Prompt Modal",
          title: "Close Edit Prompt Modal",
        }}
      >
        Edit prompt
      </MotifModalHeader>
      <MotifModalBody className="edit-prompt-body">
        <div>
          <span className="edit-prompt-modal-label">Title</span>
          <MotifFormField>
            <MotifInput
              label="inputtitle"
              onChange={(e) => {
                onPromptTitleChange(e.target.value);
              }}
              type="text"
              placeholder="Enter a title"
              value={prompt?.promptTitle}
              onKeyPress={onPromptEdited}
              onKeyUp={onPromptEditKeyUp}
              onBlur={handleBlur}
            />
            {isSpecChar && (
              <MotifErrorMessage className="spcl-char-error-message">
                Special characters not allowed
              </MotifErrorMessage>
            )}
            {!isSpecChar && isMaxChar && (
              <MotifInlineMessage className="max-char-reached-messaage">
                Maximum limit reached - 50 characters
              </MotifInlineMessage>
            )}
          </MotifFormField>
        </div>
        <div ref={promptRef}>
          <span className="edit-prompt-modal-label">Prompt</span>
          <MotifRichTextEditor
            id="prompt-library-edit-prompt"
            placeholder="Enter a prompt"
            value={prompt?.promptText}
            modules={motifReactEditorModules}
            onChange={onPromptTextChange}
            onKeyPress={onPromptEdited}
            onKeyUp={onPromptEditKeyUp}
          />
        </div>
        <div className="edit-prompt-disclaimer">
          <img
            src={infoIcon}
            className="edit-prompt-disclaimer-icon"
            alt="icon"
          />
          <span className="edit-prompt-disclaimer-message">
            Please ensure client information is not included in the prompt
          </span>
        </div>
      </MotifModalBody>
      <MotifModalFooter className="prompt-modal-footer">
        <MotifButton
          id="btnConfirm"
          onClick={() => props.handleEditPrompt(prompt)}
          size="medium"
          type="button"
          className="prompt-modal-confirm-button"
          disabled={confirmDisableCheck()}
        >
          Confirm
        </MotifButton>
        <MotifButton
          id="btnCancel"
          onClick={() => props.toggleEditPromptModal({})}
          size="medium"
          type="button"
          variant="secondary"
          className="prompt-modal-cancel-button"
        >
          Cancel
        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
}

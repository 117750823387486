import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MotifTreeNode, MotifTree, MotifCheckbox } from "@ey-xd/motif-react";
import arrowDown from "../../../assets/image/Arrow_down.svg";
import arrowUp from "../../../assets/image/Arrow_up.svg";
import redWarningInfoIcon from "../../../assets/image/Light circle.svg";
import refreshIcon from "../../../assets/image/refresh-double.svg";
import featuredicon from "../../../assets/image/check-circle.svg";
import loadingicon from "../../../assets/image/system-restart.svg";

export default function ProjectScopeTree(props) {

    const [selectedNodes, setSelectedNodes] = useState([]);

    useEffect(()=>{
        // Add required references and properties to tree data
        addReferences(props.treeData, null);
        
    },[props.treeData])

    const addReferences = (node, parent) => {

        // Check if parent reference is present in data else add parent reference
        if (!props.treeData.parent) {
            node.parent = parent;
        }

        // Check if isSelected property is present in data else add isSelected property
        if (!props.treeData.isSelected) {
            node.isSelected = false;
        }

        if (node.children && node.children.length != 0) {
            node.children.forEach((child) => addReferences(child, node));
        }
    }

    /**
     * This method generates jsx markup for tree recursively
     */
    const generateTree = (node) => {
        if (!node.children || node.children.length === 0) {
            <MotifTreeNode key={node.value} value={getNodeMarkup(node)} />;
        }
        return (
            <MotifTreeNode
                key={node.value}
                value={getNodeMarkup(node)}
                icons={{
                    close: <img src={arrowUp}></img>,
                    open: <img src={arrowDown}></img>,
                }}
            >
                {node.children ? node.children.map((child) => generateTree(child)) : ''}
            </MotifTreeNode>
        );
    };

    const getNodeMarkup = (node) => {
        return (
            <div className={props.showCheckbox ? "div-checkbox" : "icon-only"} onClick={() => {
                if (props.treeNodeClick) {
                    props.treeNodeClick(node);
                }
                else {
                    ownTreeNodeClick(node);
                }
            }}
            >
                {getNodeIconsIfApplicable(node)}
                {getCheckboxIfApplicable(node)}
                <button className="plain-button" title={node.value && (node.value.length > 45 ? node.value : '')}
                disabled={node.disabled}
                >
                    {node.value}
                </button>
            </div>
        );
    };

    const getCheckboxIfApplicable = (node) => {
        if (props.showCheckbox) {
            return (
                <MotifCheckbox
                    key={node.id}
                    checked={node.isSelected}
                    onChange={(e) => {
                        
                    }}
                    disabled={node.disabled}
                />
            );
        }
        return null;
    };

    const getNodeIconsIfApplicable = (node) => {
        if (props.showIcons) {
            if(props.isFirstTimeRefreshInstantInsight)
            {
                if(node.isAllResponseGenerated)
                    {
                        return (
                            <React.Fragment>                                                       
                                <img className="tree-icon-refresh" src={featuredicon}></img>
                            </React.Fragment>
                        );
                    }
                    else{
                        return (
                            <React.Fragment>                                                      
                                <img className="tree-icon-refresh" src={loadingicon}></img>
                            </React.Fragment>
                        );
                    }
            }else{
                if (node.noResponseToPrompt && !node.isRefreshAcknowledged) {
                    return (
                        <React.Fragment>
                            {/* <img className="icon-red-info" src={redWarningInfoIcon}></img> */}
                            <img className="tree-icon-refresh" onClick={()=> handleAcknowledge(node)} src={refreshIcon}></img>
                        </React.Fragment>
                    );
                }else if (node.isRefreshAcknowledged) {
                    return <img className="tree-icon-refresh" src={featuredicon}></img>;
                } 
                
                else if (node.noResponseToPrompt) {
                    // return <img className="icon-red-info" src={redWarningInfoIcon}></img>;
                } else if (!node.isRefreshAcknowledged) {
                    return <img className="tree-icon-refresh" onClick={()=> handleAcknowledge(node)} src={refreshIcon}></img>;
                }
            }
           
        }
        return null;
    };

    const handleAcknowledge = (node) => {
        props.setSelectedTreeNode(node);
        props.setLaunchedBy("tree");
        props.setShowAcknowledgeRefreshPopup(true);
    }

    const updateSelection = (node) => {
        if (node.children && node.children.length > 0) {
            // If any child is unselected, unselect the parent
            if (node.children.some((child) => !child.isSelected)) {
                node.isSelected = false;
            }
            else {
                // If all children are selected, select the parent
                node.isSelected = true;
            }

            // Recursively update parent nodes
            if (node.parent) {
                updateSelection(node.parent);
            }
        }
    }

    const addRemoveNode = (node, action) => {
        if (action === "add" && !node.disabled) {
            setSelectedNodes(selectedNodes => [...selectedNodes, node.id]);
            node.isSelected = true;
        }
        else {
            setSelectedNodes(selectedNodes => {
                return selectedNodes.filter((selectedNode) => {
                    if (selectedNode != node.id) {
                        return true;
                    }
                });
            });
            node.isSelected = false;
        }
    }

    const traverseAndUpdateTree = (node, action) => {
        if (!node.children || node.children.length === 0) {
            // just add/remove this node
            addRemoveNode(node, action);
        }
        else {
            // has children so add/remove node and then traverse tree again
            addRemoveNode(node, action);

            if (node.children) {
                node.children.forEach(child => {
                    traverseAndUpdateTree(child, action);
                });
            }
        }
    }

    const ownTreeNodeClick = (node) => {
        if (node.isSelected) {
            traverseAndUpdateTree(node, "remove");
        }
        else {
            traverseAndUpdateTree(node, "add");
        }

        if (props.bubbleBackData) {
            props.bubbleBackData(props.treeData);
        }
        updateSelection(node.parent);
    };

    return (
        <MotifTree treeValue={props.treeValue} showLines={props.showLines}>
            {props.treeData.children.map((child) => generateTree(child))}
        </MotifTree>
    );
}

ProjectScopeTree.propTypes = {
    treeValue: PropTypes.string,
    treeData: PropTypes.object.isRequired,
    showLines: PropTypes.bool,
    showCheckbox: PropTypes.bool,
    treeNodeClick: PropTypes.func,
    bubbleBackData: PropTypes.func
}